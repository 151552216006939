import React from "react";

import HomeLayout1 from "./HomeLayout1";
import ModuleLayout1 from "./ModuleLayout1";
import ApprovalLayout1 from "./ApprovalLayout1";
import MemoLayout1 from "./MemoLayout1";
import OpenLayout1 from "./OpenLayout1";
import ReportLayout1 from "./ReportLayout1";
import HomeLayout3 from "./HomeLayout3";
import HomeDateLayout1 from "./HomeDateLayout1";

export default {
  ModuleLayout1: HomeLayout1,
  ApprovalLayout1: HomeLayout1,
  HomeLayout1,
  HomeDateLayout1: HomeLayout1,
  HomeDateLayout2: HomeLayout1,
  OpenLayout1: HomeLayout1,
  ReportLayout1: HomeLayout1,
  MemoLayout1: HomeLayout1,
  HomeLayout2: HomeLayout1,
  HomeLayout3,
  HomeMergeLayout3: HomeLayout1,
};
