import { PrinterIcon } from "@heroicons/react/solid";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { useState } from "react";
import Spinner from "../loaders/Spinner";

const appendTimeStamp = (filename) => {    
    return filename + "_" + moment(new Date()).format("yyyy-MM-D@hh-mma");
}


export default function PrintButton({data}){

    const [processing,setProcessing] = useState(false)

    const saveToPdf = () => {
        setProcessing(true)
        setTimeout(() => {
            console.log("printing data", data)
            var dom = document.getElementById('print-preview');
            let {height,width} = dom.getBoundingClientRect()
            html2canvas(dom,{useCORS: true}).then(canvas => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'in',
                format: [9.2, height/96],
                putOnlyUsedFonts:true
                });
                pdf.addImage(imgData, 'PNG', 0, 0);
                pdf.save(`${appendTimeStamp(data.document.find(x => x.name == "Document")?.value?.replace(/\s+/,'') ?? "file")}.pdf`);
            })
            setProcessing(false)
        },1000)
    }

    return (
        <button onClick={saveToPdf} className='bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded-md inline-flex items-center'>
        {!processing && <><PrinterIcon width={16} height={16} fill="#ffffff" /> <h3 className='uppercase ml-2 hidden lg:block'>Print</h3></>}
        {processing && <>Processing <Spinner/> </>}
      </button>
    )
}