import React, { Component, useMemo, Fragment } from "react";
import Stepper from "react-stepper-horizontal";
import Form1 from "./Form1";
import Form2 from "./Form2";
import CartBagIcon from "../icons/CartBagIcon";
import SidebarModal from "./SidebarModal";
import { ThemeContext } from "styled-components";
import PreviewTransaction from "./PreviewTransaction";
import WithLoader from "./WithLoader";
import mapInitialValues from "../mapInitialValues";
import styled from "styled-components";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import { formatNumber } from "../util/formatter";
import ListCartSource from "./ListCartSource";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { Dialog, Transition, Menu } from "@headlessui/react";
import {
  EditActiveIcon,
  EditInactiveIcon,
  DeleteActiveIcon,
  DeleteInactiveIcon,
} from "../LineIcons";
import { useState } from "react";

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">cart is empty</h2>
    </div>
  );
};

const ControlButton = styled.button`
  background: #fdf9eb;
  border-radius: 5px 0px 0px 5px;
`;

const cartLines = [
  {
    cart_line_1: "Bread",
    cart_line_2: "kg",
    cart_quantity: "1000",
    cart_line_3: "",
    cart_line_4: "No",
  },
  {
    cart_line_1: "Baking powder",
    cart_line_2: "kg",
    cart_quantity: "2000",
    cart_line_3: "",
    cart_line_4: "No",
  },
];

const getValue = (value) => value?.split("=>")[0] || "";

const CartItems = ({ items, deleteItemFromCart, editCartItem }) => {
  return (
    <div>
      <h2 className="uppercase font-semibold text-md">Cart</h2>
      {(items?.length || 0) == 0 && <EmptyRecord />}
      <ul className="grid gap-4 mt-10">
        {items?.map((item, idx) => (
          <li className="border rounded-md px-4 py-4 relative bg-gray-100 bg-opacity-40">
            <div className="flex justify-between items-center pr-2">
              <div className="flex items-center">
                <div className="bg-gray-100 shadow px-2 justify-center rounded-md inline-flex items-center mr-3 w-8 h-6">
                  <span className="text-md font-bold">{idx + 1}</span>
                </div>

                <div>
                  {item?.cart_line_1 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="font-medium text-black">
                        {getValue(item?.cart_line_1)}
                      </span>
                    </div>
                  )}
                  {item?.cart_line_2 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="mr-1 capitalize text-xs">
                        {getValue(item?.cart_line_2)}
                      </span>
                    </div>
                  )}
                  {item?.cart_line_3 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="mr-1 capitalize text-xs">
                        {getValue(item?.cart_line_3)}
                      </span>
                    </div>
                  )}
                  {item?.cart_line_4 && (
                    <div className="text-gray-700 text-sm truncate">
                      <span className="mr-1 capitalize text-xs">
                        {getValue(item?.cart_line_4)}
                      </span>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex items-start flex-col mr-4">
                {item?.cart_quantity && (
                  <div className="flex">
                    <span
                      className="font-medium text-black text-center text-black-400 border text-sm rounded-sm h-6 px-2 inline-flex items-center"
                      style={{ borderColor: "#9A9FAD", background: "#F4F4F4" }}
                    >
                      {item?.cart_quantity}{" "}
                    </span>
                  </div>
                )}
                {item?.cart_unit_amount && (
                  <div className="text-gray-700 text-sm truncate font-semibold">
                    <span className="mr-1 capitalize text-xs">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.cart_currency,
                        }}
                      ></span>
                      {formatNumber(item.cart_unit_amount)}
                    </span>
                  </div>
                )}
                {item?.cart_discount && (
                  <div className="text-gray-700 text-sm truncate font-semibold">
                    <span className="mr-1 capitalize text-xs">
                      {formatNumber(item.cart_discount)}%
                    </span>
                  </div>
                )}
                {item?.cart_total_amount && (
                  <div className="text-gray-700 text-sm truncate font-semibold">
                    <span className="mr-1 capitalize text-xs">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item?.cart_currency,
                        }}
                      ></span>
                      {formatNumber(item.cart_total_amount)}
                    </span>
                  </div>
                )}
              </div>
              {/* <CloseIcon width={12} height={12} fill="#747474" className="ml-2 absolute top-1/2 transform -translate-y-1/2 right-4" onClick={() => {
                    deleteItemFromCart(idx);
                }} /> */}
              <Menu
                as="div"
                className="text-left ml-2 absolute top-1/2 transform -translate-y-1/2 right-4 z-10"
              >
                <div>
                  <Menu.Button className="inline-flex justify-center w-full px-0.5 py-1 text-sm font-medium text-custom-gray-200 rounded-sm">
                    <DotsVerticalIcon
                      width={12}
                      height={12}
                      className="w-5 h-5 text-violet-200 hover:text-violet-100"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => editCartItem(idx)}
                            className={`${
                              active ? "bg-primary text-white" : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <EditActiveIcon
                                className="w-5 h-5 mr-2"
                                aria-hidden="true"
                              />
                            ) : (
                              <EditInactiveIcon
                                className="w-5 h-5 mr-2"
                                aria-hidden="true"
                              />
                            )}
                            Edit
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => deleteItemFromCart(idx)}
                            className={`${
                              active ? "bg-primary text-white" : "text-gray-900"
                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <DeleteActiveIcon
                                className="w-5 h-5 mr-2 text-violet-400"
                                aria-hidden="true"
                              />
                            ) : (
                              <DeleteInactiveIcon
                                className="w-5 h-5 mr-2 text-violet-400"
                                aria-hidden="true"
                              />
                            )}
                            Delete
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FormLine1 = (props) => {
  const [newCart, setNewCart] = useState([]);

  console.log("the props to edit", props?.schema);

  const [state, setState] = useState({
    page: 0,
    formData: null,
    formStepper: props?.schema,
    cart: [],
    showCartSidebar: false,
    isProcessing: false,
    previewData: null,
    isOpen: false,
    activeCart: null,
    formStepperName: props?.name,
  });

  const { setLoading } = props;
  const _formStepper = props?.schema;
  const formDefaults = mapInitialValues(_formStepper);
  const formInitialValues = formDefaults.formInitialValues;
  const formDefaultValues = formDefaults.formDefaultValues;

  console.log("stepper", props.children);
  console.log("the props for FormLine", props);
  console.log("what is this FormStepper", _formStepper);

  const postData = (values) => {
    let postData = {
      jsonString: JSON.stringify([values]),
      sessionId: "E315E746-3DCC-45EF-82DE-02138152473D",
      applicationId: "601",
      menuId: "702",
      pageId: "6001",
      buttonAction: "SAVE",
    };

    console.log("postData", postData);

    let response = fetch("https://localhost:7230/App/action", {
      method: "POST",
      body: JSON.stringify(postData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => console.log("response from server", result));
  };

  const nextPage = (data) => {
    console.log("builddata nextpage is called", data);
    setState((prev) => ({
      ...prev,
      formData: { ...prev.formData, ...data },
    }));
  };

  const setFormData = (newFormData) => {
    console.log("preparing prev", state.formData, newFormData);
    // let formData = {...newFormData}
    setState((prev) => ({
      ...prev,
      formData: { ...prev.formData, ...newFormData },
    }));
    console.log("formData", state.formData);
  };

  const previousPage = () => {
    setState((prev) => ({
      ...prev,
      page: state.page - 1,
    }));
  };

  const addItemToCart = ({
    item,
    lineValues,
    preview,
    previewDataFinal,
    cartRoot,
  }) => {
    console.log("adding...", { item, lineValues, preview, previewDataFinal });
    let myCart = state.cart;
    let previewData = previewDataFinal;
    let oldLines = (previewData ?? {})[preview] ?? [];

    console.log("let see these values", myCart, previewData, oldLines);

    let { cart_total_amount, cart_quantity, ...cartJson } = item;
    let cartString = JSON.stringify(cartJson);
    console.log("cartString", cartString);

    //if edit mode
    let activeCart = state.activeCart;

    let cartIdx = null;
    let cartItem = myCart.find((m, idx) => {
      let { cart_total_amount, cart_quantity, ...rowJson } = m;
      let match = JSON.stringify(rowJson) == cartString;
      if (match) {
        cartIdx = idx;
      }
      return match;
    });

    if (cartItem) {
      //update quantity and total amount
      // cartItem.cart_quantity += item.cart_quantity;
      console.log("typeof item.cart_quantity", typeof item.cart_quantity);
      console.log(
        "typeof cartItem.cart_quantity",
        typeof cartItem.cart_quantity
      );
      cartItem.cart_quantity =
        parseFloat(item.cart_quantity) +
        parseFloat(activeCart ? 0 : cartItem.cart_quantity);

      if (cartItem.cart_total_amount) {
        cartItem.cart_total_amount = parseFloat(
          cartItem.cart_quantity * parseFloat(cartItem.cart_unit_amount)
        );

        if (cartItem.cart_discount) {
          cartItem.cart_total_amount -=
            cartItem.cart_total_amount *
            (parseFloat(cartItem.cart_discount) * 0.01);
        }
      }
    }

    let lineItem = oldLines?.find((m, idx) => idx == cartIdx);
    if (lineItem) {
      if (lineItem.line_quantity)
        lineItem.line_quantity =
          lineValues.line_quantity + (activeCart ? 0 : lineItem.line_quantity);
      if (lineItem.line_months)
        lineItem.line_months =
          lineValues.line_months + (activeCart ? 0 : lineItem.line_months);

      let qty = lineItem.line_quantity ?? lineItem.line_months ?? 1;

      if (lineItem.line_total_amount) {
        lineItem.line_total_amount = parseFloat(
          qty * lineItem.line_unit_amount
        );

        if (lineItem.line_discount) {
          lineItem.line_total_amount -=
            lineItem.line_total_amount * (lineItem.line_discount * 0.01);
        }
      }
    }

    let newCart = cartItem
      ? [...myCart.filter((m, i) => i != cartIdx), cartItem]
      : [...myCart, item];
    let lines = cartItem
      ? [...oldLines.filter((m, i) => i != cartIdx), lineItem]
      : [...oldLines, lineValues];

    if (previewData && preview) {
      previewData[preview] = lines;
    }
    console.log("previewData", previewData);

    setState((prev) => ({
      ...prev,
      cart: newCart,
      previewData,
      cartRoot,
      previewRoot: preview,
      activeCart: null,
    }));
  };

  const clearCart = () => {
    let cart = [];
    setState((prev) => ({ ...prev, cart }));
  };

  const deleteItemFromCart = (idx) => {
    let cartRoot = state.cartRoot;
    let previewRoot = state.previewRoot;

    let previewData = state.previewData;
    let formData = state.formData;
    console.log("delete item from cart formdata", formData);

    let cart = state.cart?.filter((i, index) => index !== idx);
    let formDataLines = formData[cartRoot]?.filter((i, index) => index !== idx);

    if (previewRoot && previewData) {
      let lines = previewData[previewRoot]?.filter((i, index) => index !== idx);
      previewData.line = lines;
    }

    formData[cartRoot] = formDataLines;

    setState((prev) => ({ ...prev, cart, previewData, formData }));
  };

  const editCartItem = (idx) => {
    let cartItem = state.cart[idx];

    let cartLineNames = Object.keys(cartItem);
    let editLine = {};

    let step = state.formStepper[state.page];
    let formControls = step.options;

    for (let control of formControls) {
      let { name, cart } = control;
      if (cartLineNames.includes(cart)) {
        //parse values like PEACE UDUAK (EMP-001)=>3685
        editLine[name] = cartItem[cart]?.split("=>").slice(-1)[0] || "";
      }
    }

    console.log("editLine", editLine);

    setState((prev) => ({
      ...prev,
      activeCart: editLine,
      showCartSidebar: false,
    }));
  };

  const openCart = () => {
    console.log("opening cart");
  };

  const toggleCartSidebar = (status) => {
    setState((prev) => ({ ...prev, showCartSidebar: status }));
  };

  const setProcessing = (status) => {
    setState((prev) => ({ ...prev, isProcessing: status }));
  };

  const updatePreviewData = (data) => {
    let oldData = state.previewData;
    let previewData = { ...oldData, ...data };
    console.log(
      "the double click updatePreviewData is called",
      oldData,
      data,
      previewData
    );
    setState((prev) => ({ ...prev, previewData }));
    return previewData;
  };

  const setIsOpen = (state) => {
    console.log("setting open state", state);
    setState((prev) => ({ ...prev, isOpen: state }));
  };

  const handlePrint = () => {
    console.log("printing");
  };

  const handleSubmit = () => {
    let jsonData = { ...state.formData };
    postData(jsonData);
  };

  const { onSubmit } = props;
  const steps = state?.formStepper?.map(({ name }) => ({ title: name }));

  console.log("forminitialvalues 2 => ", formInitialValues);

  const openCartSidebar = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      toggleCartSidebar(true);
    }, 600);
  };

  const closeSidebar = () => {
    setTimeout(() => {
      toggleCartSidebar(false);
    }, 400);
  };

  console.log("formStepper", state.formStepper);
  console.log("state", state);

  // console.log("schema is changing",{schema:formStepper[page]});

  const currentStep = state.formStepper[state.page];
  const previousStep = state.formStepper[state?.page - 1];
  console.log("currentStep", currentStep);
  console.log("currentStep previousStep", previousStep);

  const pageUseCart = currentStep?.useCart;

  if (!state.formStepper) return <></>;

  console.log("pageuseCart", pageUseCart);

  return (
    <>
      {state.isOpen && (
        <PreviewTransaction
          {...{
            name: state.formStepperName,
            data: state.previewData,
            isOpen: state.isOpen,
            setIsOpen: setIsOpen,
            handleSubmit: handleSubmit,
            handlePrint: handlePrint,
          }}
        />
      )}

      {state.showCartSidebar && (
        <SidebarModal
          inProp={state.showCartSidebar}
          closeSidebar={closeSidebar}
        >
          <CartItems
            items={state.cart}
            deleteItemFromCart={deleteItemFromCart}
            editCartItem={editCartItem}
          />
        </SidebarModal>
      )}

      <div className="form-stepper relative">
        {/* <div className="flex justify-between absolute top-28 right-6">
          <button
            onClick={openCartSidebar}
            className="relative bg-primary duration-300 p-1 rounded"
          >
            <CartBagIcon width={20} height={20} fill={"#ffffff"} />
            <span className="absolute bg-red-600 text-xs text-white font-bold rounded-full -top-3 -right-3 w-5 h-5 inline-grid place-items-center">
              {state.cart?.length ?? 0}
            </span>
          </button>

        </div> */}

        {/* 
        {
          <div className="mb-6">
            <Stepper
              steps={steps}
              activeStep={state.page}
              activeColor={theme?.colors.primary}
              // defaultBarColor="#B24190"
              completeColor={theme?.colors.primary}
              completeBarColor={theme?.colors.primary}
            />
          </div>
        } */}

        <div className="px-4">
          {currentStep?.type === "ListView1" ? (
            <ListCartSource
              formData={state.formData}
              setFormData={setFormData}
              nextPage={
                state.page + 1 === state.formStepper?.length ? null : nextPage
              }
              target_id={props.target_id}
              currStep={currentStep}
              previousPage={state.page - 1 < 0 ? null : previousPage}
              actionButton={previousStep?.nextButtonAction}
              button_action={props.button_action}
              previewTransaction={setIsOpen}
              formInitialValues={formInitialValues}
              formDefaultValues={formDefaultValues}
              clearCart={clearCart}
              updateRecord={props.updateRecord}
              handleSubmit={props.handleSubmit}
            />
          ) : (
            <Form2
              {...currentStep}
              formStepper={_formStepper}
              edit={props.edit}
              showPreview={props.showPreview}
              button_action={props.button_action}
              target_id={props.target_id}
              formData={state.formData}
              setFormData={setFormData}
              nextPage={
                state.page + 1 === state.formStepper?.length ? null : nextPage
              }
              updatePage={nextPage}
              previousPage={state.page - 1 < 0 ? null : previousPage}
              addItemToCart={addItemToCart}
              deleteItemFromCart={deleteItemFromCart}
              clearCart={clearCart}
              cartItems={state.cart}
              updatePreviewData={updatePreviewData}
              previewTransaction={setIsOpen}
              formInitialValues={formInitialValues}
              formDefaultValues={formDefaultValues}
              updateRecord={props.updateRecord}
              activeCart={state.activeCart}
              newCart={newCart}
              setNewCart={setNewCart}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WithLoader(FormLine1);
