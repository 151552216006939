import * as React from "react";

const RetailIcon = (props) => (
  <svg
    width="30"
    height="28"
    version="1.1"
    id="Icons"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill={props?.fill}
  >
    {/* <style type="text/css">
	.st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
</style> */}
    <g>
      <path
        d="M18.8,21.5c0.3-0.5,0.1-1.1-0.3-1.4L17,19.2V17c0-0.6-0.4-1-1-1s-1,0.4-1,1v2.2l-1.5,0.9c-0.5,0.3-0.6,0.9-0.3,1.4
		c0.3,0.5,0.9,0.6,1.4,0.3l1.4-0.9c0,0,0.1,0,0.1,0s0.1,0,0.1,0l1.4,0.9C17.6,22,17.8,22,18,22C18.3,22,18.7,21.8,18.8,21.5z"
      />
      <path
        d="M11,14l10,0c0.3,0,0.6-0.1,0.8-0.4c0.2-0.2,0.3-0.5,0.2-0.8c-0.3-1.5-1.4-2.7-2.9-3.1c-0.4-0.1-0.7-0.4-0.8-0.8
		C18.7,8.4,19,7.7,19,7V5c0-1.7-1.3-3-3-3s-3,1.3-3,3v2c0,0.7,0.3,1.4,0.7,1.9c-0.1,0.4-0.4,0.7-0.8,0.8c-1.5,0.4-2.6,1.6-2.8,3.1
		c-0.1,0.3,0,0.6,0.2,0.8C10.4,13.9,10.7,14,11,14z"
      />
      <path
        d="M27.1,25.7c-0.4-0.1-0.7-0.4-0.8-0.8c0.4-0.5,0.7-1.2,0.7-1.9v-2c0-1.7-1.3-3-3-3s-3,1.3-3,3v2c0,0.7,0.3,1.4,0.7,1.9
		c-0.1,0.4-0.4,0.7-0.8,0.8c-1.5,0.4-2.6,1.6-2.8,3.1c-0.1,0.3,0,0.6,0.2,0.8c0.2,0.2,0.5,0.4,0.8,0.4l10,0c0.3,0,0.6-0.1,0.8-0.4
		c0.2-0.2,0.3-0.5,0.2-0.8C29.7,27.3,28.5,26.1,27.1,25.7z"
      />
      <path
        d="M11.1,25.7c-0.4-0.1-0.7-0.4-0.8-0.8c0.4-0.5,0.7-1.2,0.7-1.9v-2c0-1.7-1.3-3-3-3s-3,1.3-3,3v2c0,0.7,0.3,1.4,0.7,1.9
		c-0.1,0.4-0.4,0.7-0.8,0.8c-1.5,0.4-2.6,1.6-2.8,3.1c-0.1,0.3,0,0.6,0.2,0.8C2.4,29.9,2.7,30,3,30l10,0c0.3,0,0.6-0.1,0.8-0.4
		c0.2-0.2,0.3-0.5,0.2-0.8C13.7,27.3,12.5,26.1,11.1,25.7z"
      />
    </g>
  </svg>
);

export default RetailIcon;
