import React from 'react';
import TransactionListLine from './TransactionListLine';

function ApprovalTransactionList1(props) {
    
    let {options,setGroupDrill,setGroups, multibutton} = props
    let hasMultiButton = [].concat(multibutton)?.length > 0

    
    return (
        <TransactionListLine data={options} setGroupDrill={setGroupDrill} hasMultiButton={hasMultiButton} setGroups={setGroups} />
    );
}

export default ApprovalTransactionList1;