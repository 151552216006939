import React, { useContext } from "react";
import AppContext from "../contexts/AppContext";
import styled from "styled-components";
import profilePicture from "../assets/images/user.png";

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 144px;
`;

const ProfileMini = (props) => {
  const { name, options } = props;
  const { context } = useContext(AppContext);

  let profileOptions =
    context?.pageConfig?.subcomponents[2]?.subcomponents[0]?.subcomponents[0]
      ?.options[1]?.options;

  const includeKeys = ["Role", "Full Name"];

  const itemKeys = Object.keys((profileOptions ?? [])[0] ?? {}).filter((key) =>
    includeKeys.includes(key)
  );

  console.log("the profile options", profileOptions, itemKeys);

  return (
    <div className="pt-3 p-3 border border-gray-200 rounded-md ">
      <div className="p-2 bg-gray-50">
        <div className="mb-5">
          <ProfileImage className="overflow-hidden m-auto">
            <img src={profilePicture} className="w-full h-full" />
          </ProfileImage>
        </div>

        <div className="">
          {profileOptions &&
            profileOptions.map((record, idx) => (
              <div key={idx} className="">
                <div>
                  {itemKeys?.map((str, idx) => (
                    <div key={idx} className="flex flex-row items-center my-2">
                      <h1 className="text-xs flex-1 font-semibold capitalize ">
                        {str}:
                      </h1>

                      <h1 className="text-xs uppercase text-gray-400 font-normal">
                        {record[str]}
                      </h1>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileMini;
