import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import AsyncSelect from "react-select/async";
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { appendQueryToUri } from '../util/generalUtil';
import apiEndpoints from '../apiEndpoints';
import appFetch from '../appFetch';
import { useFilter, useFilters } from '../contexts/FilterContext';


const CustomOption = ({ innerProps, label, isSelected }) => (
  <div
    {...innerProps}
    className={`relative cursor-default select-none py-2 pl-10 flex text-gray-900`}
  >
    {isSelected && (
      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-white">
        <CheckIcon className="h-5 w-5 text-primary" aria-hidden="true" />
      </span>
    )}
    <span className={`block truncate ${isSelected ? 'font-medium' : 'font-normal'}`}>
      {label}
    </span>
  </div>
);

const CustomDropdownIndicator = ({hasValue}) =>
    (
    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
        <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </div>
);

const CustomValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue, selectProps } = props;
  const selectedOptions = getValue();
  const count = selectedOptions.length;

  return (
    <components.ValueContainer {...props}>
      {!hasValue && children}
      {count === 1 && (
        <div className="ml-2 text-gray-900 truncate" style={{ maxWidth: 'calc(100% - 20px)' }}>
          {selectedOptions[0].label}
        </div>
      )}
      {count > 1 && (
        <div className="flex items-center">
          <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
            {count}
          </span>
          <span className="ml-2 text-gray-900">selected</span>
        </div>
      )}
      {hasValue && children[1]}
    </components.ValueContainer>
  );
};


export default function AdvanceSelect({target,targetId}) {

  const [filter,setFilter] = useFilter(target)
  const [selected, setSelected] = useState(filter ?? []);

  const loadDataOptions = () =>
    new Promise((resolve) => {
      appFetch
        .get(appendQueryToUri(apiEndpoints.dropdownView, {
          targetId: targetId?.toString(),
          target: target?.toString(),
        }))
        .then((result) => {
          console.log("result",result)
          resolve(result);
        })
        .catch((e) => console.log(e.message));
    });

  const handleChange = (selectedOptions) => {
    console.log("calling handle change", selectedOptions)
    setFilter(selectedOptions)
    setSelected(selectedOptions);
  };


  return (
    <div className="w-36">
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        loadOptions={loadDataOptions}
        value={selected}
        onChange={handleChange}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        components={{
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
          IndicatorSeparator: null,
          ValueContainer: CustomValueContainer,
        }}
        styles={{
          control: (base) => ({
            ...base,
            width: '100%',
            minWidth: '100%',
            border: 0,
            outline: "none",
            '&.advance-filter-select__control--is-focused': {
                border: 'none',
                boxShadow: 'none'
            }             
          }),
          menu: (base) => ({
            ...base,
            width: '200px',
            minWidth: '100%',
          }),
          menuList: (base) => ({
            ...base,
            // maxHeight: '200px', // Adjust as needed
          }),
        }}
        className="relative"
        classNamePrefix="advance-filter-select"
      />
    </div>
  );
}