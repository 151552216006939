import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Formik,
  Form as FormikForm,
  useFormikContext,
  ErrorMessage,
  useField,
} from "formik";
import {
  InputField,
  SelectField,
  RadioField,
  LabelField,
  FileField,
  RichTextField,
  PhoneField,
  StyledDate,
  NumberField,
  ColorPicker,
  UrlField,
  ServerPropField,
  LocationField,
  CheckboxField,
} from "./FormElements";
import { mapValidationSchema } from "../formValidation";
// import actions from "../actions"
import * as Yup from "yup";
import ReactJson from "react-json-view";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import Spinner from "../loaders/Spinner";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ConfigContext from "../contexts/AppContext";
import useMenuUpdate from "../hooks/useMenuUpdate";
import toast from "react-hot-toast";
import { defaults } from "../mapInitialValues";
import moment from "moment";
import { formatNumber } from "../util/formatter";
import { useMemo } from "react";
import { mapOperator } from "../util";
import { appendQueryToUri, isValidValue } from "../util/generalUtil";
import { useSubscriber } from "../hooks/useSubscriber";
import MapComponent from "./MapComponent";
import ConfirmDialog from "./ConfirmDialog";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

const formHeaderStore = {};
let cartRoot = "";
let formikStatus = {};
let timeout = null;

const Button = ({ children }) => (
  <button
    type="submit"
    className="justify-self-end bg-primary py-2 px-5 text-white rounded-md"
  >
    {children}
  </button>
);

function mapField(type) {
  switch (type) {
    case "select":
    case "multiselect":
      return SelectField;
    case "radio":
      return RadioField;
    case "dropdown":
      return SelectField;
    case "label":
      return LabelField;
    case "richtext":
      return RichTextField;
    case "upload":
      return FileField;
    case "phonenumber":
      return PhoneField;
    case "colorpicker":
      return ColorPicker;
    case "date":
    case "datetime":
      return StyledDate;
    case "number":
      return NumberField;
    case "url":
      return UrlField;
    case "serverprop":
      return ServerPropField;
    case "location":
      return LocationField;
    case "checkbox":
      return CheckboxField;
    default:
      return InputField;
  }
}

// const FormElement = ({type,...others}) => {
//   let {label, name, options, data} = others
//   const rest = {type,label, name, options, data}

//   if(type == "multiselect") return <SelectField {...rest} />
//   if(type == "radio") return <RadioField {...rest} />
//   if(type == "label") return <LabelField {...rest} />
//   if(type == "richtext") return <RichTextField {...rest} />
//   if(type == "file") return <FileField {...rest} />

//   return <InputField {...rest} />
// }

const FormWithContext = ({ props, schema: formSchema, activeCart }) => {
  console.log("activeCart", activeCart);
  let schema = formSchema;
  // const { values, handleChange,setFieldValue,setValues } = useFormikContext()
  const { values, handleChange, setFieldValue, setValues, setStatus, status } =
    props;
  let computeEvents = {};
  const { context } = useContext(AppContext);

  let activeCartNotEmpty = activeCart !== null;
  useEffect(() => {
    if (activeCartNotEmpty) {
      setValues({ ...values, ...activeCart });
    }
  }, [activeCartNotEmpty]);

  for (let control of schema) {
    let {
      validation,
      name,
      compute,
      script_field,
      options,
      script,
      type,
      use_field,
      server_fields,
    } = control;

    console.log("what are the script fields", script_field, name);

    if (script_field) {
      computeEvents[name] = [];
      console.log("script field is active", computeEvents[name]);

      let func = (() => {
        options = options || [];
        let scriptOptions = [...options];
        let defaultScript = script;

        console.log(
          "script field is func",
          scriptOptions,
          options,
          defaultScript,
          name
        );

        return (e) => {
          console.log("script field is rendered here");

          let { value: fieldValue, name: fieldName } = e.target;
          let scriptProps =
            {}.constructor == fieldValue?.constructor
              ? fieldValue
              : scriptOptions.find((m) => m.value === fieldValue);

          if (defaultScript) {
            scriptProps = scriptProps || { script: defaultScript };
          }

          console.log("scriptProps", scriptProps);
          if (!scriptProps) return;

          let scriptArr = scriptProps.script;

          let newStatus = {};

          scriptArr.forEach((sc) => {
            let { field, ...rest } = sc;
            newStatus[field] = rest;
          });

          console.log("newStatus", newStatus);

          let statusMod = { ...(status || {}), ...newStatus };

          if (Object.keys(statusMod).length > 0) {
            formikStatus = { ...statusMod };
            setStatus(statusMod);
          }
        };
      })();

      computeEvents[name].onChange = (
        computeEvents[name]?.onChange ?? []
      ).concat(func);
      console.log(
        "what does compute events do",
        computeEvents,
        computeEvents[name],
        name,
        computeEvents[name].onChange
      );
    }

    console.log(
      "let see the serverprop server_fields",
      type,
      server_fields,
      name
    );

    if (type === "serverprop" && server_fields) {
      for (let field of server_fields) {
        let func = (() => {
          let fields = [...server_fields];
          let serverControl = { ...control };

          console.log(
            "the serverprop fields and servercontrol fields",
            fields,
            serverControl,
            name
          );
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};

            console.log("what is serverprop e", name, e);

            fieldValue = moment.isDate(fieldValue)
              ? moment(fieldValue).format("YYYY-MM-DD")
              : fieldValue;

            currVals[fieldName] = fieldValue?.value ?? fieldValue;
            fields.forEach((f) => {
              if (f === fieldName) return;
              let value = values[f];
              value = moment.isDate(value)
                ? moment(value).format("YYYY-MM-DD")
                : value;
              currVals[f] = value?.value ?? value;
            });

            if (Object.values(currVals ?? {}).some((x) => !isValidValue(x))) {
              return;
            }

            console.log("the currvalues for serverprop", currVals, name);

            let handler = () => {
              setFieldValue(name, "loading...");
              let payload = {
                jsonString: JSON.stringify([currVals]),
                targetId: serverControl?.target_id?.toString(),
                target: serverControl?.target,
                applicationId: context?.menuId?.toString,
                parentDropValue: null,
              };

              console.log("the payload for serverprop is", payload);
              console.log(
                "the application id for serverprop is",
                context?.menuId
              );

              appFetch
                .post(apiEndpoints.getServerProps, {
                  body: JSON.stringify(payload),
                })
                .then((res) => {
                  console.log("response for serverprop", res);
                  setFieldValue(name, res);
                })
                .catch((e) => console.log("error from serverprop", e.message))
                .finally();
            };

            //debounce here
            console.log("timeout", timeout);
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(handler, 500);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }

    //for computer fields
    if (compute instanceof Object) {
      let { operator, withField, use_field } = compute;
      let reduceFunc = mapOperator(operator);

      for (let field of withField) {
        let func = (() => {
          let fields = [...withField];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return parseFloat(fieldValue?.replace(/\,/g, ""));
              }
              return parseFloat(values[f]?.replace(/\,/g, ""));
            });

            let result = computeValues.reduce(reduceFunc);

            const lineDiscount = parseFloat(
              values["line_unit_discount"] || values["line_discount"]
            );
            const lineInterest = parseFloat(
              values["line_unit_interest"] || values["line_interest"]
            );

            if (lineDiscount) {
              result -= result * (lineDiscount * 0.01);
            }

            if (lineInterest) {
              result += result * (lineInterest * 0.01);
            }

            console.log("computeValues value", result);

            setFieldValue(name, formatNumber(result) || "");
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
      //delete control.compute;
      control.disabled = true;
    }

    if (Array.isArray(use_field)) {
      for (let field of use_field) {
        let func = (() => {
          let fields = [...use_field];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;
            console.log("result is using field", use_field);

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return fieldValue
                  ?.toLowerCase()
                  ?.replace(/[^a-zA-Z\-_ ]/g, "")
                  .trim()
                  .replace(/\s+/g, "-");
              }
              return values[f]
                ?.toLowerCase()
                ?.replace(/[^a-zA-Z\-_ ]/g, "")
                .trim()
                .replace(/\s+/g, "-");
            });

            let result = computeValues.join("");
            console.log("result is", result);

            setFieldValue(name, result);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }
  }

  const getComputeEvent = (name) => {
    return computeEvents[name] || {};
  };

  return (
    <>
      {schema &&
        schema.map((o, idx) => {
          // let key = `${o.target_id}_${idx}`
          let key = `${o.target_id}`;
          let events = computeEvents[o.name] ?? {};

          console.log("the events", events, o.name);
          //eg: onChange: [] onKeyup: []
          let eventHandlers = {};
          Object.keys(events)?.length > 0 &&
            console.log("computeEvents2", {
              computeEvents,
              events: Object.keys(events),
            });
          Object.keys(events).forEach((eventType) => {
            //eventType onChange
            console.log(
              "the events ish for serverprops",
              eventType,
              events,
              o.name
            );

            eventHandlers[eventType] = (e) => {
              //e as target
              console.log(
                "the eventhandlers for serverprops",
                eventHandlers,
                eventType,
                events,
                o.name
              );

              handleChange(e);
              events[eventType].forEach((func) => {
                func(e);
              });
            };
          });

          console.log("eventHandlers", eventHandlers);

          let script = (status ?? {})[o.name];
          let visible = script?.visible ?? eval(o?.visible) ?? true;

          return (
            // <div className="mb-4 grid" key={key}>
            //   {React.createElement(mapField(o.type), {...o,...getComputeEvent(o.name)})}
            // </div>

            <>
              {visible && (
                <div className="mb-4 grid" key={key}>
                  {React.createElement(mapField(o.type), {
                    ...o,
                    ...eventHandlers,
                    status,
                  })}
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

// export default function Form1({name,schema:[{options:schema}],schema:[{submit}],formData,onSubmit,previousPage,edit}) {
export default function Form(props) {
  let {
    name,
    options: schema,
    showPreview,
    target_id,
    preview,
    formData,
    setFormData,
    onSubmit,
    previousPage,
    nextPage,
    edit,
    useCart,
    cartItems,
    addItemToCart,
    updatePreviewData,
    button_action,
    previewTransaction,
    formInitialValues,
    formDefaultValues,
    setFormHidden,
    updateRecord,
    stepId,
    clearCart,
    submit,
    activeCart,
    carts,
    currStep,
  } = props;

  console.log("button_action", {
    button_action,
    edit,
    submit,
    props,
    currStep,
  });
  console.log({ formDefaultValues, formInitialValues }, "form values");

  // console.log("updateRecord",updateRecord)

  let initialValues = {};
  let defaultValues = {};
  let validationSchema = {};
  nextPage = nextPage || null;
  const lastStep = nextPage === null;
  showPreview = eval(showPreview);
  useCart = eval(useCart);

  const [saving, setSaving] = useState(false);
  const { context } = useContext(AppContext);
  const { updateMenu } = useMenuUpdate();
  const { subs } = useSubscriber();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const buildEditData = (postData, editData) => {
    let ref = postData;
    let count = 1;
    let curr = `ID_ROOT_${count}`;
    let prop = "";
    while (true) {
      prop = editData[`ID_ROOT_${count}`];

      //check if next exist
      if (typeof editData[`ID_ROOT_${count + 1}`] == "undefined") break;

      ref[prop] = typeof ref[prop] != "undefined" ? ref[prop] : {};
      ref = ref[prop];

      count++;
    }

    ref[prop] = editData[prop];
    return postData;
  };

  const buildPreview = (values) => {
    let formValues = {};
    for (let control of schema) {
      let { name, type } = control;

      if (/label/i.test(type)) {
        continue;
      }

      formValues[name] =
        type === "number"
          ? values[name]?.replace(/\,/g, "")
          : values[name] || null;
    }
    console.log("MyformValues", formValues);
    return formValues;
  };

  const buildData = (values) => {
    let data = useCart ? {} : { ...formData };
    let previewData = {};

    console.log("the preview and usecart", preview, useCart);
    if (preview && !useCart) {
      previewData[preview] = {};
    }

    console.log("let see newVals schema", schema);

    for (let control of schema) {
      let { name, type, response, cart, preview_section } = control;

      if (/label/i.test(type)) {
        continue;
      }

      let ref = data;
      let count = 1;
      let prop = "";
      while (true) {
        prop = control[`root_${count}`];

        //check if next exist
        if (typeof control[`root_${count + 1}`] == "undefined") break;

        ref[prop] = ref[prop] || {};
        ref = ref[prop];

        count++;
      }

      console.log("formdetails", { values, name });

      let value =
        type === "number"
          ? values[name]?.toString()?.replace(/\,/g, "")
          : values[name];

      if (preview && preview_section) {
        previewData[preview][preview_section] = value;
      }

      console.log(
        "preview, previewSection and previewdata",
        preview,
        preview_section,
        previewData,
        value
      );

      //store cart items
      if (cart === "cart_currency") {
        formHeaderStore[cart] = value;
      }

      if (value instanceof Object) {
        if (Array.isArray(value) && /multiselect/i.test(type)) {
          value = value.map((v) => v.value).filter((v) => v);
        } else if (Array.isArray(value) && /checkbox/i.test(type)) {
          value = value.map((v) => v.value).filter((v) => v);
        } else if (Array.isArray(value) && /upload/i.test(type)) {
          value = value.filter((v) => v);
        } else {
          //value.toString() incase value is date
          value =
            value.value ??
            (moment.isDate(value)
              ? moment(value).format("YYYY-MM-DD h:mmA")
              : value.toString());
        }
        // value = Array.isArray(value) ? value.map(v => v.value).filter(v => v) : value.value;
      }
      // ref[prop] = value ?? (type == "number" ? 0 : "");

      value = response === "array" ? [].concat(value).filter((m) => m) : value;

      //dont add invalid values
      if (typeof value != "undefined" && value != null) {
        if (Array.isArray(ref[prop])) {
          ref[prop] = ref[prop].concat(value);
          ref[prop] = ref[prop].filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        } else {
          ref[prop] = value;
        }
      }
    }
    console.log("builddata", data);
    // console.log("let us see previewdATA", previewData, preview, useCart);

    console.log("builddata is previewdATA active", previewData);
    //update previewCart
    if (Object.keys(previewData).length > 0) {
      updatePreviewData(previewData);
    }

    let newData = {};
    if (useCart) {
      let root_1 = Object.keys(data).slice(-1)[0];
      console.log("builddata what is root_1", root_1, data, formData);

      if (!root_1) {
        throw new Error("root_1 has a null reference");
      }

      let lines = [].concat(formData[root_1] ?? []);
      let line = data[root_1];
      cartRoot = root_1;

      console.log("line", line);
      console.log("lines", lines);

      //new logic
      let { LINE_TOTAL_AMOUNT, LINE_QUANTITY, ...lineJson } = line;
      let lineString = JSON.stringify(lineJson);
      console.log("lineString", lineString);

      let lineIdx = null;
      let lineItem =
        lines &&
        lines.find((m, idx) => {
          let { LINE_TOTAL_AMOUNT, LINE_QUANTITY, ...rowJson } = m;
          let match = JSON.stringify(rowJson) == lineString;
          if (match) {
            lineIdx = idx;
          }
          return match;
        });

      if (lineItem) {
        if (lineItem.LINE_QUANTITY)
          lineItem.LINE_QUANTITY += line.LINE_QUANTITY;

        if (lineItem.LINE_TOTAL_AMOUNT) {
          lineItem.LINE_TOTAL_AMOUNT =
            lineItem.LINE_UNIT_AMOUNT * lineItem.LINE_QUANTITY;

          const lineDiscount = parseFloat(
            lineItem.LINE_DISCOUNT || lineItem.LINE_UNIT_DISCOUNT
          );
          const lineInterest = parseFloat(
            lineItem.LINE_INTEREST || lineItem.LINE_UNIT_INTEREST
          );

          if (lineDiscount) {
            lineItem.LINE_TOTAL_AMOUNT -=
              lineItem.LINE_TOTAL_AMOUNT * (lineDiscount * 0.01);
          }

          if (lineInterest) {
            lineItem.LINE_TOTAL_AMOUNT +=
              lineItem.LINE_TOTAL_AMOUNT * (lineInterest * 0.01);
          }
        }
      }

      //newData[root_1] = (lineData || []).concat(data[root_1])
      newData[root_1] = lineItem
        ? [...lines.filter((m, i) => i != lineIdx), lineItem]
        : [...lines, line];

      console.log("the double click builddata output", formData, newData);

      return { ...formData, ...newData };
    }

    if (edit) {
      return buildEditData(data, edit);
    }

    return data;
  };

  const addToCart = ({ values, setValues, setStatus }) => {
    let cartLine = {};
    let lineValues = {};

    const formikStatusCopy = { ...formikStatus };
    console.log("value of status new", formikStatus);

    let formStepInitialValues = {};

    for (let control of schema) {
      let { name, cart, type } = control;

      if (/label/i.test(type)) {
        continue;
      }

      const rowValue = values[name];
      formStepInitialValues[name] = initialValues[name];
      lineValues[name] =
        type === "number"
          ? values[name]?.toString()?.replace(/\,/g, "")
          : values[name] || null;

      if (cart) {
        if (Array.isArray(values[name])) {
          cartLine[cart] = values[name]?.map((m) => m?.name)?.join(",");
          continue;
        }

        if ({}.constructor === values[name]?.constructor) {
          cartLine[cart] = `${values[name]?.name}=>${values[name]?.value}`;
          continue;
        }

        cartLine[cart] =
          type === "number"
            ? rowValue?.toString().replace(/\,/g, "")
            : values[name] || null;

        if (formikStatusCopy[name]) {
          delete formikStatusCopy[name];
        }
      }
    }

    //logic here

    formikStatus = { ...formikStatusCopy };
    setStatus(formikStatus);
    console.log("value of status new", formikStatus);
    console.log("lineValues", lineValues);
    console.log("formHeaderStore", formHeaderStore);

    console.log("let see newVals values", values);
    let newVals = buildData(values);
    console.log("let see newVals addToCart", newVals);
    let cartCurrency = formHeaderStore.cart_currency?.currency_code ?? "";

    if (cartLine.cart_unit_amount) {
      cartLine.cart_currency = cartCurrency;
      lineValues.line_currency = cartCurrency;

      if (cartLine.cart_quantity) {
        cartLine.cart_total_amount = parseFloat(
          cartLine.cart_quantity * cartLine.cart_unit_amount
        );

        if (cartLine.cart_discount) {
          cartLine.cart_total_amount -=
            cartLine.cart_total_amount * (cartLine.cart_discount * 0.01);
        }
      }
    }

    addItemToCart({ item: cartLine, lineValues, preview, cartRoot });
    setFormData(newVals);

    //reset values after adding to cart
    setValues({ ...values, ...formStepInitialValues });
  };

  const previewMyTranx = ({ values }) => {
    buildData(values);
    previewTransaction(true);
  };

  const postData = (values) => {
    return new Promise((resolve, reject) => {
      let postData = {
        jsonString: JSON.stringify([values]),
        menuId: context?.menuId?.toString(),
        pageButtonActionId: target_id.toString(),
        buttonAction: button_action,
      };

      console.log("preview postData formLine", postData);
      // return;

      setSaving(true);
      appFetch
        .post(`${apiEndpoints.menuAction}?subs=${subs}`, {
          body: JSON.stringify(postData),
        })
        .then((res) => {
          let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = res;

          MySwal.fire({
            text: errMsg,
            icon: errNo === 0 ? "success" : "error",
          });

          if (errNo !== 0) {
            reject(errMsg);
            // throw new Error(errMsg);
          }

          resolve(res);
        })
        .catch((err) => {
          toast.error(err.message ?? "an error occured!");
        })
        .finally(() => setSaving(false));
      // return result;
    });
  };

  const handlePostAsync = async (myVals, resetForm) => {
    let data = await postData(myVals);
    console.log("preview postData inputvalues formline", myVals);
    console.log("result from server", data);
    if (data.constructor == {}.constructor) {
      resetForm && resetForm({ values: formDefaultValues ?? defaultValues });
      //if(data?.ERROR_MESSAGE == "SUCCESS"){
      // setFormHidden(true)

      updateMenu(data?.MENU_BADGE);

      // }
      if (clearCart instanceof Function) {
        clearCart();
      }
      let savedRecords =
        data?.layout?.subcomponents?.find(
          ({ type }) => type === "SavedRecordList1"
        ) ??
        data?.layout?.subcomponents?.find(
          ({ type }) => type === "SavedTransactionList1"
        );
      updateRecord(savedRecords);
    }
  };

  useCart = /true/i.test(useCart);

  onSubmit = onSubmit ?? nextPage;

  schema = schema || [];

  for (let control of schema) {
    let { type, value, name, defaultValue } = control;

    if (/label/i.test(type)) {
      continue;
    }

    let controlValue = defaults(type);

    initialValues[name] = typeof value == "undefined" ? controlValue : value;
    defaultValues[name] = controlValue;

    // let validation = mapValidationSchema(control)
    // if (validation){
    //   validationSchema[name] = validation
    // }
  }

  console.log("initialValues", initialValues);
  console.log("validationSchema", validationSchema);

  // let testInitialValues = {...initialValues, "interests": ["reading", "traveling"]}

  // console.log("testInitialValues", testInitialValues);

  let getValidationSchema = ({ schema, formikStatus }) => {
    let validationSchema = {};
    for (let control of schema) {
      let { type, name } = control;

      if (/label/i.test(type)) {
        continue;
      }

      let validation = mapValidationSchema(control, formikStatus);

      if (validation) {
        validationSchema[name] = validation;
      }
    }
    return validationSchema;
  };

  // let dummyData = {
  //   target_id: 7159,
  //   target: "LINE_GENERAL",
  //   name: "interests",
  //   type: "checkbox",
  //   label: "Select your interests",
  //   root_1: "_LINE",
  //   root_2: "LINE_GENERAL",
  //   root_3: "line_upload",
  //   edit: "LINE_UPLOAD",
  //   validation: {
  //     required: true,
  //   },
  //   options: [
  //     { label: "Reading", value: "reading" },
  //     { label: "Traveling", value: "traveling" },
  //     { label: "Cooking", value: "cooking" },
  //   ],
  // };

  return (
    <div className="form">
      <header className=""></header>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Formik
        initialValues={formInitialValues || initialValues}
        validationSchema={() =>
          Yup.object(getValidationSchema({ schema, formikStatus }))
        }
        onSubmit={async (values, { setValues, resetForm, setStatus }) => {
          if (
            useCart &&
            currStep.allowEmpty == "false" &&
            cartItems.length == 0
          ) {
            MySwal.fire({
              text: "Add item to cart to continue",
              icon: "info",
            });
            return;
          }

          let inputValues = { ...formData };

          let myVals = useCart ? inputValues : buildData(values);

          console.log(
            "the double click inputvalues, formdata, useCart",
            inputValues,
            formData,
            useCart
          );
          console.log("the double click myVals", myVals);
          console.log("the double click onSubmit ish", onSubmit);
          if (lastStep) {
            console.log("preview postData inputvalues formline", myVals);
            console.log("preview current values", values);
            setConfirmDialog({
              isOpen: true,
              title: "Are you sure you want to proceed?",
              subtitle: "You can't undo this operation",
              onConfirm: async () => {
                await handlePostAsync(myVals, resetForm);
              },
            });
            return;
          }

          setConfirmDialog({
            isOpen: true,
            title: "Are you sure you want to proceed?",
            subtitle: "You can't undo this operation",
            onConfirm: async () => {
              await onSubmit(myVals);
            },
          });
        }}
      >
        {(props) => {
          const {
            values,
            resetForm,
            validateForm,
            submitForm,
            setValues,
            setStatus,
            setTouched,
            isValid,
          } = props;
          return (
            <div className="">
              <FormikForm className="grid content-center ">
                {/* <ReactJson src={values} />   */}
                <FormWithContext
                  props={props}
                  targetId={target_id}
                  schema={schema}
                  activeCart={activeCart}
                />
                {/* <div className="pb-4">
                  <LocationField label={"Position"} name={"the Name is"} />
                  <MapComponent />
                  <CheckboxField
                    name="interests"
                    label="Select your interests"
                    options={[
                      { label: "Reading", value: "reading" },
                      { label: "Traveling", value: "traveling" },
                      { label: "Cooking", value: "cooking" },
                    ]}
                    validation={{ required: true }}
                  />

                  <RadioField
                    name="radioOption"
                    label="Select an Option"
                    options={[
                      { label: "Option 1", value: "option1" },
                      { label: "Option 2", value: "option2" },
                      { label: "Option 3", value: "option3" },
                    ]}
                    validation={{ required: true }}
                  />
                </div> */}
                {console.log("the values are", values)}
                <div className="flex gap-2 justify-end">
                  {previousPage && (
                    <button
                      type="button"
                      onClick={previousPage}
                      className="justify-self-end bg-primary py-2 px-5 text-white rounded-md"
                    >
                      &#8592;
                    </button>
                  )}
                  {useCart && (
                    <button
                      type="button"
                      onClick={async () => {
                        // submitForm();

                        let validated = await validateForm(values);
                        console.log("fields validation result", validated);
                        if (Object.keys(validated).length == 0) {
                          addToCart({ values, setValues, setStatus });
                        }
                      }}
                      className="justify-self-end bg-primary py-2 px-5 text-white rounded-md text-sm font-medium capitalize"
                    >
                      Add to Cart
                    </button>
                  )}
                  {!nextPage && showPreview && (
                    <button
                      type="button"
                      onClick={() => previewMyTranx({ values })}
                      className="justify-self-end bg-primary py-2 px-5 text-white rounded-md text-sm font-medium capitalize"
                    >
                      Preview
                    </button>
                  )}
                  <button
                    type="button"
                    className="justify-self-end bg-primary py-2 px-5 text-white rounded-md text-sm font-medium capitalize"
                    onClick={async () => {
                      if (useCart && cartItems.length > 0) {
                        //not last step? then goto next page.

                        if (lastStep) {
                          let inputValues = { ...formData };
                          console.log(
                            "preview postData inputvalues formline",
                            inputValues
                          );
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure you want to Proceed?",
                            subtitle: "You can't undo this operation",
                            onConfirm: async () => {
                              await handlePostAsync(inputValues);
                            },
                          });
                          return;
                        }

                        onSubmit && onSubmit(null);
                        return;
                      }

                      // setConfirmDialog({
                      //   isOpen: true,
                      //   title: "Are you sure you want to Proceed?",
                      //   subtitle: "You can't undo this operation",
                      //   onConfirm: async () => {
                      //     await submitForm();
                      //   },
                      // });
                      submitForm();
                    }}
                  >
                    {nextPage ? (
                      <> &#8594; </>
                    ) : saving ? (
                      <Spinner />
                    ) : (
                      <>
                        {" "}
                        {edit
                          ? "Update"
                          : button_action?.toLowerCase() ?? "&#8594;"}
                      </>
                    )}
                  </button>
                  {
                    <button
                      type="button"
                      onClick={() => {
                        resetForm({
                          values: formDefaultValues ?? defaultValues,
                        });
                      }}
                      className="justify-self-end bg-primary py-2 px-5 text-white rounded-md"
                    >
                      Clear All
                    </button>
                  }
                </div>
              </FormikForm>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
