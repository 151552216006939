import React from 'react';

function MainMemoArea1({children}) {
    return (
        <div className="pt-14 w-rb px-6 lg:pt-12 h-full overflow-hidden">
            <div className="block h-full">
                {children}
            </div>
        </div>
    );
}

export default MainMemoArea1;