import React, {useContext, useEffect, useState} from 'react';
import api from "../endpoints"
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import FilterIcon from '../icons/FilterIcon';
import AppContext from '../contexts/AppContext'
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import styled from 'styled-components';

const EmptyRecord  = () => {
   return ( <div className='grid place-content-center place-items-center h-80 bg-gray-100 rounded-sm mb-5'>
        <EmptyTransactionIcon  />
        <h2 className='font-semibold text-md'>No chart available</h2>
    </div>)
}


const options2 = {
	"type": "ChartControl3",
	"name": "Pie Chart",
	"options": [
		{
			"targetid": 21795,
			"target": "modal",
			"layoutid": "76",
            "title": {
                "text": "Expected Earnings this month",
                "x": -10
            },
            "total": 69700,
			"series": [
				{
					"data": [
						{
							"name": "Shoes",
							"y": 7660.00
						},
						{
							"name": "Gaming",
							"y": 2820.00
						},
                        {
                            "name":"Others",
                            "y":45257.00
                        }
					]
				}
			]
		}
	]
}
    //     const ChartContainer = styled.div`
    //     /* Add custom CSS styles to adjust spacing */
    //     .highcharts-legend-item {
    //       display: flex;
    //       justify-content: space-between;
    //       align-items: center;
    //     }
    //   `;

function ChartControl1({name,options}) {
    const {context} = useContext(AppContext)
    options = context?.chartControlOptions ?? options;

    const graphData = options2.options;    

    console.log('graphData', graphData)

    const graphOptions = {
                chart: {
                    borderRadius: 40,
                    style: {
                        'float': 'left',
                    },
                    marginRight: 210,
                    height: 200,
                    type: 'pie',
                        },
                    title:{
                        text: 'Project Earnings in April',
                        // align: 'left',
                        // marginRight: 60,
                        x: -80,
                        style:{
                            // fontFamily: 'sans-serif',
                            fontSize: '22px',
                        }
                    },
                    legend: {
                        align: 'right',
                        width:'70%',
                        margin: 0,
                        y:20,
                        x: 20,
                        floating: false,
                        layout: 'vertical',
                        verticalAlign: 'middle',
                        itemStyle: {
                            fontSize: '16px',
                        },
                            // labelFormatter: function () {
                            //     let name = this.name.length > 20 ? this.name.substr(0, 20) + '...' : this.name;
                            //     console.log(this)
                            //     let value = `$${this.y}`; // Add the desired currency symbol if needed
                            //     let space = '\u00A0'; // Non-breaking space
                            //     let spaceCount = 6; // Adjust the number of spaces as needed

                            //     // Add the desired number of spaces between the name and value
                            //     let spaces = space.repeat(spaceCount);

                            //     return name + spaces + value;                     
                            //     }
                            labelFormatter: function () {
                                let name = this.name.length > 20 ? this.name.substr(0, 20) + '...' : this.name;
                                console.log(this)
                                return `${name}   $${this.y}`
                            }
                      },
                    plotOptions: {
                        pie: {
                            size:70,
                            borderColor: null,
                            borderWidth: 12,
                            slicedOffset: 20,
                            innerSize: '99%',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                            }
                    },...options2?.options[0]
                    // series: [{
                    //         data: [
                    //             ['Shoes', 7660.00],
                    //             ['Gaming', 2820.00],
                    //             ['Others', 45257.00]
                    //             ]
                    //             }],
    };

    // const graphOptions = options2?.options;
    // const graphData = graphOptions[0];

    

    return (
        <div className='w-1/4 shadow-inner rounded-lg rounded-b-lg'>
                <div id="graph" className="mb-10 shadow-lg rounded-lg">
                <div className=''>
                <div className='flex items-center flex-row pt-6'>
                <span className='font-bold text-3xl pl-6 pr-6'>${options2.options[0].total}</span>
                <div className='font-semibold bg-green-200 text-green-700 rounded-lg pr-2 pl-2'>{'\u2191'} 2.2% </div>
                </div>
                <div>
                    {
                        // <ChartContainer>
                    <HighchartsReact
                    highcharts={Highcharts}
                    options={{...graphOptions, ...options2?.options[0]}} 
                    containerProps={{ style: { width: "100%" } }}
                    />
                    // </ChartContainer>
                    
                    }
                    </div>
                    </div>
                </div>
        </div>
    );
}

export default ChartControl1;   