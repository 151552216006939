import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";

function ListPagination({
  search,
  records,
  itemsPerPage,
  pageRangeDisplayed,
  targetId,
  schemaId,
  setLoading,
  changedRecords,
  pages,
  layoutId,
  setNewPage,
  page,
  currentDate,
}) {
  const [currentItems, setCurrentItems] = useState(records);

  useEffect(() => {
    setCurrentItems(records);
    changedRecords(records);
  }, [search]);

  const fetchData = async (currentPage) => {
    setLoading(true);
    const postData = {
      LayoutId: layoutId,
      targetId: targetId,
      schemaId: schemaId,
      target: "PAGING",
      page: currentPage,
      pageSize: itemsPerPage,
      dateFilter: currentDate ? JSON.stringify(currentDate) : null,
    };
    console.log("the pagination postData", postData);
    try {
      const res = await appFetch.get(
        appendQueryToUri(apiEndpoints.menuView, postData)
      );
      const resJson = res.options[0]?.grid || [];
      console.log("the pagination response is", res);
      setCurrentItems(resJson);
      changedRecords(resJson);
    } catch (e) {
      console.log(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = async (event) => {
    const newPage = event.selected; // ReactPaginate provides the new page index
    setNewPage(newPage); // Update the active page
    await fetchData(newPage + 1); // Adjust for 1-based indexing in your API
  };

  console.log("The pagination active page is", page);
  return (
    <>
      {records?.length > 0 && pages > 1 && (
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={pageRangeDisplayed}
          pageCount={pages}
          previousLabel="Previous"
          forcePage={page} // Set the current active page
          containerClassName="inline-flex items-center -space-x-px"
          previousLinkClassName="block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          nextLinkClassName="block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          pageLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          breakLinkClassName="py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          activeLinkClassName="z-10 py-2 px-3 leading-tight text-blue-600 bg-blue-50 border border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
        />
      )}
    </>
  );
}

export default ListPagination;
