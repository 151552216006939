import React, {
  useContext,
  useState,
  Fragment,
  useCallback,
  useEffect,
} from "react";
import { ThemeContext } from "styled-components";
import CategoryFillIcon from "../icons/CategoryFillIcon";
import { ArrowRightIcon } from "@heroicons/react/solid";

import PrintOutlineIcon from "../icons/PrintOutlineIcon";
import EditIcon from "../icons/EditIcon";
import DeleteIcon from "../icons/DeleteIcon";
import { Dialog, Transition, Menu } from "@headlessui/react";
import ReactJson from "react-json-view";
import classNames from "classnames";
import RightArrowIcon from "../icons/RightArrowIcon";
import WithLoader from "./WithLoader";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import apiEndpoints from "../apiEndpoints";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import DrillModalDialog from "./DrillModalDialog";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import {
  ViewActiveIcon,
  ViewInactiveIcon,
  PrintActiveIcon,
  PrintInactiveIcon,
  ApprovedActiveIcon,
  ApprovedInactiveIcon,
  DependencyActiveIcon,
  DependencyInactiveIcon,
} from "../LineIcons";
import SearchControl1 from "./SearchControl1";
import { appendQueryToUri } from "../util/generalUtil";
import ListPagination from "./ListPagination";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No record available</h2>
    </div>
  );
};

const BadgeValue = (value) => {
  let color;
  if (value == "PENDING" || value == "Pending") {
    color = "bg-yellow-500";
  } else if (value == "APPROVED" || value == "Approved") {
    color = "bg-green-700";
  } else if (value == "REJECTED" || value == "Rejected") {
    color = "bg-red-700 ";
  } else if (value == "OPEN" || value == "Open") {
    color = " bg-emerald-300 ";
  } else if (value == "ACTIVE" || value == "Active") {
    color = "bg-blue-600";
  } else if (value == "CLOSED" || value == "Closed") {
    color = "bg-blue-600";
  } else {
    color = "bg-gray-600 ";
  }
  // let style = `capitalize text-xs text-white block ${color} rounded-md p-1 my-1 text-center max-w-min truncate`;
  let style = `rounded-tr-lg rounded-bl-lg w-full justify-end absolute top-0 right-0 max-w-min my-1 capitalize text-xs ${color} px-2 py-1 origin-center -translate-y-1 text-white truncate`;

  return (
    <span className={style} dangerouslySetInnerHTML={{ __html: value }}></span>
  );
};

const PreviewRecord = (item) => {
  let { activeRecord: record, isOpen, setIsOpen } = item;
  let recordProps = Object.keys(record).filter(
    (str) =>
      !["details", "edit", "Line_1", "Line_2", "Line_3", "Line_4"].includes(str)
  );
  // console.log("recordProps",recordProps);
  // console.log("record", record)
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      {/* <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen h-full px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900">
                  Detail
                </Dialog.Title>
                <div className="mt-2">
                    <ul className='grid gap-1 my-6'>
                        {recordProps.filter(s => !["details","ID"].includes(s)).map((str, idx) => {
                            return (
                                <li className={classNames('flex justify-between')} key={idx}> 
                                    <div className={classNames("py-2 px-3 w-36",{"bg-gray-200": ((idx+1) % 2) != 0})}><span className='text-gray-700 font-semibold text-sm'>{str}</span></div>
                                    <div className={classNames("ml-0.5 py-2 px-3 flex-grow", {"bg-gray-200": ((idx+1) % 2) != 0})}><span className='text-sm'>{record[str]}</span></div>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
                    onClick={closeModal}>
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition> */}

      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
        open={isOpen}
      >
        <div className="min-h-screen h-full px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <span className="inline-block h-full align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
            <Dialog.Title
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Detail
            </Dialog.Title>
            <div className="mt-2">
              <ul className="grid gap-1 my-6">
                {recordProps
                  .filter((s) => !["details", "ID"].includes(s))
                  .map((str, idx) => {
                    return (
                      <li
                        className={classNames("flex justify-between")}
                        key={idx}
                      >
                        <div
                          className={classNames("py-2 px-3 w-36", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-gray-700 font-semibold text-sm">
                            {str}
                          </span>
                        </div>
                        <div
                          className={classNames("ml-0.5 py-2 px-3 flex-grow", {
                            "bg-gray-200": (idx + 1) % 2 != 0,
                          })}
                        >
                          <span className="text-sm">{record[str]}</span>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>

            <div className="mt-4">
              <button
                type="button"
                className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

function SavedTransactionList1({ options, target_id, layoutid, setLoading }) {
  const theme = useContext(ThemeContext);
  // const [records,setRecords] = useState(options)

  const [showPreview, setShowPreview] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [activePage, setActivePage] = useState(0); // Track the active page (0-based index)

  const { context } = useContext(AppContext);
  const [search, setSearch] = useState(null);

  const [valuee, setValue] = useState([]);

  useEffect(() => {
    if (search) return serverSearch();
  }, [search]);

  console.log(options);

  const handleSearch = (() => {
    let timeout;

    return (e) => {
      let value = e.target.value;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearch(value);
      }, 300);
    };
  })();

  const reInit = () => setSearch(null);

  let records = (options ?? [])[0]?.grid?.filter((item) => {
    if (search == null) return true;
    let searchBody = `${item["Caption 1"]} ${item["Caption 2"]} ${
      item["Caption 3"]
    } ${item["Caption 4"]} ${item["Caption 5"] ?? ""} ${
      item["Caption 6"] ?? ""
    }`;
    return searchBody?.toLowerCase().search(search.toLowerCase()) !== -1;
  });

  let searchValue = valuee.filter((item) => {
    if (search == null) return true;
    let searchBody = `${item["Caption 1"]} ${item["Caption 2"]} ${
      item["Caption 3"]
    } ${item["Caption 4"]} ${item["Caption 5"] ?? ""} ${
      item["Caption 6"] ?? ""
    }`;
    return searchBody?.toLowerCase().search(search.toLowerCase()) !== -1;
  });

  const showRecordPreview = (item) => {
    setIsOpen(true);
    setActiveRecord(item);
  };

  const showDetail = (action, item) => {
    console.log("item", item);

    setLoading(true);
    let postData = {
      urlMenu: null,
      layoutId: item.layoutid,
      targetId: item.targetid,
      target: item.target,
      schemaId: null,
      txnGroup: null,
      parentDropValue: null,
      drillId: item.drillid?.toString(),
      targetName: item?.target_name,
      targetType: action ?? item?.target_type,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        const { layoutid, drillid } = item;
        console.log("modal data", {
          ...res,
          layoutid,
          targetid: target_id,
          drillid,
          targetType: item?.target_type,
        });
        setModalData({
          ...res,
          layoutid,
          targetid: target_id,
          drillid,
          targettype: item?.target_type,
        });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const deleteData = async (values) => {
    let postData = {
      jsonString: JSON.stringify([values]),
      menuId: context?.menuId,
      buttonAction: "DELETE",
      pageButtonActionId: target_id,
    };

    console.log("data to delete", postData);

    setLoading(true);
    appFetch
      .post(apiEndpoints.menuAction, {
        body: JSON.stringify(postData),
      })
      .then((res) => {
        let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = res;

        MySwal.fire({
          text: errMsg,
          icon: errNo === 0 ? "success" : "error",
        });

        if (errNo !== 0) {
          throw new Error(errMsg);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));

    // let json = await response.json();
    // console.log("response from server", json)
    return "deleted";
  };

  // const deleteRecord = async (item) => {
  //     console.log("item to delete")
  //     let {ID} = item;

  //     let data = {};
  //     let ref = data;
  //     let target = item["root"];
  //     if(typeof target != "undefined"){
  //       ref[target] = {};
  //       ref = ref[target];
  //     }

  //     ref["ID"] = ID

  //     let result = await deleteData(data);
  //     if(result == "deleted"){
  //       const newRecords = records.filter(i => i.ID != item.ID);
  //       setRecords([...newRecords]);
  //     }
  // }

  records = records || [];

  const optionsData = (options ?? [])[0];

  const [serverResults, setServerResults] = useState([]);
  const [serverSearched, setServerSearched] = useState(false);

  const serverSearch = useCallback(() => {
    let timerId = null;
    setServerSearched(false);
    const searches = () => {
      let postData = {
        urlMenu: null,
        LayoutId: layoutid,
        targetId: target_id,
        target: "PAGING",
        schemaId: null,
        parentDropValue: null,
        drillId: null,
        targetType: null,
        targetName: null,
        pageSize: optionsData?.records_per_page,
        searchText: search,
      };

      appFetch
        .get(appendQueryToUri(apiEndpoints.menuView, postData))
        .then((res) => {
          console.log("server search", res);
          console.log("Server search", res?.optionsData.grid);
          setServerResults(res?.optionsData.grid);
          setServerSearched(true);
        })
        .catch((e) => console.log(e.message));
    };

    const debounceSearch = () => {
      if (timerId) {
        clearTimeout(timerId);
      }

      timerId = setTimeout(searches, 300);
    };

    debounceSearch();
  });

  const combinedResults = [
    ...searchValue,
    ...serverResults?.filter(
      (serverResult) =>
        !searchValue?.some(
          (clientResult) => clientResult.ID === serverResult.ID
        )
    ),
  ];

  return (
    <>
      {/* {isOpen && <PreviewRecord {...{activeRecord, isOpen, setIsOpen}}  />} */}
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
        />
      )}
      <div className="grid gap-2 record-list">
        {(records?.length > 0 || search) && (
          <SearchControl1
            handleSearch={handleSearch}
            search={search}
            searched={serverSearched}
          />
        )}
        {records &&
          (serverSearched ? combinedResults : searchValue)?.map((item, idx) => {
            // const {Line_1,Line_2,Line_3,Line_4} = item
            return (
              <div
                className="shadow-1/2 relative flex border border-md rounded-lg shadow-1/2 mb-4 items-center p-4"
                key={idx}
              >
                <div class="grid grid-cols-2 pl-16 flex items-center gap-4 relative w-full">
                  <div
                    className="absolute left-0 border w-12 h-12 rounded-lg grid place-items-center"
                    style={{ backgroundColor: "#F3F3F3" }}
                  >
                    <span className="text-xs font-bold inline-grid place-items-center">
                      {item["Ref"]}
                    </span>
                  </div>
                  <div className="grid">
                    {item["Caption 1"] && (
                      <span
                        className="capitalize text-sm block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 1"] }}
                      ></span>
                    )}
                    {item["Caption 3"] && (
                      <span
                        className="capitalize text-xs text-gray block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 3"] }}
                      ></span>
                    )}
                    {item["Caption 5"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 5"] }}
                      ></span>
                    )}
                    {item["Caption 7"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 7"] }}
                      ></span>
                    )}
                    {item["Caption 9"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 9"] }}
                      ></span>
                    )}
                    {item["Caption 11"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 11"] }}
                      ></span>
                    )}
                  </div>
                  <div className="grid">
                    {item["Caption 2"] && (
                      <span
                        className="capitalize text-sm block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 2"] }}
                      ></span>
                    )}
                    {/* {item["Caption 4"] && BadgeValue(item["Caption 4"])} */}
                    {item["Caption 4"] && (
                      <span
                        className="capitalize text-xs text-gray block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 4"] }}
                      ></span>
                    )}
                    {item["Caption 6"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 6"] }}
                      ></span>
                    )}
                    {item["Caption 8"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        dangerouslySetInnerHTML={{ __html: item["Caption 8"] }}
                      ></span>
                    )}
                    {item["Caption 10"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 10"] }}
                      ></span>
                    )}
                    {item["Caption 12"] && (
                      <span
                        className="capitalize text-xs block truncate"
                        style={{ color: "#9a9fad" }}
                        dangerouslySetInnerHTML={{ __html: item["Caption 12"] }}
                      ></span>
                    )}
                    {/* {item["Status"] && (
                      <span
                        // className="absolute capitalize text-xs text-white block ${color} rounded-md p-1 my-1 text-center max-w-min truncate"
                        className="absolute -top-4 -right-10 max-w-min my-1 rounded-md capitalize text-xs bg-yellow-400 px-2 py-1 origin-center -translate-y-1"
                        dangerouslySetInnerHTML={{ __html: "APPROVED" }}
                      ></span>
                    )} */}
                  </div>
                </div>
                {item["Status"] && BadgeValue(item["Status"])}
                <Menu as="div" className="relative inline-block text-left pt-2">
                  <div>
                    <Menu.Button
                      className={`inline-flex ${
                        item["Status" && "pt-5"]
                      } justify-center w-full px-0.5 py-1 text-sm font-medium text-custom-gray-200 rounded-sm align-bottom`}
                    >
                      <DotsVerticalIcon
                        width={12}
                        height={12}
                        className="w-5 h-5 text-violet-200 hover:text-violet-100"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute z-10 right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => showDetail("VIEW", item)}
                              className={`${
                                active
                                  ? "bg-primary text-white"
                                  : "text-gray-900"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              {active ? (
                                <ViewActiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ViewInactiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              )}
                              View
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => showDetail("PRINT", item)}
                              className={`${
                                active
                                  ? "bg-primary text-white"
                                  : "text-gray-900"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              {active ? (
                                <PrintActiveIcon
                                  className="w-5 h-5 mr-2 text-violet-400"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PrintInactiveIcon
                                  className="w-5 h-5 mr-2 text-violet-400"
                                  aria-hidden="true"
                                />
                              )}
                              Print
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => showDetail("APPROVAL", item)}
                              className={`${
                                active
                                  ? "bg-primary text-white"
                                  : "text-gray-900"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              {active ? (
                                <ApprovedActiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              ) : (
                                <ApprovedInactiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              )}
                              Approval History
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => showDetail("CHAIN", item)}
                              className={`${
                                active
                                  ? "bg-primary text-white"
                                  : "text-gray-900"
                              } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                            >
                              {active ? (
                                <DependencyActiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              ) : (
                                <DependencyInactiveIcon
                                  className="w-5 h-5 mr-2"
                                  aria-hidden="true"
                                />
                              )}
                              Dependencies
                            </button>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            );
          })}
        <ListPagination
          search={search}
          records={records}
          itemsPerPage={optionsData?.records_per_page}
          pageRangeDisplayed={3}
          item={showRecordPreview}
          changedRecords={(record) => setValue(record)}
          targetId={target_id}
          pages={optionsData?.pages}
          setLoading={setLoading}
          layoutId={layoutid}
          setNewPage={(page) => setActivePage(page)}
          page={activePage}
        ></ListPagination>
      </div>
      {(combinedResults?.length == 0 || !combinedResults) && (
        <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
          <EmptyTransactionIcon />
          <h2 className="font-semibold text-sm mt-2">
            No record found {search && `that matches "${search}"`}{" "}
          </h2>
        </div>
      )}

      {!serverSearched && search && (
        <div className="grid place-content-center place-items-center h-80 rounded-sm mb-5">
          <h2 className="font-semibold text-md mt-2">
            Fetching more results for {search && `"${search}"`}{" "}
          </h2>
        </div>
      )}
    </>
  );
}

export default WithLoader(SavedTransactionList1);
