import * as React from "react";

const LotteryIcon = (props) => (
  <svg
    viewBox="0 0 17 15"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.125 10.75V11.5625C9.125 12.209 8.86819 12.829 8.41107 13.2861C7.95395 13.7432 7.33397 14 6.6875 14H3.4375C2.79103 14 2.17105 13.7432 1.71393 13.2861C1.25681 12.829 1 12.209 1 11.5625V3.4375C1 2.79103 1.25681 2.17105 1.71393 1.71393C2.17105 1.25681 2.79103 1 3.4375 1H6.6875C7.33397 1 7.95395 1.25681 8.41107 1.71393C8.86819 2.17105 9.125 2.79103 9.125 3.4375V4.25M12.375 10.75L15.625 7.5L12.375 10.75ZM15.625 7.5L12.375 4.25L15.625 7.5ZM15.625 7.5H4.25H15.625Z"
      stroke="inherit"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LotteryIcon;
