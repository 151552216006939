import * as React from "react";

const ArrowDownRightIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 512 512" version="1.1">
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="add" fill="#000000" transform="translate(106.666667, 64.000000)">
        <polygon
          id="Shape"
          points="320 234.666667 192 362.666667 161.706667 332.373333 238.293333 256 -1.42108547e-14 256 -1.42108547e-14 0 42.6666667 0 42.6666667 213.333333 238.293333 213.333333 161.706667 136.96 192 106.666667"
        ></polygon>
      </g>
    </g>
  </svg>
);

export default ArrowDownRightIcon;
