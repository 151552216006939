import React, { Component, useContext, useEffect, useMemo, useState } from 'react';
import renderLayout from "./renderLayout";
import {BrowserRouter as Router, Route,Switch, withRouter} from "react-router-dom"
import "./mock-fetch"
import "./assets/fonts/product-sans/style.css"
import AppContext from './contexts/AppContext';
import {GlobalStyles} from "./theme/GlobalStyles"
import { ThemeProvider } from "styled-components";
import { useTheme } from './theme/useTheme';
import appFetch from './appFetch';
import apiEndpoints from './apiEndpoints';
import WithThemeWrapper from './theme/WithThemeWrapper';
import { appendQueryToUri } from './util/generalUtil';
import { SubscriberProvider } from './hooks/useSubscriber';
import LeftBarContext from './contexts/LeftBarContext';
import { FilterProvider } from './contexts/FilterContext';


function App(props){
    const {context,setContext} = useContext(AppContext)
    const [menuCollapse,setMenuCollapse] = useState(false);
    // const { theme, themeLoaded, setMode } = useTheme(context?.theme)

    let {location, theme} = props;
    let {pathname} = location;

    let currentPath = pathname.split("/").slice(-1)[0]?.toLowerCase() || "home"
    currentPath = decodeURI(currentPath.replace(/\//, "")).replace(/\-/g," ")

    useEffect(() => {
        if(currentPath == "login") return;

        let postData = {    
            "urlMenu": currentPath,
            "layoutId": null,
            "targetId": null,
            "target": null,
            "schemaId": null,
            "txnGroup": null,
            "parentDropValue":null
          }
        
        appFetch.get(appendQueryToUri(apiEndpoints.menuView, postData)).then(res => {
            setContext(prev => ({...prev, pageConfig: res}))
        })

    },[])

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles/>
            <LeftBarContext.Provider value={{menuCollapse,setMenuCollapse}}>
                <FilterProvider initialFilters={{ }}>
                    {renderLayout(context.pageConfig)}
                </FilterProvider>
            </LeftBarContext.Provider>
        </ThemeProvider>
    ) 
}

export default WithThemeWrapper(App);