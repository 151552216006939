import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MenuAlt2Icon,
  XIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LeftBarContext from "../contexts/LeftBarContext";
import RecentTransactionList2 from "./RecentTransactionList2";
import FavouriteList2 from "./FavouriteList2";
import ProfileMini from "./ProfileMini";

const LeftBarToggler = styled.div`
  width: 26px;
  height: 26px;
  background: #fcfcfc;
`;
const LeftBarArrowToggler = styled.div`
  width: 26px;
  height: 26px;
  background: #fcfcfc;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
`;

function LeftArea1({ children }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(
    window.innerWidth <= 768 ? true : false
  );
  const [menuCollapse, setMenuCollapse] = useState(false);

  const resizeMenuBar = (e) => {
    if (window.matchMedia("(max-width: 1024px)").matches) {
      setMenuCollapse(false);
    }
  };

  // useEffect(() => {
  //     resizeMenuBar()
  //     window.addEventListener("resize", resizeMenuBar)
  //     return () => {
  //         window.removeEventListener("resize", resizeMenuBar);
  //     }
  // }, [])

  return (
    <LeftBarContext.Provider
      value={{
        mobileMenuOpen,
        setMobileMenuOpen,
        menuCollapse,
        setMenuCollapse,
      }}
    >
      <div className="lg:hidden fixed left-0 top-2 w-auto h-12 z-20 flex flex-col justify-center">
        <LeftBarToggler
          className="lg:hidden w-16 h-16 ml-4 inline-grid place-items-center"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          {mobileMenuOpen ? (
            <MenuAlt2Icon width={20} height={20} />
          ) : (
            <XIcon width={20} height={20} />
          )}
        </LeftBarToggler>
      </div>
      <div
        className={classNames(
          "lg:max-w-xs transition-transform h-full z-10 lg:relative fixed left-0 top-0 transform lg:transform-none bg-white",
          { "-translate-x-full": mobileMenuOpen },
          { "w-24": menuCollapse },
          { "lg:w-rb": !menuCollapse }
        )}
      >
        <LeftBarArrowToggler
          className="z-10 rounded-full hidden lg:inline-grid absolute right-0 transform translate-x-1/2 translate-y-20 place-items-center cursor-pointer"
          onClick={() => setMenuCollapse(!menuCollapse)}
        >
          {menuCollapse ? (
            <ChevronRightIcon width={24} height={24} />
          ) : (
            <ChevronLeftIcon width={24} height={24} />
          )}
        </LeftBarArrowToggler>
        <div
          className={classNames(
            "min-h-screen overflow-auto menu-sidebar border-r border-gray-custom h-full pb-6",
            {
              "px-8": !menuCollapse,
              "flex flex-col items-center": menuCollapse,
            }
          )}
        >
          {children}
          {/* <FavouriteList2 /> */}
          {/* <RecentTransactionList2 /> */}
          {/* <ProfileMini /> */}
        </div>
      </div>
    </LeftBarContext.Provider>
  );
}

export default LeftArea1;
