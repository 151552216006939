import React from "react";

function MiddleArea1_3({ children }) {
  return (
    <div className="flex-1 h-full overflow-y-auto scrollbar scrollbar-h-1 scrollbar-track-gray-200 scrollbar-thumb-gray-500 scrollbar-thumb-rounded-full p-4">
      {children}
    </div>
  );
}

export default MiddleArea1_3;
