import * as React from "react";

const PartialIcon = (props) => (
  <svg fill={props?.fill} width="20" height="20" viewBox="0 0 1920 1920">
    <g fill-rule="evenodd">
      <path
        fill="red"
        d="M960 0c529.355 0 960 430.645 960 960s-430.645 960-960 960S0 1489.355 0 960 430.645 0 960 0Zm0 112.941c-467.125 0-847.059 379.934-847.059 847.059 0 467.125 379.934 847.059 847.059 847.059 467.125 0 847.059-379.934 847.059-847.059 0-467.125-379.934-847.059-847.059-847.059Z"
      />
      <path fill="red" d="M451.765 1016.47h1016.47V903.53H451.765z" />
    </g>
  </svg>
);

export default PartialIcon;
