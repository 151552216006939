import styled from "styled-components";
import QuickNotificationList1 from "./QuickNotificationList1";
import ChartWrapperControl1 from "./ChartWrapperControl1";
import QuickNotificationList1_2 from "./QuickNotificationList1_2";
import QuickNotificationList2 from "./QuickNotificationList2";

const DashboardCard = styled.div`
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25), 0px 0px 0px rgba(0, 0, 0, 0.25);
  padding: 2px;
  border-radius: 4px 0px 0px 4px;
  border-radius: 8px;
  margin-bottom: 1em;
  zoom: 0.8;
  /* border: 1px solid #939393; */
`;

export const QuickNotificationListm1 = (props) => {
  console.log("QuickNotificationList2", props);
  return (
    <DashboardCard className="dashboard-card">
      <QuickNotificationList1_2 {...props} />
    </DashboardCard>
  );
};

export const QuickNotificationListm2 = (props) => {
  console.log("QuickNotificationList2", props);
  return (
    <DashboardCard className="dashboard-card">
      <QuickNotificationList1_2 {...props} />
    </DashboardCard>
  );
};

export const QuickNotificationListm3 = (props) => {
  console.log("QuickNotificationList2", props);
  return (
    <DashboardCard className="dashboard-card">
      <QuickNotificationList1_2 {...props} />
    </DashboardCard>
  );
};

export const QuickNotificationListm11 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};

export const QuickNotificationListm21 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};

export const QuickNotificationListm31 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};

export const QuickNotificationListm41 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};

export const QuickNotificationListm12 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};
export const QuickNotificationListm22 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};
export const QuickNotificationListm32 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};
export const QuickNotificationListm42 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};

export const QuickNotificationListm13 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};
export const QuickNotificationListm23 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};
export const QuickNotificationListm33 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};
export const QuickNotificationListm43 = (props) => {
  return (
    <div className="dashboard-card" style={{ zoom: 0.8 }}>
      <QuickNotificationList2 {...props} />
    </div>
  );
};

export const ChartWrapperControlm11 = (props) => {
  console.log("chartWrapperControlm11", props);
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm12 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm13 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm21 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm22 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm23 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm31 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm32 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm33 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};

export const ChartWrapperControlm41 = (props) => {
  return (
    <DashboardCard className="dashboard-card">
      <ChartWrapperControl1 {...props} />
    </DashboardCard>
  );
};
