import * as React from "react";

const ThirdPartyIcon = (props) => (
  <svg
    width="40"
    height="27"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 115.783 115.783"
    fill={props?.fill}
  >
    <g>
      <g>
        <path
          fill={props?.fill}
          d="M49.03,52.614c0.086,0.05,0.172,0.1,0.258,0.15c-0.064-0.072-0.118-0.147-0.154-0.225
			C49.08,52.539,49.052,52.572,49.03,52.614z"
        />
        <path
          fill={props?.fill}
          d="M43.605,27.172c-0.011,0.018-0.021,0.036-0.036,0.054c0.047-0.068,0.107-0.125,0.157-0.19
			C43.691,27.087,43.641,27.119,43.605,27.172z"
        />
        <path
          fill={props?.fill}
          d="M43.726,27.033c0.687-0.931,1.464-1.793,2.316-2.577C45.19,25.24,44.414,26.102,43.726,27.033z"
        />
        <path
          fill={props?.fill}
          d="M46.156,24.338c-0.043,0.039-0.072,0.086-0.115,0.125c0.175-0.157,0.369-0.297,0.548-0.451
			C46.436,24.109,46.285,24.216,46.156,24.338z"
        />
        <path
          fill={props?.fill}
          d="M46.793,23.794c-0.039,0.032-0.079,0.061-0.115,0.093c0.025-0.004,0.05-0.007,0.079-0.011
			c0.018-0.014,0.036-0.032,0.057-0.047C46.808,23.815,46.822,23.794,46.793,23.794z"
        />
        <path
          fill={props?.fill}
          d="M46.844,23.751c0.021,0,0.032,0,0.057,0c0.036-0.029,0.072-0.061,0.107-0.086
			C46.955,23.683,46.908,23.705,46.844,23.751z"
        />
        <path
          fill={props?.fill}
          d="M40.581,34.262c0.007,0,0.011,0.014,0.018,0.018c0.011-0.057,0.007-0.118,0.018-0.172
			C40.609,34.165,40.591,34.208,40.581,34.262z"
        />
        <path
          fill={props?.fill}
          d="M40.806,33.342c0.183-0.766,0.415-1.51,0.694-2.237C41.214,31.832,40.985,32.58,40.806,33.342z"
        />
        <path
          fill={props?.fill}
          d="M40.616,34.108c0.05-0.258,0.125-0.512,0.186-0.769C40.742,33.596,40.67,33.851,40.616,34.108z"
        />
        <path
          fill={props?.fill}
          d="M41.983,29.978c0.286-0.612,0.608-1.199,0.959-1.768C42.588,28.783,42.266,29.37,41.983,29.978z"
        />
        <path
          fill={props?.fill}
          d="M42.943,28.21c0.204-0.329,0.401-0.666,0.626-0.981C43.343,27.545,43.147,27.877,42.943,28.21z"
        />
        <path
          fill={props?.fill}
          d="M41.497,31.106c0.15-0.379,0.308-0.755,0.483-1.127C41.808,30.35,41.647,30.723,41.497,31.106z"
        />
        <path
          fill={props?.fill}
          d="M47.044,23.594c0.011,0,0.032,0.004,0.047,0c0.115-0.089,0.222-0.193,0.34-0.283
			C47.295,23.386,47.094,23.554,47.044,23.594z"
        />
        <path
          fill={props?.fill}
          d="M74.719,32.304c0.018,0.064,0.039,0.132,0.057,0.197c-0.014-0.05-0.018-0.104-0.036-0.154
			C74.723,32.333,74.73,32.315,74.719,32.304z"
        />
        <path
          d="M73.492,34.896c-0.24-0.483-0.369-0.816-0.698-1.263c-0.243-0.336-0.598-0.594-0.691-1.024
			c-0.161-0.705-1.321-1.131-1.353-1.908c-0.004-0.115-0.297-0.662-0.268-0.684c0.075-0.057,0.05-0.347,0.057-0.433
			c0.018-0.293,0.082-0.519,0.004-0.812c-0.633,0.168-1.152-0.089-1.793-0.15c0.021-0.007,0.047-0.025,0.054-0.039
			c-0.19-0.161-0.311-0.293-0.533-0.408c0.24-0.251,0-0.147-0.036-0.304c-0.05-0.222,0.53-0.122,0.637-0.161
			c-0.014-0.021-0.082-0.061-0.079-0.093c-0.233-0.036-0.197,0.204-0.451,0.021c-0.197-0.14-0.222-0.057-0.404-0.021
			c0.029,0.057,0.036,0.097,0.029,0.15c0,0-0.308-0.218-0.279-0.057c0.19,0.183,0.293,0.361,0.508,0.54
			c-0.129,0.068-0.247-0.004-0.354-0.1c0.007,0.047,0.072,0.329,0.111,0.351c-0.251-0.029-0.412-0.15-0.301-0.372
			c-0.2-0.021-0.619-0.437-0.662-0.644c-0.068-0.358-0.558-0.408-0.598-0.644c-0.089-0.007-1.113-0.716-1.084-0.508
			c0.05,0.326,0.58,0.777,0.916,0.809c0.05,0.268,0.558,0.204,0.565,0.501c-0.483-0.129-0.293-0.172-0.236,0.179
			c-0.004-0.039-0.279,0.379-0.315,0.165c0.512,0.05-1.117-1.224-1.052-1.074c-0.361-0.372-0.422-0.512-0.895-0.272
			c-0.233,0.118-1.002-0.293-0.802,0.222c-0.014,0.039-1.077,0.494-0.616,0.698c-0.14,0.197-0.358,0.358-0.551,0.437
			c-0.236-0.089-0.673,0.24-0.812,0.025c-0.175-0.276-0.279-0.172-0.565-0.19c-0.286-0.021-0.036-0.984-0.036-1.113
			c0-0.183-0.15-0.426,0.222-0.397c0.319,0.025,0.63,0.107,0.952,0.132c0.358-0.047,0.497,0.082,0.472-0.412
			c-0.007,0.004-0.011,0.007-0.014,0.007c0.215-0.358-0.766-0.462-0.766-0.648c0-0.132,0.573-0.011,0.644-0.025
			c0-0.068-0.043-0.115-0.047-0.193c0.455,0.039,0.523-0.132,0.913-0.218c0.329-0.068,0.279-0.415,0.673-0.383
			c0.118,0.011,0.533,0.029,0.433-0.147c-0.229-0.394-0.225-0.394,0.193-0.558c0.018,0.122,0.079,0.165,0.107,0.218
			c-0.014-0.004-0.043,0.072-0.064,0.154c0.075,0.011,0.132,0.043,0.115,0.147c-0.011-0.072-0.086-0.043-0.136-0.061
			c-0.018,0.093-0.029,0.168-0.011,0.115c0.215,0.021,0.469,0.004,0.68,0.043c-0.039,0.247,0.777-0.036,0.97,0.036
			c-0.007,0.018-0.018,0.043-0.018,0.05c0.075,0.004,0.161,0.007,0.236,0.011c-0.061-0.437,0.734-0.608,0.734-0.494
			c0-0.104-0.354-0.233-0.251-0.283c0.301-0.157,0.655,0.075,0.973-0.043c-0.122-0.132-1.657-0.093-1.428-0.376
			c-0.129-0.036-0.111-0.129-0.175-0.208c0.054,0.007,0.684-0.222,0.673-0.251c-0.125-0.304-0.623-0.036-0.68,0.047
			c0.043,0.143-0.659,0.079-0.623,0.297c0.036,0.225,0.315,0.161,0.372,0.369c0.011,0.036-0.684,0.322-0.243,0.401
			c0.004,0.172-0.523,0.297-0.637,0.29c-0.1-0.011-0.311-0.422-0.365-0.497c-0.132-0.197-0.193-0.34-0.419-0.204
			c-0.075,0.047-0.727,0.215-0.748-0.021c-0.007-0.068,0.111,0.004,0.097-0.104c-0.089-0.007-0.089-0.007-0.168,0.004
			c0.021-0.036,0.057-0.064,0.097-0.086c-0.462-0.319,0.419-0.487,0.619-0.465c-0.075-0.014-0.097-0.021-0.15-0.032
			c0.129-0.007,1.152-0.422,1.188-0.53c-0.143,0.004-0.197,0.029-0.336,0.021c0.029-0.011,0.086-0.021,0.111-0.025
			c-0.032-0.004-0.089-0.011-0.089-0.011c0.014-0.061,0.472-0.018,0.483-0.021c-0.043-0.007-0.043-0.007-0.089-0.007
			c0.075-0.025,0.154-0.036,0.229-0.025c-0.036-0.011-0.025-0.011-0.057-0.018c0.215-0.072,0.476,0,0.694-0.032
			c-0.161-0.029,0.394-0.129,0.347,0.032c0.222,0.021,0.462,0.104,0.701,0.218c-2.727-1.732-5.959-2.741-9.427-2.741
			c-3.887,0-7.448,1.299-10.361,3.432c0.004,0.018,0,0.032-0.004,0.043c0.036,0,0.054,0.018,0.039,0.075
			c0.222-0.057,0.576,0.075,0.54,0.075c0.208-0.011,0.419-0.344,0.705-0.243c-0.018-0.004-0.254,0.143-0.276,0.19
			c-0.104,0.222,0.018,0.086,0.172,0.208c-0.075,0.021-0.222,0.007-0.286,0.054c0.011-0.007,0.039,0.047,0.039,0.047
			c-0.204,0.093-1.399,0.132-1.457,0.079c-0.011-0.007-0.061-0.004-0.104,0c-0.029,0.021-0.057,0.047-0.086,0.072
			c0.004,0.011,0.025,0.007,0.021,0.036c-0.018,0.004-0.05,0.007-0.075,0.011c-0.057,0.047-0.115,0.089-0.168,0.14
			c0.215-0.132,0.451-0.222,0.727-0.122c0.082,0.029,0.329,0.007,0.462-0.107c-0.004,0-0.011,0-0.014,0
			c-0.075,0.011-0.311,0.05-0.279,0.025c0.032-0.025,0.14-0.029,0.279-0.025c0.014,0,0.025,0,0.025,0s-0.004,0-0.007,0
			c0.369,0.011,0.963,0.082,1.177,0.029c-0.036,0.021-0.054,0.039,0.043,0.021c0.15-0.118,0.394-0.082,0.458-0.179
			c-0.05,0.011-0.082,0.014-0.129,0.025c0.047-0.021,0.082-0.043,0.129-0.072c0.047-0.036-0.211-0.057-0.168-0.089
			c0.165-0.122,0.752-0.132,0.616-0.029c-0.064,0.018-0.122,0.039-0.186,0.057c0.011,0.032,0.143,0.004,0.19,0.025
			c-0.193,0.054,0.043,0.011-0.086,0.115c-0.025,0,0.487-0.122,0.387-0.036c-0.122-0.004-0.175,0.036-0.15,0.132
			c0.147,0.136,0.569-0.243,0.676-0.276c-0.004,0-0.873-0.011-0.766-0.097c0.125,0.05,0.229,0.039,0.315-0.032
			c-0.032-0.011-0.297-0.018-0.297-0.018c-0.061-0.118,0.322-0.157,0.387-0.197c-0.1,0.036-0.29,0.018-0.397,0.072
			c0.018,0,0.039,0,0.057,0c-0.089,0.054-0.183,0.075-0.286,0.075c0.311-0.218,0.465-0.215,0.891-0.233
			c-0.064,0.036-0.132,0.047-0.208,0.072c0.204-0.039,0.387-0.039,0.583-0.029c-0.075,0.014-0.415,0.104-0.308,0.104
			c-0.222,0.1,0.727-0.222,0.787-0.061c0.004,0.011-0.043,0.093-0.064,0.093c0.408,0.004,0.744,0.125,1.124,0.143
			c-0.004,0.007-0.004,0.018-0.011,0.029c0.047-0.011,0.086,0,0.122,0.036c-0.047,0.007-0.086,0.004-0.118,0.029
			c0.047-0.007,0.089-0.004,0.132,0.014c-0.054,0.014-0.075,0.014-0.118,0.047c0.032,0.011,0.501,0.172,0.497,0.183
			c-0.032,0.218-0.379,0.118-0.548,0.093c-0.021,0.043-0.054,0.004-0.075,0.068c0.093-0.007,0.129,0.115,0.258,0.097
			c-0.097,0.319-0.082,0.136-0.451,0.197c-0.004-0.004,0.404,0.193,0.19,0.24c-0.272,0.057-0.594-0.136-0.759-0.218
			c-0.068-0.036-0.787,0.047-0.759,0.007c0.154-0.211,0.573-0.143,0.795-0.24c-0.172-0.057,0.455-0.172,0.243-0.326
			c-0.004,0.14-0.34,0.043-0.336,0.039c0.086-0.057,0.183-0.079,0.283-0.072c-0.054-0.025-0.301-0.036-0.308-0.029
			c0.043-0.011,0.082-0.021,0.129-0.032c-0.029-0.029-0.064-0.043-0.097-0.047c0.004,0,0.011-0.004,0.014-0.004
			c-0.107-0.057-0.505,0.047-0.422-0.018c-0.061,0-0.165,0-0.286,0.004c0.086,0.011,0.183,0.05,0.265,0.047
			c-0.161,0.14-0.483,0.297-0.823,0.394c0.072-0.014,0.039,0.014,0.05,0.036c-0.061,0-0.079-0.018-0.057-0.036
			c-0.057,0.014-0.115,0.029-0.172,0.043c0,0.018,0.004,0.029,0,0.047c0.057-0.021,0.293,0.007,0.319,0.072
			c0.004,0.018-0.032,0.039-0.018,0.047c0.05,0.032,0.157,0.014,0.172,0.047c-0.243,0.072-0.648,0.111-0.891,0.122
			c-0.061,0-0.014-0.025,0.025-0.057c-0.075-0.007-0.175,0.007-0.218,0.011c0.079-0.072,0.301-0.193,0.469-0.261
			c-0.186,0.025-0.365,0.025-0.505-0.018c-0.018,0.075,0.165,0.057,0.308,0.072c-0.204,0.175-0.723,0.233-1.034,0.208
			c0.025,0.007,0.054,0.025,0.075,0.043c-0.272,0.243-0.92,0.444-1.26,0.669c0.261-0.043,0.236,0.061,0.064,0.236
			c0.623-0.025,0.558,0.34,1.063,0.322c0.168,0.118-0.079,0.272-0.097,0.365c0.064-0.014,0.165-0.011,0.122,0.05
			c0.05,0.014,0.068,0.036,0.2,0.032c0.243-0.272-0.036-0.372,0.44-0.565c0.594-0.236,0.161-0.236,0.15-0.548
			c0.644-0.143,0.383-0.712,1.238-0.301c0.247,0.118,0.988,0.333,1.17,0c-0.115,0.197-0.039,0.222,0.061,0.251
			c-0.039-0.021-0.075-0.043-0.1-0.079c0.218-0.297,0.694,0.336,0.315,0.458c0.061,0.039,0.111,0.093,0.258,0.093l-0.004,0.011
			c-0.125,0.208,0.501,0.15,0.447,0.218c-0.089,0.082-0.497,0.118-0.666,0.168c0.014,0.089,0.737-0.097,0.802-0.118
			c0.222,0.744-1.997,0.669-2.452,1.034c0.057-0.004,1.167-0.261,1.081-0.1c-0.075,0.136-0.286,0.1-0.401,0.172
			c0.068,0,0.132,0.018,0.172,0.018c-0.397,0.49,0.494,0.433,0.451,0.519c-0.072,0.147-0.837,0.265-1.009,0.354
			c-0.283,0.15,0.014-0.329,0.394-0.347c-0.1,0.011-0.122-0.014-0.064-0.093c-0.286,0.011-0.755,0.336-0.984,0.286
			c-0.24-0.054-0.963,0.548-0.565,0.54c-0.243,0.089-0.608,0.072-0.877,0.19c0.054,0,0.208-0.039,0.243,0.007
			c-0.15,0.039-0.82,0.279-0.777,0.376c-0.122,0.072-0.161,0.462-0.401,0.455c0.014,0.004,0.093-0.336,0.104-0.361
			c-0.05,0.05-0.254,0.397-0.236,0.455c0.014,0,0.036,0.004,0.05,0.004c-0.032,0.082-0.093,0.14-0.186,0.161
			c-0.004,0.054,0.029,0.064,0.061,0.072c-0.072,0.093-0.258,0.054-0.132,0.193c-0.161,0.125-0.369,0.15-0.533,0.265
			c0.004,0.004,0.004,0.007,0.011,0.014c-0.673,0.293-1.278,0.58-1.367,1.389c-0.021,0.204-0.261,1.109-0.565,1.066
			c-0.272-0.036,0.243-1.342,0.211-1.449c-0.004-0.014-0.759-0.136-0.719-0.208c-0.115,0.025-0.229,0-0.361,0.043
			c0.043,0.029,0.032,0.029,0.082,0.004c-0.079,0.129-0.179,0.2-0.143,0.272c-0.222,0.097-0.426-0.351-0.727-0.19
			c-0.447,0.24-0.769,0.447-1.031,0.902c-0.004,0-0.011,0-0.011-0.004c-0.068,0.157-1.385,2.344-0.773,2.473
			c0.827,0.183,0.866-0.988,1.764-0.82c-0.111,0.233-0.311,0.404-0.422,0.637c-0.075,0.154-0.143,0.229-0.215,0.319
			c-0.1,0.175-1.016,0.92-0.222,0.97c0.959,0.061,0.247,0.97,0.057,1.467c-0.168,0.433-0.523,1.875,0.304,1.521
			c0.394-0.172,0.404,0.429,0.465,0.651c-0.025,0.039-0.319,0.311-0.319,0.304c-0.018-0.129,0.132-0.594-0.054-0.662
			c-0.369-0.129-0.401,0.741-0.576,0.666c-0.358-0.147-0.183-1.5-0.447-1.589c-0.029,0.036-0.05,0.093-0.05,0.129
			c-0.021-0.007-0.043-0.014-0.068-0.025c-0.068-0.268,0.168-0.555,0.15-0.841c-0.014-0.258-0.057-0.472,0.014-0.73
			c-0.233-0.075-0.458-0.236-0.58-0.54c-0.047-0.115,0.011-0.68-0.111-0.709c-0.082-0.329-0.233,0.093-0.379,0.054
			c-0.165-0.043-0.218-0.533-0.344-0.583c-0.183,1.016-0.315,2.054-0.315,3.124c0,6.413,3.429,12.021,8.553,15.099
			c-0.104-0.15-0.021-0.311-0.104-0.487c-0.111-0.236-0.297-0.276-0.301-0.608c0-0.655-0.047-1.056-0.308-1.704
			c-0.283-0.372-0.236-1.188-0.311-1.65c-0.157-0.44-0.222-0.891-0.404-1.324c-0.204-0.472-0.769-0.787-1.274-0.855
			c-0.648-0.115-0.752-0.673-1.149-1.102c-0.333-0.358-0.669-0.766-0.934-1.156c-0.136-0.186-0.401-0.2-0.476-0.505
			c-0.072-0.286,0.204-0.404,0.2-0.662c-0.387-0.208-0.272-0.261-0.261-0.684c0.007-0.361,0.497-0.752,0.494-1.117
			c-0.1-0.032-0.129-0.086-0.086-0.168c-0.075-0.347-0.526-0.934-0.075-1.12c-0.089-0.483,0.501-0.812,0.862-0.97
			c0.079-0.036,0.165-0.29,0.272-0.143c0.057,0.057,0.047,0.115-0.029,0.172c0,0.118-0.072,0.676,0.19,0.662
			c0.111-0.007-0.319-0.601,0.272-0.641c-0.054-0.107-0.172-0.057-0.193-0.165c0.211-0.007,0.465,0.175,0.587,0.315
			c0.093,0.104,0.472,0.054,0.601,0.097c0.154,0.057,0.429-0.089,0.659-0.14c0.089,0.397,0.956,0.497,1.195,0.859
			c0.193,0.283,1.02,0.358,1.331,0.347c0.361-0.025,0.977,0.862,0.809,1.088c-0.254,0.34,0.111,0.762,0.437,0.727
			c0.326-0.029,0.025-0.616,0.662-0.351c0.236,0.1,0.326,0.089,0.39,0.369c0.297-0.029,0.673-0.14,1.02-0.043
			c0.344,0.097,0.551,0.315,0.916,0.39c0.78,0.168,0.036,1.603-0.15,1.95c-0.14,0.261,0.265,1.152,0.208,1.546
			c-0.172,0.669-0.272,0.909-0.855,1.278c-0.161,0.1-0.462,0.422-0.422,0.644c0.057,0.193,0.115,0.383,0.179,0.569
			c-0.111,0.129-0.161,0.276-0.157,0.444v-0.007c-0.061,0.433-0.218,0.812-0.301,1.249c-0.054,0.293-0.956,0.429-0.991,0.333
			c0.172,0.447,0.805,0.258,0.508,0.934c-0.107,0.24-0.472,0.29-0.691,0.344c-0.258,0.061,0.011,0.358,0.007,0.497
			c0.118,0.279-0.297,0.143-0.465,0.193c-0.315,0.082,0.476,0.447,0.426,0.336c0.079,0.175-0.19,0.082-0.243,0.097
			c0.075,0.165,0.319,0.573,0.011,0.719c-0.472,0.286,0.358,0.451,0.44,0.684c2.033,0.82,4.252,1.274,6.582,1.274
			c6.66,0,12.451-3.697,15.443-9.151c0.032-0.186,0.097-0.29,0.19-0.365c1.26-2.423,1.972-5.171,1.972-8.092
			c0-0.87-0.079-1.714-0.208-2.548C74.558,35.067,73.957,35.794,73.492,34.896z M66.864,23.869c0,0.064-0.039,0.136-0.122,0.129
			c-0.061-0.004-0.075-0.043-0.079-0.068c0.054,0,0.079-0.018,0.136-0.018C66.649,23.801,66.821,23.851,66.864,23.869z
			 M66.155,23.966c-0.036,0.047-0.075,0.093-0.111,0.143C65.955,24.037,66.116,23.966,66.155,23.966z M65.064,24.284
			c-0.111,0.011-0.111,0.157-0.229,0.125c0.047,0,0.086-0.025,0.082-0.054C64.613,24.302,64.906,24.027,65.064,24.284z
			 M63.331,22.151C63.331,22.151,63.331,22.155,63.331,22.151h0.007v0.004c0.039,0.018,0.082,0.029,0.107,0.057
			C63.378,22.201,63.278,22.162,63.331,22.151z M63.625,22.094c-0.014,0.004-0.032,0.007-0.043,0.007
			c0.043,0.011,0.075,0.014,0.104,0.021c-0.075-0.014,0.183,0.021,0.218,0.029c-0.175-0.147,0.179-0.05,0.179-0.05
			c0,0.011-0.007,0.018-0.007,0.029c0.075-0.011,0.186,0,0.286,0.021c-0.014-0.007-0.032-0.021-0.039-0.032c0.068,0,0.129,0,0.2,0
			c-0.021,0-0.204-0.011-0.211-0.011c-0.036,0-0.19,0.007-0.218-0.047c0.05,0,0.097,0,0.147,0.004v0c0.021,0,0.047,0,0.072,0
			c-0.007-0.007-0.018-0.011-0.025-0.014c0.293,0.004,0.748-0.061,1.02,0.075c-0.097,0.018-0.551,0.089-0.809,0.064
			c0.036,0.014,0.075,0.025,0.107,0.039c-0.014,0-0.032,0-0.047,0c0.132,0.018,0.304,0.036,0.444,0.107
			c-0.129,0.007-0.179,0.036-0.297,0.043c-0.014-0.014-0.014-0.025,0-0.032c-0.064-0.004-0.154-0.007-0.218-0.014
			c0-0.032,0.057-0.018,0.064-0.043c-0.029-0.004-0.072-0.025-0.089-0.043c-0.19,0.043-0.759,0.243-0.777,0.036
			c0.097,0,0.179,0.007,0.272,0.004c-0.036-0.007-0.322,0-0.329-0.043c0,0.004,0.161-0.021,0.254-0.036
			c-0.107,0.004-0.322,0.025-0.372-0.014c0.011,0,0.014,0,0.029,0c-0.068-0.011-0.118-0.029-0.104-0.047
			c-0.039-0.007-0.079-0.018-0.115-0.039C63.421,22.08,63.521,22.073,63.625,22.094z M49.331,24.528
			c0.011-0.014,0.025-0.029,0.039-0.043c0.107-0.047,0.211-0.104,0.326-0.075C49.61,24.452,49.42,24.517,49.331,24.528z
			 M46.239,33.704c-0.014,0.111-0.079,0.161-0.204,0.157C45.917,33.822,46.214,33.729,46.239,33.704z M45.863,31.778
			c0.05,0.004,0.05,0.057,0.05,0.086c-0.079,0.004-0.147-0.014-0.186-0.018C45.759,31.8,45.827,31.771,45.863,31.778z
			 M45.591,32.351c0.025-0.043,0.057,0.129,0.039,0.161C45.544,32.695,45.58,32.38,45.591,32.351z M43.963,33.586
			c0.018-0.064,0.057-0.104,0.118-0.115c0.029-0.068,0.025-0.129,0.072-0.075C44.023,33.768,43.998,33.511,43.963,33.586z
			 M44.635,35.049c0.089,0.021,0.168,0.118,0.204,0.157c0-0.011,0-0.021-0.004-0.032c0.039,0.036,0.039,0.079,0.025,0.132
			C44.349,35.321,44.575,35.039,44.635,35.049z M44.864,34.341c0.15-0.358,0.039-0.931-0.497-0.995
			c-0.143-0.472-0.19,0.122-0.616,0.068c0.437-0.555,0.762-0.644,1.242-0.175c0.15,0.15,0.505,0.655,0.483,0.784
			c0.236,0.072,0.064,0.208,0.175,0.361C45.398,34.72,45.136,34.387,44.864,34.341z M46.317,35.354
			c-0.018-0.172-0.279,0.229-0.336,0.215c-0.004,0-0.383-0.691-0.548-0.243c-0.419-0.243,0.401-0.315,0.447-0.29
			c0.004-0.079,0.018-0.866,0.218-0.562c0.089,0.147,0.229,0.032,0.361,0.054c0.268,0.043,0.236,0.311,0.333,0.494
			c0.032,0.115,0.1,0.361,0.1,0.347C46.869,35.357,46.317,35.357,46.317,35.354z M47.169,35.59c0.061-0.186,0.361,0.068,0.365,0.157
			C47.441,35.88,47.108,35.783,47.169,35.59z M70.468,29.041c0,0.104-0.354,0.319-0.404,0.1
			C70.167,29.105,70.342,29.048,70.468,29.041z M68.7,28.98c-0.118,0.057-0.387-0.039-0.494-0.097
			C68.37,28.872,68.556,28.93,68.7,28.98z M66.18,28.031c-0.014,0.068-0.021,0.322-0.029,0.322
			c-0.104-0.011-0.558-0.265-0.573-0.351C65.661,27.971,66.083,28.003,66.18,28.031z M64.774,26.811
			c0.004,0.029,0.018,0.064,0.039,0.075c-0.014,0.075-0.014,0.218-0.039,0.254c-0.075-0.021-0.122-0.215-0.165-0.268
			C64.673,26.872,64.724,26.854,64.774,26.811z M64.691,27.405c0-0.057-0.075-0.093-0.086-0.15
			C65.182,27.172,64.77,28.031,64.691,27.405z M63.553,27.459c0.007,0.089-0.157-0.036-0.165-0.043
			C63.478,27.344,63.528,27.359,63.553,27.459z M62.014,23.586c-0.05,0.036-0.107,0.064-0.168,0.086
			c0.075,0.136,0.211,0.154,0.419,0.061c0.021,0.111-0.211,0.204-0.233,0.268c0.268,0.072,0.68,0.347,0.701,0.619
			c0.047-0.007,0.054-0.021,0.079-0.029c0.154-0.075,0.154,0.1-0.007,0.293c0.047,0.007,0.093,0.018,0.147,0.029
			c0.004,0.057-1.202,0.165-1.342,0.154c0.079-0.143,0.476-0.208,0.476-0.286c-0.054,0.05-0.433-0.018-0.433-0.043
			c0-0.089,0.14-0.111,0.14-0.279c0.115,0.007,0.311,0.011,0.311-0.136c-0.111,0-0.197-0.018-0.118-0.118
			c-0.043-0.004-0.333,0.029-0.329-0.043c0-0.157,0.19-0.082-0.047-0.197c-0.039,0.029-0.014,0.043,0,0.064
			c-0.018,0-0.039-0.004-0.054-0.004c0-0.161-0.029-0.129-0.021-0.258c-0.097-0.018-0.154-0.036-0.154-0.086
			c0.014,0,0.183,0.047,0.183,0.025C61.442,23.658,62.021,23.357,62.014,23.586z M61.707,24.32c0-0.021,0-0.043,0.05-0.054
			c0.004,0,0.025,0,0.032,0c0,0.011-0.007,0.032-0.007,0.057C61.767,24.324,61.749,24.324,61.707,24.32z M61.456,23.558
			c-0.004,0.043-0.064,0.086-0.132,0.082C61.184,23.626,61.438,23.558,61.456,23.558z M60.737,24.266
			c0.007-0.079,0.229-0.054,0.286-0.082c-0.129-0.143,0.222-0.129,0.29-0.122c0.401,0.029,0.1,0.211,0.1,0.258
			c-0.007,0.326-0.225,0.433-0.587,0.404c-0.376-0.025,0.014-0.258,0.047-0.354C60.826,24.338,60.787,24.306,60.737,24.266z
			 M50.616,22.541c-0.014,0.007-0.032,0.018-0.047,0.025c0.068,0-0.154,0.011-0.093-0.021C50.53,22.541,50.587,22.541,50.616,22.541
			z M50.455,22.699c-0.111-0.086,0.329-0.118,0.225,0C50.58,22.706,50.243,22.724,50.455,22.699z M50.841,22.72
			c-0.036,0.025-0.075,0.036-0.122,0.036c-0.021,0-0.24-0.007-0.24-0.007C50.598,22.72,50.719,22.731,50.841,22.72z M49.617,22.674
			c0.014-0.004,0.036-0.007,0.054-0.014c-0.057,0-0.064-0.004-0.032-0.018c0.118-0.011,0.469-0.05,0.558,0.036
			c-0.018,0.004-0.043,0.011-0.057,0.014c0.039,0.036-0.097,0.057-0.247,0.061c-0.036,0.007-0.079,0.011-0.132,0.011
			c0.025,0,0.061-0.007,0.093-0.011c-0.129,0-0.258-0.014-0.283-0.039c0.068,0.007,0.129-0.007,0.183-0.039
			C49.667,22.67,49.632,22.681,49.617,22.674z M49.467,22.813c-0.025,0.011-0.064,0.021-0.089,0.021
			c-0.047,0-0.068-0.036-0.032-0.057C49.449,22.774,49.402,22.806,49.467,22.813z M48.748,22.763
			c-0.054-0.011-0.111-0.007-0.165,0.011c0.068-0.036,0.276-0.021,0.394-0.025c-0.021,0-0.039,0.007-0.061,0.007
			c0.011,0.004,0.018,0.011,0.021,0.025c-0.236,0.025-0.265,0.029-0.089,0.021c-0.054,0.032-0.208,0.054-0.293,0.057
			C48.454,22.856,48.608,22.792,48.748,22.763z M48.329,22.903c-0.036,0.021-0.079,0.036-0.125,0.029
			c-0.018,0-0.036-0.007-0.011-0.021C48.239,22.91,48.282,22.906,48.329,22.903z M48.146,22.853
			c0.057,0.018,0.032,0.032-0.068,0.039c-0.039,0.057-0.097,0.079-0.179,0.072C47.964,22.881,48.046,22.846,48.146,22.853z
			 M48.014,23.078c0-0.004,0-0.004,0-0.011c-0.029,0-0.064,0.014-0.079,0.025c-0.082,0.018-0.165,0.021-0.247,0.018
			c0.039-0.061,0.089-0.082,0.15-0.079c-0.011,0-0.021-0.004-0.029-0.004c0.039-0.032,0.075-0.032,0.125-0.029
			c-0.007,0-0.018-0.004-0.025-0.007c0.043-0.014,0.079-0.021,0.125-0.021c-0.014-0.004-0.018-0.007,0-0.018
			c0.2-0.021,0.408,0.122,0.623,0.036c-0.043,0.004-0.061,0-0.072,0c0.036,0,0.064-0.011,0.086-0.032
			c-0.172-0.004,0.2-0.075,0.236-0.075c-0.197,0.097,0.211,0.068,0.175,0.089C48.79,23.146,48.329,23.007,48.014,23.078z
			 M49.206,22.992c-0.014,0.043-0.057,0.061-0.115,0.054c-0.039,0-0.039,0-0.075,0C49.08,23.01,49.138,22.999,49.206,22.992z
			 M48.862,23.651c-0.111,0.021,0.444-0.175,0.351,0.025C49.13,23.69,48.572,23.69,48.862,23.651z M49.485,23.225
			c0.061-0.004,0.218-0.054,0.1,0.025c0.018,0.004,0.068,0.004,0.089,0.007c-0.161,0.097-0.573,0.236-0.727,0.025
			c0.115-0.075,0.222,0.007,0.347-0.075c-0.326-0.007,0.401-0.043,0.433-0.061C49.649,23.182,49.571,23.214,49.485,23.225z
			 M49.556,23.01c0.057-0.039,0.175-0.05,0.229-0.068c-0.054-0.018-0.129-0.014-0.208-0.011h0.004c0,0-0.004,0-0.011,0
			c-0.086,0.007-0.179,0.021-0.236,0.025c0.004-0.007,0.007-0.011,0.014-0.018c-0.05,0.004-0.061-0.004-0.075-0.011l0.054-0.032
			c0,0,0,0,0,0.004c0-0.054,0.193,0.018,0.247,0.032h0.007c-0.014-0.007-0.025-0.014-0.029-0.021
			c0.086-0.05,0.165,0.029,0.261-0.029c-0.072,0-0.129,0.004-0.107-0.018c0.032-0.004,0.394,0,0.394,0
			C50.1,23.093,49.649,22.949,49.556,23.01z M50.043,22.996c0.025-0.014,0.086-0.025,0.097-0.025
			c0.089-0.05,0.183-0.057,0.279-0.029C50.551,23.01,50.047,22.996,50.043,22.996z M50.988,22.985
			c-0.079,0.004-0.612-0.007-0.329-0.057c-0.018-0.089-0.072-0.107-0.154-0.057c-0.075,0-0.147-0.014-0.218-0.043
			c0.068-0.043,0.741-0.032,0.762,0.032c-0.057,0-0.115,0.004-0.175,0.004c-0.039,0.043,0.107,0.018,0.14,0.029
			c-0.089,0.025,1.217-0.089,1.231-0.004c-0.029,0-0.061,0.004-0.086,0.018c0.029,0.004,0.057,0,0.057,0
			C51.893,23.118,51.278,22.821,50.988,22.985z M51.983,23.146c-0.011,0-0.179-0.039-0.14-0.072
			c0.136-0.104,0.458-0.007,0.598,0.018C52.33,23.146,52.097,23.143,51.983,23.146z M52.724,22.674
			c-0.279,0.272-0.934,0.032-1.353,0.075c0.082-0.021,0.168-0.036,0.258-0.043c-0.229-0.1,0.293-0.039,0.361-0.047
			c-0.111-0.086-0.304-0.075-0.419-0.043c0,0.011,0.014,0.014,0.007,0.025c-0.093,0-0.365-0.018-0.462,0.007
			c-0.014,0-0.025,0.004-0.043,0.007c0.004-0.004,0.011-0.004,0.018-0.004c-0.029,0-0.054-0.011-0.072-0.036
			c0.122-0.025,0.243-0.039,0.369-0.039c-0.054-0.004-0.236-0.021-0.351-0.018c0.011,0,0.021,0,0.032-0.007
			c-0.14,0.004-0.218,0.004-0.161-0.036c0.072,0,0.15,0,0.218,0c-0.047,0.004-0.089,0-0.129-0.025
			c0.047-0.029,0.115-0.021,0.179-0.021c-0.007-0.004-0.021-0.007-0.029-0.018c0.079-0.011,0.161-0.014,0.243-0.018
			c-0.036-0.004-0.104-0.004-0.075-0.021c0.14,0,0.422,0.118,0.519,0.068c0.068,0,0.075,0.011,0.011,0.05
			c0.14-0.079,0.175,0.1,0.404-0.061c0.011-0.007-0.107-0.05-0.15-0.057c0.107,0,0.136-0.007,0.072-0.029
			c-0.115,0-0.204-0.011-0.222-0.039c0.043-0.007,0.086-0.011,0.129-0.018c-0.061,0.004-0.122,0.004-0.183,0.007
			c0.1-0.032,0.193-0.032,0.308-0.043c-0.107-0.007-0.211,0.036-0.319,0.014c0.061-0.011,0.093-0.014,0.15-0.025
			c-0.093,0.007-0.186,0.014-0.283,0.018c0.512-0.19,1.95-0.319,2.462-0.136c-0.265,0.068,0.437-0.036,0.404,0.025
			c-0.021,0.047-0.44,0.047-0.472,0.054c0.021,0.007,0.14-0.004,0.208-0.004c-0.369,0.107-0.769,0.093-1.138,0.19
			c0.036,0,0.068,0,0.1,0c-0.021,0.025-0.061,0.025-0.129,0.025c0.036,0.004,0.072,0.007,0.075,0.025
			c-0.172,0.032-0.258,0.075-0.429,0.086c-0.032,0.079-0.186,0.05-0.254,0.111C52.62,22.667,52.742,22.659,52.724,22.674z
			 M52.183,26.432c-0.14,0.1-0.333-0.043-0.44-0.122C51.986,26.278,52.069,26.371,52.183,26.432z M51.439,26.925
			c0.007-0.018,0.032-0.032,0.054-0.036c0.082,0.107,0.193,0.14,0.333,0.093C51.736,27.169,51.41,26.976,51.439,26.925z
			 M51.958,27.097c0.075-0.086,0.165-0.15,0.272-0.197c0,0.025,0.036,0.036,0.025,0.057c-0.032,0.064-0.154,0.089,0.018,0.089
			C52.223,27.158,51.922,27.172,51.958,27.097z M52.906,26.854c0.004,0-0.358,0.186-0.344,0.15c0.036-0.043,0.075-0.075,0.129-0.093
			c-0.089,0-0.895-0.061-0.891-0.068c0.039-0.079,0.161-0.054,0.2-0.136c-0.021,0-0.047-0.011-0.047-0.029
			c0.319-0.097,0.569-0.576,0.905-0.576c0.104,0-0.308,0.336-0.315,0.354c0.075-0.05,0.154-0.057,0.24-0.014
			c-0.039,0.007-0.064,0.036-0.072,0.075c0.047,0,0.089,0,0.14,0c0,0.004-0.014,0.025-0.014,0.025
			c0.036-0.004,0.072-0.011,0.104-0.021c0.079,0.018,0.179,0.018,0.225,0c-0.021,0.05-0.05,0.1-0.082,0.183
			c0.018-0.011,0.064-0.014,0.093-0.014c-0.064,0.118-0.05,0.057-0.093,0.175c0.079,0,0.061-0.007,0.118-0.032
			C53.046,27.244,53.06,27.015,52.906,26.854z M57.398,23.182c-0.072,0.215-0.333-0.132-0.376,0
			c0.064,0.036,0.422,0.093,0.379,0.236c-0.025,0.093-0.458-0.068-0.519-0.082c-0.018,0.072,0.05,0.072-0.075,0.079
			c-0.021,0.068,0.379,0.064,0.469,0.075c-0.372,0.129-0.791,0.2-1.188,0.19c-0.258,0.075-0.408,0.243-0.705,0.236
			c0.004-0.014,0.011-0.029,0.014-0.043c-0.276,0.05-0.598,0.605-0.744,0.594c-0.093-0.007-0.762-0.315-0.519-0.447
			c-0.054-0.061-0.247-0.082-0.132-0.24c-0.018-0.004-0.039-0.007-0.057,0c0.043-0.143,0.279-0.172,0.369-0.279
			c-0.082-0.018-0.286-0.025-0.279-0.079c0.079-0.018,0.193,0.082,0.247,0c-0.025-0.004-0.218-0.115-0.29-0.107
			c0.082-0.043,0.086-0.075,0.007-0.093c0.118-0.304-0.523-0.304-0.694-0.258c0.007,0.004,0.011,0.007,0.018,0.011
			c-0.057,0.014-0.111,0.007-0.165-0.025c0.025-0.007,0.039-0.011,0.054-0.021c-0.05-0.021-0.104-0.032-0.157-0.018
			c0.064-0.097,0.283,0,0.361-0.086c-0.036,0-0.426,0.029-0.401-0.025c0.075-0.161,0.48-0.004,0.576-0.183
			c-0.057,0.007-0.111,0.004-0.165-0.018c0.093-0.183,0.981-0.222,1.113-0.229c0.032-0.061,0.132-0.047,0.2-0.021
			c-0.004-0.007-0.014-0.014-0.007-0.025c0.043-0.068,0.394,0,0.472,0c-0.057,0-0.104-0.011-0.147-0.043
			c0.043-0.014,0.089-0.025,0.132-0.021c-0.014-0.007-0.039-0.007-0.047-0.014c0.88-0.204,1.857-0.15,2.759-0.132
			c-0.064,0.054-0.165,0.086-0.29,0.111c0.1-0.011,0.172-0.014,0.168-0.004c-0.007,0.007-0.361,0.122-0.383,0.14
			c0.1-0.011,1.804-0.222,1.789-0.193c-0.054,0.082-0.415,0.107-0.605,0.115c-0.05,0.093-0.741,0.243-0.866,0.311
			c0.107-0.014,0.215-0.007,0.319,0.021c-0.025,0.004-0.064,0.011-0.089,0.014c0.068,0.004,0.125,0.025,0.183,0.057
			c-0.05,0.118-0.412-0.047-0.458,0.072c-0.011,0.025,0.215,0.011,0.175,0.107c-0.032,0.089-0.15,0.004-0.179,0.072
			c0.057,0.036,0.115,0.047,0.179,0.039c-0.039,0.032-0.143,0.054-0.236,0.057c-0.004,0.014,0.014,0.025,0.036,0.032
			c-0.118,0.14-0.336,0.036-0.472,0.036C57.251,23.114,57.326,23.146,57.398,23.182z M57.619,23.572c0.072,0.004,0.2,0,0.261-0.007
			c0.007,0.007-0.2-0.089-0.193-0.118c0.068-0.007,0.179-0.043,0.251-0.036c-0.068,0.154,0.089,0.097,0.147,0.068
			c0.05,0.072,0.143,0.011,0.215,0.054c0.007-0.064,0.544-0.004,0.608,0c-0.143,0.036,0.068,0.143,0.029,0.204
			c-0.515,0.225-0.784,0.018-1.267-0.025c0.018-0.004,0.036-0.011,0.05-0.011c0.029-0.043,0.054-0.061,0.086-0.097
			C57.745,23.586,57.645,23.608,57.619,23.572z M72.078,41.087c-0.057,0.268-0.301,0.419-0.304,0.709
			c-0.004,0.297,0.072,0.587,0.064,0.88c0,0.308,0.419,0.637,0.293,0.916c-0.361,0.769,0.329,1.55-0.565,2.101
			c-0.308,0.19-0.712,0.369-0.784,0.773c-0.565,0.115-0.043,0.798-0.054,1.077c-0.021,0.648-0.15,0.512-0.555,0.845
			c-0.426,0.358-0.014,0.673-0.376,1.102c-0.336,0.394-0.519,0.873-0.82,1.292c-0.132,0.175-0.773,0.401-0.798,0.544
			c-0.344,0.036-0.633-0.05-0.97,0.129c-0.315,0.165-0.68-0.064-0.798,0.007c0.011-1.131-0.891-1.983-1.02-3.167
			c-0.061-0.544-0.075-0.752-0.326-1.249c-0.261-0.519-0.487-1.102-0.379-1.696c0.079-0.422,0.419-0.716,0.519-1.124
			c0.097-0.412-0.089-1.041-0.204-1.435c-0.111-0.426-0.147-0.952-0.419-1.303c-0.276-0.351-0.769-0.82-0.583-1.317
			c0.279-0.773,0.415-1.568-0.701-1.546c-0.344,0.004-0.308-0.673-0.705-0.666c-0.54,0.011-0.991,0.641-1.442,0.655
			c-0.193,0.004-0.34-0.168-0.533-0.161c-0.236,0.007-0.404,0.14-0.619,0.215c-0.462,0.154-0.809-0.304-1.099-0.594
			c-0.387-0.39-0.462-0.923-0.823-1.321c-0.204-0.222-0.397-0.455-0.558-0.716c0.007,0,0.018,0,0.025,0
			c0.004-0.319-0.193-0.612-0.039-0.855c0.157-0.247,0.268-0.594,0.279-0.891c0.014-0.415-0.279-1.027-0.061-1.414
			c0.165-0.293,0.279-0.583,0.419-0.891c0.172-0.379,0.34-0.855,0.759-1.009c0.233-0.086,0.508-0.319,0.583-0.562
			c0.082-0.258,0.025-0.558,0.19-0.784c0.236-0.333,0.523-0.626,0.773-0.952c-0.007,0-0.011,0-0.014,0
			c0.279-0.376,0.716,0.043,1.206-0.243c0.39-0.222,0.888-0.469,1.349-0.49c0.308-0.011,0.591,0,0.905-0.014
			c0.15-0.004,0.34-0.279,0.48-0.068c0.075,0.097,0.154,0.104,0.24,0.018c-0.014,0.272-0.132,0.501-0.132,0.805
			c-0.004,0.537,0.515,0.361,0.82,0.462c0.293,0.093,1.414,1.313,1.392,0.376c-0.018-0.812,1.177-0.115,1.474-0.007
			c0.419,0.15,0.744,0.204,1.16,0.047c0.315-0.115,0.594,0.089,0.923,0c0.19,0.476,0.175,0.605,0.061,1.099
			c-0.183-0.029-0.358-0.49-0.515-0.662c0.029,0.508,0.512,1.117,0.744,1.568c0.157,0.304,0.197,0.612,0.369,0.905
			c0.204,0.34,0.111,0.812,0.358,1.092c0.265,0.297,0.365,0.437,0.447,0.837c0.118,0.533,0.97,0.877,1.088,1.374
			c0.165,0.723,0.394,0.548,0.988,0.44c0.336-0.072,0.651-0.243,0.966-0.351c0.351,0.827-0.573,2.434-1.034,3.121
			C73.173,39.777,72.296,40.063,72.078,41.087z"
        />
        <path
          d="M68.979,23.73c0.308,0.283,0.594,0.558,0.837,0.777c1.228,1.127,2.301,2.437,3.157,3.862
			c-0.018,0-0.039,0.007-0.054,0.014c0.587,0.852,1.045,1.811,1.417,2.799c-0.211-0.297-0.927-0.744-0.58-0.047
			c0.168,0.344,0.58,0.523,0.705,0.855c0.082-0.011,0.036-0.129,0.111-0.136c0.075,0.157,0.018,0.326,0.147,0.447
			C73.685,28.894,71.677,25.913,68.979,23.73z"
        />
        <path d="M75.249,34.609c-0.111-0.719-0.272-1.421-0.472-2.108C74.973,33.188,75.127,33.886,75.249,34.609z" />
        <path
          d="M47.534,23.221c-0.036,0.025-0.072,0.047-0.104,0.072c0.007,0,0.014,0,0.021,0
			C47.481,23.275,47.506,23.246,47.534,23.221C47.531,23.225,47.534,23.225,47.534,23.221z"
        />
        <rect x="63.335" y="22.155" width="0" height="0.004" />
        <path
          d="M51.088,22.649c0.004,0,0.021,0,0.025,0c0.011,0,0.021-0.004,0.032-0.004
			C51.124,22.649,51.106,22.649,51.088,22.649z"
        />
        <path
          d="M48.783,25.952C48.783,25.948,48.78,25.948,48.783,25.952c-0.029-0.011-0.111-0.011-0.118-0.021
			C48.701,25.945,48.74,25.956,48.783,25.952z"
        />
        <path
          d="M47.091,23.594c-0.029,0.025-0.057,0.05-0.086,0.072c0.089-0.036,0.183-0.054,0.322-0.068
			C47.262,23.569,47.162,23.59,47.091,23.594z"
        />
        <polygon points="47.452,23.297 47.434,23.311 47.456,23.297 		" />
        <path
          d="M75.249,34.609c0.014,0.082,0.029,0.165,0.043,0.247c0.007,0,0.011-0.004,0.014-0.004
			C75.288,34.77,75.263,34.692,75.249,34.609z"
        />
        <rect x="0" y="64.829" width="38.594" height="25.088" />
        <polygon points="13.507,92.129 9.649,95.99 28.946,95.99 25.084,92.129 		" />
        <rect x="77.192" y="64.829" width="38.591" height="25.088" />
        <polygon points="90.699,92.129 86.837,95.99 106.134,95.99 102.276,92.129 		" />
        <path
          d="M33.799,45.536c0.458,0,0.916-0.175,1.267-0.523l0.39-0.39c0.698-0.698,0.698-1.832,0-2.53
			c-0.698-0.698-1.832-0.698-2.53,0l-0.39,0.39c-0.698,0.698-0.698,1.832,0,2.53C32.882,45.364,33.341,45.536,33.799,45.536z"
        />
        <path
          d="M25.292,54.042c0.458,0,0.916-0.175,1.267-0.523l0.727-0.727c0.698-0.698,0.698-1.832,0-2.53
			c-0.698-0.698-1.832-0.698-2.53,0l-0.727,0.727c-0.698,0.698-0.698,1.832,0,2.53C24.376,53.867,24.834,54.042,25.292,54.042z"
        />
        <path
          d="M29.547,49.787c0.458,0,0.916-0.175,1.267-0.523l0.723-0.723c0.698-0.698,0.698-1.832,0-2.53
			c-0.698-0.698-1.832-0.698-2.53,0l-0.723,0.723c-0.698,0.698-0.698,1.832,0,2.53C28.631,49.615,29.089,49.787,29.547,49.787z"
        />
        <path
          d="M21.036,58.301c0.458,0,0.916-0.175,1.267-0.523l0.727-0.727c0.698-0.698,0.698-1.832,0-2.53
			c-0.698-0.698-1.832-0.698-2.53,0l-0.727,0.727c-0.698,0.698-0.698,1.832,0,2.53C20.12,58.122,20.578,58.301,21.036,58.301z"
        />
        <path
          d="M17.118,62.217c0.458,0,0.916-0.175,1.267-0.523l0.39-0.39c0.698-0.698,0.698-1.832,0-2.53
			c-0.698-0.698-1.832-0.698-2.53,0l-0.39,0.39c-0.698,0.698-0.698,1.832,0,2.53C16.205,62.041,16.66,62.217,17.118,62.217z"
        />
        <path
          d="M80.717,45.009c0.354,0.351,0.809,0.523,1.267,0.523c0.455,0,0.916-0.175,1.267-0.523
			c0.698-0.698,0.698-1.832,0-2.53l-0.39-0.39c-0.694-0.698-1.832-0.698-2.53,0c-0.694,0.698-0.694,1.832,0,2.53L80.717,45.009z"
        />
        <path
          d="M88.501,52.797l0.727,0.727c0.351,0.351,0.812,0.523,1.267,0.523c0.462,0,0.92-0.175,1.267-0.523
			c0.698-0.698,0.698-1.832,0-2.53l-0.727-0.727c-0.694-0.698-1.832-0.698-2.527,0C87.807,50.961,87.8,52.096,88.501,52.797z"
        />
        <path
          d="M92.757,57.052l0.727,0.727c0.351,0.351,0.809,0.523,1.267,0.523c0.458,0,0.916-0.175,1.267-0.523
			c0.698-0.698,0.698-1.832,0-2.53l-0.723-0.727c-0.698-0.698-1.836-0.698-2.53,0C92.062,55.216,92.055,56.347,92.757,57.052z"
        />
        <path
          d="M84.25,48.545l0.719,0.723c0.354,0.351,0.812,0.523,1.267,0.523c0.455,0,0.916-0.175,1.267-0.523
			c0.698-0.698,0.698-1.832,0-2.53l-0.723-0.723c-0.698-0.698-1.836-0.698-2.53,0C83.555,46.709,83.548,47.84,84.25,48.545z"
        />
        <path
          d="M97.008,61.3l0.39,0.39c0.351,0.351,0.812,0.523,1.267,0.523c0.462,0,0.916-0.175,1.267-0.523
			c0.694-0.698,0.694-1.832,0-2.53l-0.387-0.39c-0.698-0.698-1.836-0.698-2.53,0C96.314,59.468,96.307,60.602,97.008,61.3z"
        />
      </g>
    </g>
  </svg>
);

export default ThirdPartyIcon;
