import * as React from "react";

const AccuracyIcon = (props) => (
  <svg width="22" height="24" viewBox="0 0 64 64" version="1.1" {...props}>
    <g id="Layer_2" />

    <g id="Layer_3" />

    <g id="Layer_4" />

    <g id="Layer_5" />

    <g id="Layer_6" />

    <g id="Layer_7" />

    <g id="Layer_8" />

    <g id="Layer_9" />

    <g id="Layer_10" />

    <g id="Layer_11" />

    <g id="Layer_12" />

    <g id="Layer_13" />

    <g id="Layer_15" />

    <g id="Layer_16">
      <path d="M62.9,30.9h-7.8C54.6,19,45,9.4,33.1,8.9V1.1C33.1,0.5,32.6,0,32,0s-1.1,0.5-1.1,1.1v7.8C19,9.4,9.4,19,8.9,30.9H1.1   C0.5,30.9,0,31.4,0,32s0.5,1.1,1.1,1.1h7.8c0.6,11.9,10.1,21.4,22,22v7.8c0,0.6,0.5,1.1,1.1,1.1s1.1-0.5,1.1-1.1v-7.8   c11.9-0.6,21.4-10.1,22-22h7.8c0.6,0,1.1-0.5,1.1-1.1S63.5,30.9,62.9,30.9z M52.9,30.9h-4.1c-0.5-8.4-7.3-15.1-15.7-15.7v-4.1   C43.8,11.7,52.3,20.2,52.9,30.9z M30.9,30.9H17.4C18,23.7,23.7,18,30.9,17.4V30.9z M30.9,33.1v13.5C23.7,46,18,40.3,17.4,33.1H30.9   z M33.1,33.1h13.5C46,40.3,40.3,46,33.1,46.6V33.1z M33.1,30.9V17.4C40.3,18,46,23.7,46.6,30.9H33.1z M30.9,11.1v4.1   c-8.4,0.5-15.1,7.3-15.7,15.7h-4.1C11.7,20.2,20.2,11.7,30.9,11.1z M11.1,33.1h4.1c0.5,8.4,7.3,15.1,15.7,15.7v4.1   C20.2,52.3,11.7,43.8,11.1,33.1z M33.1,52.9v-4.1c8.4-0.5,15.1-7.3,15.7-15.7h4.1C52.3,43.8,43.8,52.3,33.1,52.9z" />
    </g>

    <g id="Layer_17" />

    <g id="Layer_18" />

    <g id="Layer_19" />

    <g id="Layer_20" />

    <g id="Layer_21" />

    <g id="Layer_22" />

    <g id="Layer_23" />

    <g id="Layer_24" />

    <g id="Layer_25" />

    <g id="Layer_26" />

    <g id="Layer_27" />
  </svg>
);

export default AccuracyIcon;
