import * as React from "react";

const UssdIcon = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 22 22"
    fill={props?.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 4H20V20H4V4ZM5.5 5.5V18.5H18.5V5.5H5.5Z"
      fill={props?.fill}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.41566 17H9.86455L10.5116 14.5H12.0634L11.4164 17H12.8653L13.5123 14.5H16V13H13.9006L14.4182 11H16.5V9.50002H14.8064L15.4535 7H14.0046L13.3575 9.50002H11.8057L12.4527 7H11.0038L10.3568 9.50002H8V11H9.96856L9.45093 13H7.5V14.5H9.0627L8.41566 17ZM11.4175 11L10.8998 13H12.4002L12.9693 11H11.4175Z"
      fill={props?.fill}
    />
  </svg>
);

export default UssdIcon;
