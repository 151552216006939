import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Formik,
  Form as FormikForm,
  useFormikContext,
  ErrorMessage,
  useField,
  FieldArray,
} from "formik";
import {
  InputField,
  SelectField,
  RadioField,
  LabelField,
  FileField,
  RichTextField,
  PhoneField,
  StyledDate,
  NumberField,
  ColorPicker,
  UrlField,
  ServerPropField,
  TextArea,
  LocationField,
  //   LocationField,
} from "./FormElements";
import { mapValidationSchema } from "../formValidation";
// import actions from "../actions"
import * as Yup from "yup";
import ReactJson from "react-json-view";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import Spinner from "../loaders/Spinner";
import uuid from "react-uuid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ConfigContext from "../contexts/AppContext";
import useMenuUpdate from "../hooks/useMenuUpdate";
import toast from "react-hot-toast";
import { defaults } from "../mapInitialValues";
import moment from "moment";
import { formatNumber } from "../util/formatter";
import { useMemo } from "react";
import { mapOperator } from "../util";
import { appendQueryToUri, isValidValue } from "../util/generalUtil";
import { useSubscriber } from "../hooks/useSubscriber";
import ConfirmDialog from "./ConfirmDialog";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

const formHeaderStore = {};
let cartRoot = "";
let formikStatus = {};
let timeout = null;

function mapField(type) {
  switch (type) {
    case "select":
    case "multiselect":
      return SelectField;
    case "radio":
      return RadioField;
    case "dropdown":
      return SelectField;
    case "label":
      return LabelField;
    case "richtext":
      return RichTextField;
    case "upload":
      return FileField;
    // case "location":
    //   return LocationField;
    case "phonenumber":
      return PhoneField;
    case "colorpicker":
      return ColorPicker;
    case "date":
    case "datetime":
      return StyledDate;
    case "number":
      return NumberField;
    case "url":
      return UrlField;
    case "serverprop":
      return ServerPropField;
    case "textarea":
      return TextArea;
    default:
      return InputField;
  }
}

const FormWithContext = ({ props, schema: formSchema, activeCart }) => {
  console.log("activeCart", activeCart);
  let schema = formSchema;
  // const { values, handleChange,setFieldValue,setValues } = useFormikContext()
  const { values, handleChange, setFieldValue, setValues, setStatus, status } =
    props;
  let computeEvents = {};
  const { context } = useContext(AppContext);

  let activeCartNotEmpty = activeCart !== null;
  useEffect(() => {
    if (activeCartNotEmpty) {
      setValues({ ...values, ...activeCart });
    }
  }, [activeCartNotEmpty]);

  for (let control of schema) {
    let {
      validation,
      name,
      compute,
      script_field,
      options,
      script,
      type,
      use_field,
      server_fields,
    } = control;

    console.log("what are the script fields", script_field, name);

    if (script_field) {
      computeEvents[name] = [];
      console.log("script field is active", computeEvents[name]);

      let func = (() => {
        options = options || [];
        let scriptOptions = [...options];
        let defaultScript = script;

        console.log(
          "script field is func",
          scriptOptions,
          options,
          defaultScript,
          name
        );

        return (e) => {
          console.log("script field is rendered here");

          let { value: fieldValue, name: fieldName } = e.target;
          let scriptProps =
            {}.constructor == fieldValue?.constructor
              ? fieldValue
              : scriptOptions.find((m) => m.value === fieldValue);

          if (defaultScript) {
            scriptProps = scriptProps || { script: defaultScript };
          }

          console.log("scriptProps", scriptProps);
          if (!scriptProps) return;

          let scriptArr = scriptProps.script;

          let newStatus = {};

          scriptArr.forEach((sc) => {
            let { field, ...rest } = sc;
            newStatus[field] = rest;
          });

          console.log("newStatus", newStatus);

          let statusMod = { ...(status || {}), ...newStatus };

          if (Object.keys(statusMod).length > 0) {
            formikStatus = { ...statusMod };
            setStatus(statusMod);
          }
        };
      })();

      computeEvents[name].onChange = (
        computeEvents[name]?.onChange ?? []
      ).concat(func);
      console.log(
        "what does compute events do",
        computeEvents,
        computeEvents[name],
        name,
        computeEvents[name].onChange
      );
    }

    console.log(
      "let see the serverprop server_fields",
      type,
      server_fields,
      name
    );

    if (type === "serverprop" && server_fields) {
      for (let field of server_fields) {
        let func = (() => {
          let fields = [...server_fields];
          let serverControl = { ...control };

          console.log(
            "the serverprop fields and servercontrol fields",
            fields,
            serverControl,
            name
          );
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};

            console.log("what is serverprop e", name, e);

            fieldValue = moment.isDate(fieldValue)
              ? moment(fieldValue).format("YYYY-MM-DD")
              : fieldValue;

            currVals[fieldName] = fieldValue?.value ?? fieldValue;
            fields.forEach((f) => {
              if (f === fieldName) return;
              let value = values[f];
              value = moment.isDate(value)
                ? moment(value).format("YYYY-MM-DD")
                : value;
              currVals[f] = value?.value ?? value;
            });

            if (Object.values(currVals ?? {}).some((x) => !isValidValue(x))) {
              return;
            }

            console.log("the currvalues for serverprop", currVals, name);

            let handler = () => {
              setFieldValue(name, "loading...");
              let payload = {
                jsonString: JSON.stringify([currVals]),
                targetId: serverControl?.target_id?.toString(),
                target: serverControl?.target,
                applicationId: context?.menuId?.toString,
                parentDropValue: null,
              };

              console.log("the payload for serverprop is", payload);
              console.log(
                "the application id for serverprop is",
                context?.menuId
              );

              appFetch
                .post(apiEndpoints.getServerProps, {
                  body: JSON.stringify(payload),
                })
                .then((res) => {
                  console.log("response for serverprop", res);
                  setFieldValue(name, res);
                })
                .catch((e) => console.log("error from serverprop", e.message))
                .finally();
            };

            //debounce here
            console.log("timeout", timeout);
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(handler, 500);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }

    //for computer fields
    if (compute instanceof Object) {
      let { operator, withField, use_field } = compute;
      let reduceFunc = mapOperator(operator);

      for (let field of withField) {
        let func = (() => {
          let fields = [...withField];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return parseFloat(fieldValue?.replace(/\,/g, ""));
              }
              return parseFloat(values[f]?.replace(/\,/g, ""));
            });

            let result = computeValues.reduce(reduceFunc);

            const lineDiscount = parseFloat(
              values["line_unit_discount"] || values["line_discount"]
            );
            const lineInterest = parseFloat(
              values["line_unit_interest"] || values["line_interest"]
            );

            if (lineDiscount) {
              result -= result * (lineDiscount * 0.01);
            }

            if (lineInterest) {
              result += result * (lineInterest * 0.01);
            }

            console.log("computeValues value", result);

            setFieldValue(name, formatNumber(result) || "");
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
      //delete control.compute;
      control.disabled = true;
    }

    if (Array.isArray(use_field)) {
      for (let field of use_field) {
        let func = (() => {
          let fields = [...use_field];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;
            console.log("result is using field", use_field);

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return fieldValue
                  ?.toLowerCase()
                  ?.replace(/[^a-zA-Z\-_ ]/g, "")
                  .trim()
                  .replace(/\s+/g, "-");
              }
              return values[f]
                ?.toLowerCase()
                ?.replace(/[^a-zA-Z\-_ ]/g, "")
                .trim()
                .replace(/\s+/g, "-");
            });

            let result = computeValues.join("");
            console.log("result is", result);

            setFieldValue(name, result);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }
  }
  const getComputeEvent = (name) => {
    return computeEvents[name] || {};
  };

  return (
    <>
      {schema &&
        schema.map((o, idx) => {
          // let key = `${o.target_id}_${idx}`
          let key = `${o.target_id}`;
          let events = computeEvents[o.name] ?? {};

          //eg: onChange: [] onKeyup: []
          let eventHandlers = {};
          Object.keys(events)?.length > 0 &&
            console.log("computeEvents2", {
              computeEvents,
              events: Object.keys(events),
            });
          Object.keys(events).forEach((eventType) => {
            //eventType onChange
            eventHandlers[eventType] = (e) => {
              //e as target
              handleChange(e);
              events[eventType].forEach((func) => {
                func(e);
              });
            };
          });

          console.log("eventHandlers", eventHandlers);

          let script = (status ?? {})[o.name];
          let visible = script?.visible ?? eval(o?.visible) ?? true;
          let shortenOptions = true;
          return (
            <>
              {visible && (
                <div className="mb-4 grid" key={key}>
                  {React.createElement(mapField(o.type), {
                    ...o,
                    ...eventHandlers,
                    status,
                    shortenOptions,
                  })}
                </div>
              )}
            </>
          );
        })}
    </>
  );
};

const FormWithTableContext = ({
  props,
  schema: formSchema,
  fullSchema,
  activeCart,
  targetId,
  cart,
  handleDelete,
  _values,
}) => {
  let schema = formSchema;
  console.log("activeCart", activeCart);
  // const { values, handleChange,setFieldValue,setValues } = useFormikContext()
  const { values, handleChange, setFieldValue, setValues, setStatus, status } =
    props;
  let computeEvents = {};
  const { context } = useContext(AppContext);

  let activeCartNotEmpty = activeCart !== null;

  useEffect(() => {
    if (activeCartNotEmpty) {
      setValues({ ...values, ...activeCart });
    }
  }, [activeCartNotEmpty]);

  for (let control of fullSchema) {
    let {
      validation,
      name,
      compute,
      script_field,
      options,
      script,
      type,
      use_field,
      server_fields,
    } = control;

    console.log("what are the script fields", script_field, name);

    if (script_field) {
      computeEvents[name] = [];
      console.log("script field is active", computeEvents[name]);

      let func = (() => {
        options = options || [];
        let scriptOptions = [...options];
        let defaultScript = script;

        console.log(
          "script field is func",
          scriptOptions,
          options,
          defaultScript,
          name
        );

        return (e) => {
          console.log("script field is rendered here");

          let { value: fieldValue, name: fieldName } = e.target;
          let scriptProps =
            {}.constructor == fieldValue?.constructor
              ? fieldValue
              : scriptOptions.find((m) => m.value === fieldValue);

          if (defaultScript) {
            scriptProps = scriptProps || { script: defaultScript };
          }

          console.log("scriptProps", scriptProps);
          if (!scriptProps) return;

          let scriptArr = scriptProps.script;

          let newStatus = {};

          scriptArr.forEach((sc) => {
            let { field, ...rest } = sc;
            newStatus[field] = rest;
          });

          console.log("newStatus", newStatus);

          let statusMod = { ...(status || {}), ...newStatus };

          if (Object.keys(statusMod).length > 0) {
            formikStatus = { ...statusMod };
            setStatus(statusMod);
          }
        };
      })();

      computeEvents[name].onChange = (
        computeEvents[name]?.onChange ?? []
      ).concat(func);
      console.log(
        "what does compute events do",
        computeEvents,
        computeEvents[name],
        name,
        computeEvents[name].onChange
      );
    }

    console.log(
      "let see the serverprop server_fields",
      type,
      server_fields,
      name
    );

    if (type === "serverprop" && server_fields) {
      for (let field of server_fields) {
        let func = (() => {
          let fields = [...server_fields];
          let serverControl = { ...control };

          console.log(
            "the serverprop fields and servercontrol fields",
            fields,
            serverControl,
            name
          );
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};

            console.log("what is serverprop e", name, e);

            fieldValue = moment.isDate(fieldValue)
              ? moment(fieldValue).format("YYYY-MM-DD")
              : fieldValue;

            currVals[fieldName] = fieldValue?.value ?? fieldValue;
            fields.forEach((f) => {
              if (f === fieldName) return;
              let value = values[f];
              value = moment.isDate(value)
                ? moment(value).format("YYYY-MM-DD")
                : value;
              currVals[f] = value?.value ?? value;
            });

            if (Object.values(currVals ?? {}).some((x) => !isValidValue(x))) {
              return;
            }

            console.log("the currvalues for serverprop", currVals, name);

            let handler = () => {
              setFieldValue(name, "loading...");
              let payload = {
                jsonString: JSON.stringify([currVals]),
                targetId: serverControl?.target_id?.toString(),
                target: serverControl?.target,
                applicationId: context?.menuId?.toString,
                parentDropValue: null,
              };

              console.log("the payload for serverprop is", payload);
              console.log(
                "the application id for serverprop is",
                context?.menuId
              );

              appFetch
                .post(apiEndpoints.getServerProps, {
                  body: JSON.stringify(payload),
                })
                .then((res) => {
                  console.log("response for serverprop", res);
                  setFieldValue(name, res);
                })
                .catch((e) => console.log("error from serverprop", e.message))
                .finally();
            };

            //debounce here
            console.log("timeout", timeout);
            if (timeout) {
              clearTimeout(timeout);
            }
            timeout = setTimeout(handler, 500);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }

    //for computer fields
    if (compute instanceof Object) {
      let { operator, withField, use_field } = compute;
      let reduceFunc = mapOperator(operator);

      for (let field of withField) {
        let func = (() => {
          let fields = [...withField];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return parseFloat(fieldValue?.replace(/\,/g, ""));
              }
              return parseFloat(values[f]?.replace(/\,/g, ""));
            });

            let result = computeValues.reduce(reduceFunc);

            const lineDiscount = parseFloat(
              values["line_unit_discount"] || values["line_discount"]
            );
            const lineInterest = parseFloat(
              values["line_unit_interest"] || values["line_interest"]
            );

            if (lineDiscount) {
              result -= result * (lineDiscount * 0.01);
            }

            if (lineInterest) {
              result += result * (lineInterest * 0.01);
            }

            console.log("computeValues value", result);

            setFieldValue(name, formatNumber(result) || "");
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
      //delete control.compute;
      control.disabled = true;
    }

    if (Array.isArray(use_field)) {
      for (let field of use_field) {
        let func = (() => {
          let fields = [...use_field];
          return (e) => {
            let { value: fieldValue, name: fieldName } = e.target;
            let currVals = {};
            currVals[fieldName] = fieldValue;
            console.log("result is using field", use_field);

            let computeValues = fields.map((f) => {
              if (f === fieldName) {
                return fieldValue
                  ?.toLowerCase()
                  ?.replace(/[^a-zA-Z\-_ ]/g, "")
                  .trim()
                  .replace(/\s+/g, "-");
              }
              return values[f]
                ?.toLowerCase()
                ?.replace(/[^a-zA-Z\-_ ]/g, "")
                .trim()
                .replace(/\s+/g, "-");
            });

            let result = computeValues.join("");
            console.log("result is", result);

            setFieldValue(name, result);
          };
        })();

        if (!computeEvents[field]) {
          computeEvents[field] = {};
        }

        computeEvents[field].onChange = (
          computeEvents[field]?.onChange ?? []
        ).concat(func);
      }
    }
  }

  const getComputeEvent = (name) => {
    return computeEvents[name] || {};
  };

  console.log("compare the schema", schema, cart, _values);

  const renderValue = (field, item) => {
    let value = item[field.name];

    if (!Array.isArray(value)) {
      return typeof value !== "object" ? value : value?.label || "-";
    } else {
      // If it's an array, assuming it's an array of objects
      return value.map((item) => item.label).join(", ") || "-";
    }
  };

  function formatVariableName(variableName) {
    // Replace underscores with spaces
    const words = variableName.split("_");

    // Capitalize the first letter of each word
    let formattedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    formattedWords.shift();

    // Join the words back together with spaces
    const formattedVariableName = formattedWords.join(" ");

    return formattedVariableName;
  }

  return (
    <>
      {schema && (
        <div className="pt-6">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                {schema.map((str, idx) => {
                  let { name } = str;
                  let formattedName = formatVariableName(name);
                  return (
                    <th
                      key={idx}
                      className="px-5 py-3 border-b-2 border-gray-200 border-r-2 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                    >
                      {formattedName}
                    </th>
                  );
                })}
                <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"></th>
              </tr>
            </thead>
            {console.log("the cart items", cart)}
            <tbody>
              {cart.length > 0 &&
                cart.map((item, index) => (
                  <tr key={index}>
                    {console.log("the items for cart ishh", item)}
                    {schema.map((o, idx) => {
                      let value = item[o.name];
                      console.log("let compare value ish", value, o);
                      return (
                        <td
                          key={idx}
                          id={idx}
                          className="px-5 py-4 border-gray-200 bg-white text-sm border-r-2 border-t-2"
                        >
                          <div className="mx-auto px-5 py-2 border-2 border-gray-200 rounded-md font-medium bg-gray-50">
                            {renderValue(o, item)}
                          </div>
                        </td>
                      );
                    })}
                    <td className="text-md font-medium hover:underline px-4 border-b-2">
                      <button
                        type="button"
                        onClick={() => {
                          handleDelete(index);
                        }}
                        className="justify-self-end text-red-500 py-2 hover:text-red-700 hover:underline"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                ))}
              <tr>
                {schema.map((o, idx) => {
                  let key = `${o.target_id}`;
                  let events = computeEvents[o.name] ?? {};

                  //eg: onChange: [] onKeyup: []
                  let eventHandlers = {};
                  Object.keys(events)?.length > 0 &&
                    console.log("computeEvents2", {
                      computeEvents,
                      events: Object.keys(events),
                    });
                  Object.keys(events).forEach((eventType) => {
                    //eventType onChange
                    eventHandlers[eventType] = (e) => {
                      //e as target
                      handleChange(e);
                      events[eventType].forEach((func) => {
                        func(e);
                      });
                    };
                  });

                  console.log("eventHandlers", eventHandlers);

                  let script = (status ?? {})[o.name];
                  let visible = script?.visible ?? eval(o?.visible) ?? true;
                  let showLabel = false;

                  return (
                    <>
                      <td
                        id={idx}
                        className="px-5 py-2 border-gray-200 bg-white text-sm border-r-2 border-t-2"
                      >
                        <div>
                          {visible && (
                            <div className="mb-4 grid" key={key}>
                              {React.createElement(mapField(o.type), {
                                ...o,
                                ...eventHandlers,
                                status,
                                showLabel,
                              })}
                            </div>
                          )}
                        </div>
                      </td>
                    </>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default function Form2(props) {
  let {
    name,
    options,
    showPreview,
    target_id,
    // preview,
    formStepper,
    formData,
    setFormData,
    onSubmit,
    previousPage,
    nextPage,
    updatePage,
    edit,
    useCart,
    cartItems,
    addItemToCart,
    updatePreviewData,
    button_action,
    previewTransaction,
    formInitialValues,
    formDefaultValues,
    setFormHidden,
    updateRecord,
    stepId,
    deleteItemFromCart,
    clearCart,
    submit,
    activeCart,
    newCart,
    setNewCart,
  } = props;

  useCart = false;
  updatePage = updatePage || null;
  console.log("formstepper from form2", formStepper);

  console.log("builddata Formdata is updated", formData);

  formStepper.sort((a, b) => (a.useCart === "false" ? -1 : 1));

  const useCartFalse = formStepper?.filter((item) => item.useCart === "false");
  const useCartTrue = formStepper?.filter((item) => item.useCart === "true");

  console.log("the sorted formStepper is ", formStepper);
  console.log("usecartTrue and useCartFalse ", useCartTrue, useCartFalse);

  let optionsArrays = useCartTrue?.flatMap((item) => item.options);
  optionsArrays = optionsArrays?.filter(
    (item) => item.target !== "label" && item.type !== "richtext"
  );

  let fullSchema = formStepper?.flatMap((item) => item.options);

  fullSchema = fullSchema?.filter(
    (item) => item.target !== "label" && item.type !== "richtext"
  );

  console.log("the full Schema is", fullSchema);
  let optionsFalseArrays = useCartFalse?.flatMap((item) => item.options);
  optionsFalseArrays = optionsFalseArrays?.filter(
    (item) => item.target !== "label" && item.type !== "richtext"
  );

  let richTextRender = useCartTrue?.flatMap((item) => item.options);
  richTextRender = richTextRender?.filter((item) => item.target === "richtext");

  console.log("the optionsArrays is ", optionsArrays, optionsFalseArrays);

  let schema = formStepper?.flatMap((item) => item.options);
  let previewDataFinal = null;
  let preview =
    cartItems.length > 0 ? useCartTrue[0]?.preview : useCartFalse[0]?.preview;

  let initialValues = {};
  let defaultValues = {};
  let validationSchema = {};
  nextPage = nextPage || null;
  const lastStep = nextPage === null;
  showPreview = eval(showPreview);

  console.log("preview final", preview);
  const [newRow, setNewRow] = useState([{ ...optionsArrays }]);
  const [saving, setSaving] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });
  const { context } = useContext(AppContext);
  const { updateMenu } = useMenuUpdate();
  const { subs } = useSubscriber();

  const buildEditData = (postData, editData) => {
    let ref = postData;
    let count = 1;
    let curr = `ID_ROOT_${count}`;
    let prop = "";
    while (true) {
      prop = editData[`ID_ROOT_${count}`];

      //check if next exist
      if (typeof editData[`ID_ROOT_${count + 1}`] == "undefined") break;

      ref[prop] = typeof ref[prop] != "undefined" ? ref[prop] : {};
      ref = ref[prop];

      count++;
    }

    ref[prop] = editData[prop];
    return postData;
  };

  const buildPreview = (values) => {
    let formValues = {};
    for (let control of schema) {
      let { name, type } = control;

      if (/label/i.test(type)) {
        continue;
      }

      formValues[name] =
        type === "number"
          ? values[name]?.replace(/\,/g, "")
          : values[name] || null;
    }
    console.log("MyformValues", formValues);
    return formValues;
  };

  const addRow2 = () => {
    setNewRow([...newRow, { ...optionsArrays }]);
  };

  const handleDelete = (id) => {
    let newFormValues = newCart?.filter((i, index) => index !== id);
    setNewCart(newFormValues);

    console.log("the newcart should be changed", newFormValues, id);
    deleteItemFromCart(id);
  };

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  const buildData = (
    values,
    arrayToUse,
    useCartManual,
    previewToUse,
    reply
  ) => {
    let data = useCartManual ? {} : { ...formData };
    console.log("formdata", data);
    let previewData = {};

    console.log("the preview and usecart", previewToUse, useCartManual);
    if (previewToUse && !useCartManual) {
      previewData[previewToUse] = {};
    }

    console.log("let see newVals schema", arrayToUse);
    for (let control of arrayToUse) {
      let { name, type, response, cart, preview_section } = control;

      if (/label/i.test(type)) {
        continue;
      }

      let ref = data;
      let count = 1;
      let prop = "";
      while (true) {
        prop = control[`root_${count}`];

        //check if next exist
        if (typeof control[`root_${count + 1}`] == "undefined") break;

        ref[prop] = ref[prop] || {};
        ref = ref[prop];

        count++;
      }

      console.log("formdetails", { values, name });

      let value =
        type === "number"
          ? values[name]?.toString()?.replace(/\,/g, "")
          : values[name];

      if (previewToUse && preview_section) {
        previewData[previewToUse][preview_section] = value;
      }

      console.log(
        "preview, previewSection and previewdata",
        previewToUse,
        preview_section,
        previewData
      );

      //store cart items
      if (cart === "cart_currency") {
        formHeaderStore[cart] = value;
      }

      if (value instanceof Object) {
        if (Array.isArray(value) && /multiselect/i.test(type)) {
          value = value.map((v) => v.value).filter((v) => v);
        } else if (Array.isArray(value) && /checkbox/i.test(type)) {
          value = value.map((v) => v.value).filter((v) => v);
        } else if (Array.isArray(value) && /upload/i.test(type)) {
          value = value.filter((v) => v);
        } else {
          value =
            value.value ??
            (moment.isDate(value)
              ? moment(value).format("YYYY-MM-DD h:mmA")
              : value.toString());
        }
      }

      value = response === "array" ? [].concat(value).filter((m) => m) : value;

      if (value) {
        if (Array.isArray(ref[prop])) {
          ref[prop] = ref[prop].concat(value);
          ref[prop] = ref[prop].filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        } else {
          ref[prop] = value;
        }
      }
    }

    console.log("builddata", data);

    console.log(
      "builddata is previewdATA active",
      previewData,
      Object.keys(previewData).length > 0
    );

    if (Object.keys(previewData).length > 0) {
      previewDataFinal = updatePreviewData(previewData);
    }

    let newData = {};
    if (useCartManual) {
      let root_1 = Object.keys(data).slice(-1)[0];
      console.log("builddata what is root_1", root_1, data, formData);
      if (!root_1) {
        throw new Error("root_1 has a null reference");
      }

      let a = formData === null;
      console.log("it is a miracle", a, reply, formData);

      let lines;

      if (a) {
        // If 'a' is true
        lines = [].concat(reply[root_1] ?? []);
      } else {
        // If 'a' is false
        lines = [].concat(formData[root_1] ?? []);
      }
      // let lines = [].concat(formData[root_1] ?? []);
      let line = data[root_1];
      cartRoot = root_1;

      console.log("line", line);
      console.log("lines", lines);

      //new logic
      let { LINE_TOTAL_AMOUNT, LINE_QUANTITY, ...lineJson } = line;
      let lineString = JSON.stringify(lineJson);
      console.log("lineString", lineString);

      let lineIdx = null;
      let lineItem =
        lines &&
        lines.find((m, idx) => {
          let { LINE_TOTAL_AMOUNT, LINE_QUANTITY, ...rowJson } = m;
          let match = JSON.stringify(rowJson) == lineString;
          if (match) {
            lineIdx = idx;
          }
          return match;
        });

      if (lineItem) {
        if (lineItem.LINE_QUANTITY)
          lineItem.LINE_QUANTITY += line.LINE_QUANTITY;

        if (lineItem.LINE_TOTAL_AMOUNT) {
          lineItem.LINE_TOTAL_AMOUNT =
            lineItem.LINE_UNIT_AMOUNT * lineItem.LINE_QUANTITY;

          const lineDiscount = parseFloat(
            lineItem.LINE_DISCOUNT || lineItem.LINE_UNIT_DISCOUNT
          );
          const lineInterest = parseFloat(
            lineItem.LINE_INTEREST || lineItem.LINE_UNIT_INTEREST
          );

          if (lineDiscount) {
            lineItem.LINE_TOTAL_AMOUNT -=
              lineItem.LINE_TOTAL_AMOUNT * (lineDiscount * 0.01);
          }

          if (lineInterest) {
            lineItem.LINE_TOTAL_AMOUNT +=
              lineItem.LINE_TOTAL_AMOUNT * (lineInterest * 0.01);
          }
        }
      }

      //newData[root_1] = (lineData || []).concat(data[root_1])
      newData[root_1] = lineItem
        ? [...lines.filter((m, i) => i != lineIdx), lineItem]
        : [...lines, line];

      console.log("the double click builddata output", formData, newData);

      return { ...formData, ...newData };
    }

    if (edit) {
      return buildEditData(data, edit);
    }

    setFormData(data);
    return data;
  };

  const addToCart = async ({ values, setValues, setStatus, status, reply }) => {
    let cartLine = {};
    let lineValues = {};
    let previewUseCart = useCartTrue[0]?.preview;
    console.log("previewUseCart", previewUseCart);

    const formikStatusCopy = { ...formikStatus };
    console.log("value of status new", formikStatus);

    let formStepInitialValues = {};

    console.log("the optionsarray - addToCart", optionsArrays);

    for (let control of optionsArrays) {
      let { name, cart, type } = control;

      if (/label/i.test(type)) {
        continue;
      }

      const rowValue = values[name];
      formStepInitialValues[name] = initialValues[name];
      lineValues[name] =
        type === "number"
          ? values[name]?.toString()?.replace(/\,/g, "")
          : values[name] || null;

      if (cart) {
        if (Array.isArray(values[name])) {
          cartLine[cart] = values[name]?.map((m) => m?.name)?.join(",");
          continue;
        }

        if ({}.constructor === values[name]?.constructor) {
          cartLine[cart] = `${values[name]?.name}=>${values[name]?.value}`;
          continue;
        }

        cartLine[cart] =
          type === "number"
            ? rowValue?.toString().replace(/\,/g, "")
            : values[name] || null;

        if (formikStatusCopy[name]) {
          delete formikStatusCopy[name];
        }
      }
    }

    //logic here

    formikStatus = { ...formikStatusCopy };
    setStatus(formikStatus);
    console.log("value of status new", formikStatus);
    console.log("lineValues", lineValues);
    console.log("formHeaderStore", formHeaderStore);
    console.log("what is the cartline", cartLine);
    let manualUseCart = true;
    let previewManual = useCartTrue[0]?.preview;

    console.log("let see newVals values", values);

    let newVals = await buildData(
      values,
      optionsArrays,
      manualUseCart,
      previewManual,
      reply
    );
    console.log("let see newVals addToCart", newVals);
    let cartCurrency = formHeaderStore.cart_currency?.currency_code ?? "";

    if (cartLine.cart_unit_amount) {
      cartLine.cart_currency = cartCurrency;
      lineValues.line_currency = cartCurrency;

      if (cartLine.cart_quantity) {
        cartLine.cart_total_amount = parseFloat(
          cartLine.cart_quantity * cartLine.cart_unit_amount
        );

        if (cartLine.cart_discount) {
          cartLine.cart_total_amount -=
            cartLine.cart_total_amount * (cartLine.cart_discount * 0.01);
        }
      }
    }

    addItemToCart({
      item: cartLine,
      lineValues,
      preview: previewUseCart,
      previewDataFinal,
      cartRoot,
    });
    setNewCart([...newCart, lineValues]);

    console.log("compare the stuff", newCart, lineValues);
    setFormData(newVals);

    //reset values after adding to cart
    setValues({ ...values, ...formStepInitialValues });
    // const lineKeys = Object.keys(lineValues);
    // const newStatus = { ...status };
    // lineKeys.forEach((x) => delete newStatus[x]);
    // setStatus(newStatus);
  };

  const previewMyTranx = async ({ values }) => {
    let manualUseCart = true;
    let previewManual = useCartTrue[0]?.preview;
    await buildData(values, optionsArrays, manualUseCart, previewManual);
    previewTransaction(true);
  };

  const postData = (values) => {
    return new Promise((resolve, reject) => {
      let postData = {
        jsonString: JSON.stringify([values]),
        menuId: context?.menuId?.toString(),
        pageButtonActionId: target_id.toString(),
        buttonAction: button_action,
      };

      console.log("preview postData formLine", postData);

      setSaving(true);
      appFetch
        .post(`${apiEndpoints.menuAction}?subs=${subs}`, {
          body: JSON.stringify(postData),
        })
        .then((res) => {
          let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = res;

          MySwal.fire({
            text: errMsg,
            icon: errNo === 0 ? "success" : "error",
          });

          if (errNo !== 0) {
            reject(errMsg);
          }

          resolve(res);
        })
        .catch((err) => {
          toast.error(err.message ?? "an error occured!");
        })
        .finally(() => setSaving(false));
    });
  };

  const handlePostAsync = async (myVals, resetForm) => {
    let data = await postData(myVals);
    console.log("result from server", data);
    if (data.constructor == {}.constructor) {
      resetForm && resetForm({ values: formDefaultValues ?? defaultValues });
      //if(data?.ERROR_MESSAGE == "SUCCESS"){
      // setFormHidden(true)

      updateMenu(data?.MENU_BADGE);

      // }
      if (clearCart instanceof Function) {
        clearCart();
      }
      let savedRecords =
        data?.layout?.subcomponents?.find(
          ({ type }) => type === "SavedRecordList1"
        ) ??
        data?.layout?.subcomponents?.find(
          ({ type }) => type === "SavedTransactionList1"
        );
      updateRecord(savedRecords);
    }
  };

  const handleAddLine = async (values) => {
    let manualUseCart = false;
    let previewManual = useCartFalse[0]?.preview;
    let myVals = await buildData(
      values,
      optionsFalseArrays,
      manualUseCart,
      previewManual
    );
    console.log("builddata myvals for onsubmit", myVals);

    return myVals;
  };
  useCart = /true/i.test(useCart);

  onSubmit = onSubmit ?? updatePage;

  schema = schema || [];

  console.log("the schema is ", schema);

  for (let control of schema) {
    let { type, value, name, defaultValue } = control;

    if (/label/i.test(type)) {
      continue;
    }

    let controlValue = defaults(type);

    initialValues[name] = typeof value == "undefined" ? controlValue : value;
    defaultValues[name] = controlValue;
  }

  console.log(
    "initialValues",
    initialValues,
    "and formInifialValues",
    formInitialValues
  );

  let getValidationSchema = ({ schema, formikStatus }) => {
    let validationSchema = {};
    for (let control of schema) {
      let { type, name } = control;

      if (/label/i.test(type)) {
        continue;
      }

      let validation = mapValidationSchema(control, formikStatus);

      if (validation) {
        validationSchema[name] = validation;
      }
    }
    return validationSchema;
  };

  return (
    <div className="form">
      <header className=""></header>

      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Formik
        initialValues={formInitialValues || initialValues}
        validationSchema={() =>
          Yup.object(getValidationSchema({ schema, formikStatus }))
        }
        onSubmit={async (values, { setValues, resetForm, setStatus }) => {
          console.log("the double click somhow onsubmit is called");
          let inputValues = { ...formData };
          let manualUseCart = true;
          let previewManual = useCartTrue[0]?.preview;
          let myVals =
            optionsArrays.length > 1
              ? inputValues
              : buildData(values, optionsArrays, manualUseCart, previewManual);

          await handlePostAsync(myVals, resetForm);
          return;

          // onSubmit(myVals);
        }}
      >
        {(props) => {
          const {
            values,
            resetForm,
            validateForm,
            submitForm,
            setValues,
            setStatus,
            setTouched,
            isValid,
            touched,
            status,
          } = props;
          return (
            <div className="">
              <FormikForm className="grid content-center">
                {/* start of form */}

                {console.log("the values are", values)}
                {useCartFalse?.map((nn) => {
                  let nnOptions = nn.options;

                  const [nnFirstItem, ...nnRest] = nnOptions;

                  return (
                    <div className="grid">
                      <div className="grid mb-4">
                        <LabelField label={nnFirstItem?.label} />
                      </div>

                      <div className="grid grid-cols-3 gap-4">
                        <FormWithContext
                          props={props}
                          targetId={target_id}
                          schema={nnRest}
                          activeCart={activeCart}
                        />
                      </div>
                    </div>
                  );
                })}

                {/* cart line  */}
                <div className="overflow-x-visible w-full tranx-table scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300">
                  {console.log("the newcart", newCart)}
                  <FormWithTableContext
                    props={props}
                    targetId={target_id}
                    schema={optionsArrays}
                    fullSchema={fullSchema}
                    activeCart={activeCart}
                    cart={newCart}
                    handleDelete={handleDelete}
                    _values={values}
                  />
                </div>

                <div className="flex gap-3 justify-start pt-5">
                  <button
                    type="button"
                    onClick={async () => {
                      validateForm().then(async (errors) => {
                        const possibleErrors = Object.keys(errors);
                        console.log(possibleErrors);
                        if (possibleErrors.length === 0) {
                          let reply = await handleAddLine(
                            values,
                            setValues,
                            setStatus,
                            status
                          );

                          setTimeout(() => {
                            addToCart({
                              values,
                              setValues,
                              setStatus,
                              status,
                              reply,
                            });
                          }, 100);
                          setTouched({});
                          addRow2();
                        }
                        if (possibleErrors) {
                          setTouched({
                            ...touched,
                            ...errors,
                          });
                        }
                      });
                    }}
                    className="justify-self-end text-white bg-primary rounded-md py-2 px-5 text-sm font-medium capitalize"
                  >
                    Add Line
                  </button>
                </div>

                {/* <div className="grid mb-4 pt-5">
                  <LocationField label={"Position"} name={"the Name is"} />
                </div> */}

                {/* rest of form - if richTextExists*/}
                <div className="grid">
                  <div className="grid mt-6 mb-2">
                    <LabelField label={richTextRender[0]?.label} />
                  </div>

                  <div className="pt-5">
                    <FormWithContext
                      props={props}
                      targetId={target_id}
                      schema={richTextRender}
                      activeCart={activeCart}
                    />
                  </div>
                </div>

                {/* Buttons */}
                <div className="flex gap-2 justify-end pt-6">
                  <div className="space-x-3">
                    {/* preview button */}
                    {showPreview && (
                      <button
                        type="button"
                        onClick={() =>
                          cartItems.length > 0
                            ? previewMyTranx({ values })
                            : MySwal.fire({
                                text: "Add Line Item to Preview",
                                icon: "info",
                              })
                        }
                        className="justify-self-end bg-primary py-2 px-5 text-white rounded-md text-sm font-medium capitalize"
                      >
                        Preview
                      </button>
                    )}

                    {/* clear all button */}
                    <button
                      type="button"
                      onClick={() => {
                        resetForm({
                          values: formDefaultValues ?? defaultValues,
                        });
                      }}
                      className="justify-self-end bg-primary py-2 px-5 text-white rounded-md text-sm font-medium capitalize"
                    >
                      Clear All
                    </button>

                    {/* submit button */}
                    <button
                      type="button"
                      className={`justify-self-end bg-primary ${
                        saving ? "pt-[3px] pb-[12px] px-5" : "py-2 px-5"
                      }  text-white rounded-md text-sm font-medium capitalize`}
                      onClick={async () => {
                        // if (
                        //   useCart &&
                        //   currStep.allowEmpty == "false" &&
                        //   cartItems.length == 0
                        // ) {
                        //   MySwal.fire({
                        //     text: "Add item to cart to continue",
                        //     icon: "info",
                        //   });
                        //   return;
                        // }
                        if (cartItems.length > 0) {
                          //not last step? then goto next page.
                          let inputValues = { ...formData };
                          console.log(
                            "preview postData inputvalues formline",
                            inputValues
                          );
                          setConfirmDialog({
                            isOpen: true,
                            title: "Are you sure you want to proceed?",
                            subtitle: "You can't undo this operation",
                            onConfirm: async () => {
                              await handlePostAsync(inputValues, resetForm);
                            },
                          });
                          return;
                        } else {
                          MySwal.fire({
                            text: "Add Line Item to Submit",
                            icon: "info",
                          });
                        }
                      }}
                    >
                      {saving ? (
                        <div className="h-5 ">
                          <Spinner style={{ paddingBottom: 0 }} />
                        </div>
                      ) : (
                        <>{edit ? "Update" : "submit" ?? "&#8594;"}</>
                      )}
                    </button>
                  </div>
                </div>
              </FormikForm>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}
