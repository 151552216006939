import { React, useState } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
  Polyline,
} from "@react-google-maps/api";
import SidebarModal from "./SidebarModal";

const MapControl1 = ({ setLoading, name, options, selectedChartControl }) => {
  const mapStyles = {
    height: "100vh",
    width: "100%",
  };

  options = selectedChartControl?.options[0] ?? options[0];

  // const defaultCenter = {
  //   lat: 41.3851, lng: 2.1734
  // }

  const SidebarModals = ({ marker, onClose }) => {
    return (
      <div className="sidebar-modal-container">
        <div className="sidebar-modal-content">
          <h2>{marker.title}</h2>
          <p>{marker.description}</p>
          {/* Add more content as needed */}
        </div>
        <button className="close-button" onClick={onClose}>
          <span>&times;</span>
        </button>
      </div>
    );
  };

  const [inProp, setInProp] = useState(false);

  const MyStyle = (selected) => {
    selected = selected?.selected;

    let { id, name, latitude, longitude, map_drill } = selected;

    let mapDrill = JSON.parse(map_drill);
    let { header, history } = mapDrill;

    const drillKeys = Object.keys(header);
    // .filter(key => !invalidHeads.includes(key))

    return (
      <div className="font-sans">
        <section>
          <h2 className="uppercase font-semibold text-md pb-5">
            {header?.agent}
          </h2>
          <hr className="pb-5" />
          {
            <div>
              {Object.entries(header).map(([key, value]) => (
                <div className="justify-between flex pb-4" key={key}>
                  <h2 className="Capitalize font-semibold text-md">{key}</h2>
                  <h2 className="Capitalize font-semibold text-md">{value}</h2>
                </div>
              ))}
            </div>
          }
        </section>
        <hr className="pb-6" />
        <section>
          <div className="text-teal-600 text-xl pb-10">Last Activity</div>

          {history?.map((currItem, idx) => {
            return (
              <>
                <div className="flex items-center justify-between">
                  {currItem?.picture && (
                    <img
                      src={currItem?.picture}
                      className="h-8 w-12 object-contain"
                    />
                  )}

                  <div className="pb-4">
                    <p2 className="text-sm">{currItem?.customer}</p2>
                  </div>

                  <div className="pb-4">
                    <div className="text-sm text-gray-500">
                      {currItem?.timestamp}
                    </div>
                  </div>

                  <div className="pb-4">
                    <div className="text-sm">{currItem?.longitude}</div>
                    <div className="text-sm text-gray-500">
                      {currItem?.latitude}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </section>
      </div>
    );
  };

  const [selected, setSelected] = useState({});

  const onSelect = (item) => {
    setSelected(item);
    setInProp(true);
  };

  let newLocations = options?.data;

  const locations = [
    {
      name: "Location 1",
      location: {
        lat: 12.9802347063322,
        lng: 77.5907760360903,
        bearing: -20.5784744283754,
      },
    },
    {
      name: "Location 2",
      location: {
        lat: 12.9787501361417,
        lng: 77.5917845466407,
        bearing: 58.0502467067782,
      },
    },
    {
      name: "Location 3",
      location: {
        lat: 12.9774746532636,
        lng: 77.5901537636231,
        bearing: 65.5464053454266,
      },
    },
    {
      name: "Location 4",
      location: {
        lat: 12.9761573444059,
        lng: 77.5872569779997,
        bearing: -66.4029340594377,
      },
    },
    {
      name: "Location 5",
      location: {
        lat: 12.9771191896563,
        lng: 77.5857120256672,
        bearing: -60.0659370316888,
      },
    },
  ];

  //   const path = [
  //     {lat: 41.3954, lng: 2.162 },
  //     {lat: 41.3917, lng: 2.1649},
  //     {lat: 41.3773, lng: 2.1585},
  //     {lat: 41.3797, lng: 2.1682},
  //     {lat: 41.4055, lng: 2.1915}
  //   ];

  const path = [
    {
      lat: 12.9802347063322,
      lng: 77.5907760360903,
      bearing: -20.5784744283754,
    },
    {
      lat: 12.9793774204024,
      lng: 77.5910979011596,
      bearing: 17.1118088152409,
    },
    {
      lat: 12.9795865148043,
      lng: 77.5911622741734,
      bearing: 70.6690312217414,
    },
    {
      lat: 12.9797746996155,
      lng: 77.5916987159555,
      bearing: 38.1233134168197,
    },
    {
      lat: 12.9801301594259,
      lng: 77.5919776656823,
      bearing: -45.7414247345699,
    },
    {
      lat: 12.9798374278543,
      lng: 77.5922780730802,
      bearing: 16.0994201411847,
    },
    {
      lat: 12.9791683258247,
      lng: 77.5920849540387,
      bearing: 35.6916527554558,
    },
    {
      lat: 12.9787501361417,
      lng: 77.5917845466407,
      bearing: 58.0502467067782,
    },
    {
      lat: 12.9784155838887,
      lng: 77.5912481048586,
      bearing: 64.0233912454979,
    },
    {
      lat: 12.9784783124705,
      lng: 77.5913768508863,
      bearing: 45.7412428776673,
    },
    {
      lat: 12.9783319457552,
      lng: 77.5912266471873,
      bearing: -69.926654677622,
    },
    {
      lat: 12.978394674358,
      lng: 77.591054985817,
      bearing: 16.3413468751341,
    },
    {
      lat: 12.9779555738058,
      lng: 77.5909262397893,
      bearing: 54.6749460887583,
    },
    {
      lat: 12.9776210204837,
      lng: 77.5904541710211,
      bearing: 64.0233096712307,
    },
    {
      lat: 12.9774746532636,
      lng: 77.5901537636231,
      bearing: 65.5464053454266,
    },
    {
      lat: 12.9761573444059,
      lng: 77.5872569779997,
      bearing: -66.4029340594377,
    },
    {
      lat: 12.9764291706147,
      lng: 77.5866347055324,
      bearing: -48.4630801907934,
    },
    {
      lat: 12.9766382674962,
      lng: 77.5863986711483,
      bearing: -54.992843944921,
    },
    {
      lat: 12.9771191896563,
      lng: 77.5857120256672,
      bearing: -60.0659370316888,
    },
  ];

  const MapOptions = {
    strokeColor: "#0088FF",
    strokeWeight: 6,
    strokeOpacity: 0.6,
    // strokeOpacity: 5.0,
    // strokeWeight: 2,
    // fillColor: '#FF0000',
    // fillOpacity: 0.35,
    // clickable: false,
    // draggable: false,
    // editable: false,
    // visible: true,
    // radius: 30000,
    // paths: [
    //     {lat: 41.3954, lng: 2.162 },
    //     {lat: 41.3917, lng: 2.1649},
    //     {lat: 41.3773, lng: 2.1585},
    //     {lat: 41.3797, lng: 2.1682},
    //     {lat: 41.4055, lng: 2.1915}
    // ],
    zIndex: 1,
  };

  const onLoad = (polyline) => {
    console.log("polyline: ", polyline);
  };

  const sideDetails = () => {
    return <div>This is the sidebar</div>;
  };

  console.log("the selected item is", selected);

  return (
    <LoadScript googleMapsApiKey="AIzaSyCJgg4sQpU4NKpPDkgtmSDtQxeTC5A9sXU">
      <GoogleMap
        mapContainerStyle={mapStyles}
        zoom={16}
        center={{
          lat: parseFloat(newLocations[0]?.latitude),
          lng: parseFloat(newLocations[0]?.longitude),
        }}
      >
        {newLocations.map((item) => {
          let currLocation = {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          };

          return (
            <Marker
              key={item.name}
              position={currLocation}
              onClick={() => onSelect(item)}
            />
          );
        })}
        {selected && (
          //   <InfoWindow
          //   position={selected.location}
          //   onCloseClick={() => setSelected({})}
          // >
          //   <div
          //     style={{
          //       backgroundColor: '#02AFE1',
          //       color: 'white',
          //       width: '150px',
          //       minHeight: '50px',
          //       padding: 15,
          //       opacity: 0.5,
          //       borderRadius: 10,
          //       display: 'flex',
          //       flexDirection: 'column',
          //       alignItems: 'center',
          //       fontFamily: 'montserrat',
          //       // background: 'white',
          //       // border: '1px solid #ccc',
          //       // padding: '5px',
          //       // display: 'flex',
          //       // flexDirection: 'column',
          //       // justifyContent: 'center',
          //       // alignItems: 'center',
          //       // lineHeight: '0.05em',
          //     }}
          //   >
          //     <div
          //       style={{
          //         fontSize: 16,
          //         fontWeight: 500,
          //         padding: 1,
          //         textAlign: 'center',
          //       }}
          //     >
          //       {selected.name}
          //     </div>
          //     <div style={{ padding: 1 }}>{selected.location.lat}</div>
          //     <div style={{ padding: 1 }}>{selected.location.long}</div>
          //   </div>
          // </InfoWindow>
          <SidebarModal inProp={inProp} closeSidebar={() => setInProp(false)}>
            <MyStyle selected={selected} />
          </SidebarModal>
          // <SidebarModal marker={selected} onClose={() => setSelected({})} />
        )}
        <Polyline onLoad={onLoad} path={path} options={MapOptions} />
      </GoogleMap>
    </LoadScript>
  );
};

export default MapControl1;
