import React, { useContext, useState } from "react";
import AppContext from "../contexts/AppContext";
import renderComponent from "../renderComponent";

function MapWrapperControl1({ subcomponents }) {
  console.log("map subcomponents", subcomponents);
  const [selectedMapControl, setSelectedMapControl] = useState(null);

  let [MapFilter, MapControl] = subcomponents;
  let customSubcomponents = [MapFilter, selectedMapControl];

  let subcomponentstoUse = [];

  if (selectedMapControl) {
    subcomponentstoUse = customSubcomponents;
  } else {
    subcomponentstoUse = subcomponents;
  }

  console.log("the subcomponentstoUse", subcomponentstoUse);

  return (
    <>
      {subcomponentstoUse?.map((config, idx) =>
        renderComponent(config, {
          selectedMapControl,
          setSelectedMapControl,
        })
      )}
    </>
  );
}

export default MapWrapperControl1;
