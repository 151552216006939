import React, {useContext, useEffect, useState} from 'react';
import api from "../endpoints"
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import FilterIcon from '../icons/FilterIcon';
import AppContext from '../contexts/AppContext'
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon"
// import '../index.css';
// import {
//     Card,
//     CardHeader,
//     CardBody,
//     CardFooter,
//     Typography,
//     Button
//   } from "@material-tailwind/react";

const EmptyRecord  = () => {
   return ( <div className='grid place-content-center place-items-center h-80 bg-gray-100 rounded-sm mb-5'>
        <EmptyTransactionIcon  />
        <h2 className='font-semibold text-md'>No chart available</h2>
    </div>)
}

function ChartControl1({name,options}) {
    const {context} = useContext(AppContext)
    options = context?.chartControlOptions ?? options;


    // const graphData = (options ?? [])[0] ?? {};

    const options2 = {	
        "type": "ChartControl5",
        "name": "Bar Chart",
        // "series": [
        //     {
        //         "name": "USA",
        //         "data": [
        //             12478
        //         ]
        //     },
        //     {
        //         "name": "India",
        //         "data": [
        //             7456
        //         ]
        //     },
        //     {
        //         "name": "Canada",
        //         "data": [
        //             6083
        //         ]
        //     },
        //     {
        //         "name": "Brazil",
        //         "data": [
        //             5041
        //         ]
        //     },
        //     {
        //         "name": "FRANCE",
        //         "data": [
        //             4420
        //         ]
        //     }
        // ]
        "series":[{
                    showInLegend: false,
                    data: [12478, 7456, 6083, 5041, 4420],
                    colorByPoint:true,
                    "colors": [
                        '#4682B4',
                        '#C21E56',
                        '#77dd77',
                        '#F6BE00',
                        '#6A5ACD'
                    ],
                    
                }],
          
             "xAxis": {
                categories: [
                    'USA',
                    'India',
                    'Canada',
                    'Brazil',
                    'France'
                ],
            },
            'title': {
                enabled: true,
                text: 12478+ 7456+ 6083+ 5041+ 4420,
            
            },
            'subtitle': {
                text: 'Organic Sessions',
                x:-115,
            },
          "yAxis": {
                min: 0,
                title: {
                    text: 'Rainfall (mm)'
                },
                tickInterval: 3000
            },
        'total': 12478+ 7456+ 6083+ 5041+ 4420
    }

    const graphData = options2;


    for(var i=0; i<graphData?.series?.length; i++){
        graphData.series[i] = {...graphData.series[i]};
    }

    console.log(graphData);
    const graphOptions2 =  {
        chart: {
            type: 'spline'
        },
        title:{
            text:''
        },
        subTitle:{
            text:''
        },
        legend:{ enabled:false },
        xAxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        },
        yAxis:{
            title:{
                enabled:false,
            }
        },
        plotOptions: {
            series: {
                shadow:true,
            }
        },
    }
    const graphOptions =  {
        chart: {
            type: 'column',
        },
        title:{
            text:''
        },
        subTitle:{
            text:''
        },
        legend:{ enabled:false },
        xAxis: {
            categories: [],
        },
        yAxis:{
            title:{
                enabled:false,
            }
        },
        plotOptions: {
            series: {
                borderRadius:4,
                pointWidth: 20,
                groupPadding: 0.38,
                shadow:false,
            },
        },
        ...graphData
    }

    const graphOptions3 = {
        chart: {
            type: 'bar'
        },
        // title: {
        //     text: 'Monthly Average Rainfall'
        // },
        xAxis: {
            categories: [],
        },
        yAxis: {
            tickInterval: 3000
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    inside: true,
                    // align: 'left',
                    formatter: function(){
                        return this.y.toLocaleString();
                    }
                 },
                borderRadius:4,
                pointWidth: 20,
                groupPadding: 0.38,
                shadow:false,
            }
        },
        ...graphData
    }

    console.log('graphOptions',graphOptions, options);

    return (
        <div className=''>
             
             <div class="max-w-sm rounded-lg overflow-hidden shadow-lg mb-5">
            <div class="px-6 py-4">
                <div className='flex flex-row'>
                <div class="font-bold text-xl pr-6">{graphData.total.toLocaleString()}</div>
                <div className='font-semibold bg-green-200 text-green-700 rounded-lg pr-1 pl-1 pt-1'>{'\u2191'} 8.02% </div>
                </div>
                <div id="graph" className="">
                 {Object.keys(graphData?.xAxis ?? {}).length > 0 ?
                    <HighchartsReact
                    highcharts={Highcharts}
                    options={{...graphOptions3}} 
                    containerProps={{ style: { width: "100%" } }}
                    />
                    :
                    <EmptyRecord />
                    }
             </div>
            </div>
            </div>
        </div>
    );
}

export default ChartControl1;