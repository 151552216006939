import React, { useContext, useEffect, useState } from "react";
import api from "../endpoints";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FilterIcon from "../icons/FilterIcon";
import AppContext from "../contexts/AppContext";
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import "../index.css";
import WithLoader from "./WithLoader";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import DrillModalDialog from "./DrillModalDialog";

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-96 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No chart available</h2>
    </div>
  );
};

function ChartControl2({ setLoading, name, options }) {
  const [modalData, setModalData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const { context } = useContext(AppContext);
  options = context?.chartControlOptions ?? options;

  const graphData = options[0];

  for (var i = 0; i < graphData?.series?.length; i++) {
    graphData.series[i] = { ...graphData.series[i] };
  }

  const showDetail = (category) => {
    console.log("item", category);

    setLoading(true);
    let postData = {
      layoutId: graphData.layoutid,
      targetId: graphData.targetid,
      target: graphData.target,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res", res);
        const { layoutid, targetid, drillid } = graphData;
        setModalData({ ...res, layoutid, targetid, drillid });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  const graphOptions = {
    yAxis: {
      title: {
        text: "",
      },
    },
    xAxis: {
      categories: [],
    },
    plotOptions: {
      series: {
        label: {
          enabled: false,
        },
        point: {
          events: {
            click: function () {
              // Call the custom function with the category as an argument
              showDetail(this.category);
            },
          },
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
        },
      ],
    },
    ...graphData,
  };

  console.log("graphOptions", graphOptions, options);
  console.log("the modalData is", modalData);


  return (
    <>
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
          setLoading={setLoading}
        />
      )}
      <div>
        <div id="graph" className="mb-10">
          {Object.keys(graphData?.xAxis ?? {}).length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{ ...graphOptions, ...graphData }}
              containerProps={{ style: { width: "100%" } }}
            />
          ) : (
            <EmptyRecord />
          )}
        </div>
      </div>
    </>
  );
}

export default WithLoader(ChartControl2);
