import React from "react";

import ApplicationList1 from "./ApplicationList1";
import ButtonStyle1 from "./ButtonStyle1";
import MenuList1 from "./MenuList1";
import MenuList2 from "./MenuList2";
import SearchControl1 from "./SearchControl1";
import FavouriteList1 from "./FavouriteList1";
import FavouriteList2 from "./FavouriteList2";
import LeftArea1 from "./LeftArea1";
import RightArea1 from "./RightArea1";
import QuickNotificationList1 from "./QuickNotificationList1";
import QuickNotificationList1_2 from "./QuickNotificationList1_2";
import ChartControl1 from "./ChartControl1";
import RecentTransactionList1 from "./RecentTransactionList1";
import RecentTransactionList2 from "./RecentTransactionList2";
import SectionGrid from "./SectionGrid";
import MainModuleArea1 from "./MainModuleArea1";
import ModuleList1 from "./ModuleList1";
import FormStepper1 from "./FormStepper1";
import FormLine1 from "./FormLine1";
import Form1 from "./Form1";
import Form2 from "./Form2";
import WalletBalanceStyle1 from "./WalletBalanceStyle1";
import WalletBalanceStyle2 from "./WalletBalanceStyle2";
import TwoColumnRow from "./TwoColumnRow";
import TopNavActionMenu1 from "./TopNavActionMenu1";
import TopNavActionButton from "./TopNavActionButton";
import PendingApprovalList1 from "./PendingApprovalList1";
import MainArea1 from "./MainArea1";
import MainApprovalArea1 from "./MainApprovalArea1";
import ChartControl2 from "./ChartControl2";
import ChartFilterControl1 from "./ChartFilterControl1";
import TopNavMenuList1 from "./TopNavMenuList1";
import ChartWrapperControl1 from "./ChartWrapperControl1";
import RecordWithFormWrapper from "./RecordWithFormWrapper";
import GridAndFormWrapper1 from "./GridAndFormWrapper1";
import SavedRecordList1 from "./SavedRecordList1";
import GridAndFormStepperWrapper1 from "./GridAndFormStepperWrapper1";
import ApprovalTransactionList1 from "./ApprovalTransactionList1";
import MainMemoArea1 from "./MainMemoArea1";
import MainOpenArea1 from "./MainOpenArea1";
import SavedTransactionList1 from "./SavedTransactionList1";
import ModalLayout1 from "./ModalLayout1";
import DateNotificationList1 from "./DateNotificationList1";
import DateNotificationList2 from "./DateNotificationList2";
import ChartControl3 from "./ChartControl3";
import ChartControl4 from "./ChartControl4";
import BannerArea1 from "./BannerArea1";
import MiddleArea1_3 from "./MiddleArea1_3";
import ContentArea from "./ContentArea";
import ParentMerge3MiddleArea from "./ParentMerge3MiddleArea";
import ProfileMini from "./ProfileMini";
import BarChartNotification from "./BarChartNotification";
import MixedNotificationList1 from "./MixedNotificationList1";
import MixedNotificationList2 from "./MixedNotificationList2";
import NewChartNotification1 from "./NewChartNotification1";
import QuickNotificationList2 from "./QuickNotificationList2";
import ParentMerge2MiddleArea from "./ParentMerge2MiddleArea";
import MapWrapperControl1 from "./MapWrapperControl1";
import MapControl1 from "./MapControl1";
import MapFilterControl1 from "./MapFilterControl1";
import MainArea2 from "./MainArea2";
import MiddleArea1_2 from "./MiddleArea1_2";
import {
  ChartWrapperControlm11,
  ChartWrapperControlm12,
  ChartWrapperControlm13,
  ChartWrapperControlm21,
  ChartWrapperControlm22,
  ChartWrapperControlm23,
  ChartWrapperControlm31,
  ChartWrapperControlm32,
  ChartWrapperControlm33,
  ChartWrapperControlm41,
  QuickNotificationListm1,
  QuickNotificationListm2,
  QuickNotificationListm3,
  QuickNotificationListm11,
  QuickNotificationListm21,
  QuickNotificationListm31,
  QuickNotificationListm41,
  QuickNotificationListm12,
  QuickNotificationListm22,
  QuickNotificationListm32,
  QuickNotificationListm42,
  QuickNotificationListm13,
  QuickNotificationListm23,
  QuickNotificationListm43,
  QuickNotificationListm33
} from "./dashboardComponents.js";

export default {
  ApplicationList1,
  ButtonStyle1,
  MenuList1,
  MenuList2,
  SearchControl1,
  FavouriteList1,
  FavouriteList2,
  LeftArea1,
  RightArea1,
  QuickNotificationList1,
  QuickNotificationList2,
  QuickNotificationList3: QuickNotificationList2,
  QuickNotificationList4: QuickNotificationList2,
  QuickNotificationList5: QuickNotificationList2,
  QuickNotificationList6: QuickNotificationList2,

  QuickNotificationListm11,
  QuickNotificationListm21,
  QuickNotificationListm31,
  QuickNotificationListm41,
  QuickNotificationListm12,
  QuickNotificationListm22,
  QuickNotificationListm32,
  QuickNotificationListm42,
  QuickNotificationListm13,
  QuickNotificationListm23,
  QuickNotificationListm33,
  QuickNotificationListm43,
  // ChartControl1: ListNotification1,
  MixedNotificationList1,
  ChartControl1,
  RecentTransactionList1,
  RecentTransactionList2,
  SectionGrid,
  MainModuleArea1,
  FormStepper1,
  FormLine1,
  Form1,
  Form2,
  WalletBalanceStyle1,
  WalletBalanceStyle2,
  TwoColumnRow,
  TopNavActionButton,
  TopNavMenuList1,

  PendingApprovalList1,
  PendingApprovalList2: PendingApprovalList1,
  PendingApprovalList3: PendingApprovalList1,
  PendingApprovalList4: PendingApprovalList1,
  PendingApprovalList5: PendingApprovalList1,

  MainArea1,
  MainArea2,
  MainArea3: MainArea2,
  MainApprovalArea1,
  ChartControl2,
  ChartFilterControl1,
  ChartWrapperControl1,
  ModuleList1,
  RecordWithFormWrapper,
  GridAndFormWrapper1,
  SavedRecordList1,
  GridAndFormStepperWrapper1,
  SavedTransactionList1,
  ProfileMini,
  ApprovalTransactionList1,
  ApprovalTransactionList2: ApprovalTransactionList1,
  ApprovalTransactionList3: ApprovalTransactionList1,
  ApprovalTransactionList4: ApprovalTransactionList1,
  ApprovalTransactionList5: ApprovalTransactionList1,

  MemoTransactionList1: ApprovalTransactionList1,
  OpenTransactionList1: ApprovalTransactionList1,
  MainMemoArea1,
  PendingMemoList1: PendingApprovalList1,
  PendingOpenList1: PendingApprovalList1,
  MainOpenArea1,
  ModalLayout1,
  DateNotificationList1,
  DateNotificationList2,
  ChartControl3,
  ChartControl4,
  BannerArea1,
  MiddleArea1_3,
  MiddleArea2_3: MiddleArea1_3,
  MiddleArea3_3: MiddleArea1_3,
  ChartWrapperControlm11,
  ChartWrapperControlm12,
  ChartWrapperControlm13,
  ChartWrapperControlm21,
  ChartWrapperControlm22,
  ChartWrapperControlm23,
  ChartWrapperControlm31,
  ChartWrapperControlm32,
  ChartWrapperControlm33,
  ApplicationList2: ApplicationList1,
  ChartFilterControl2: ChartFilterControl1,
  ChartFilterControl3: ChartFilterControl1,
  ChartFilterControl4: ChartFilterControl1,
  ChartFilterControl5: ChartFilterControl1,
  ChartFilterControl6: ChartFilterControl1,

  ChartFilterControlm11: ChartFilterControl1,
  ChartFilterControlm12: ChartFilterControl1,
  ChartFilterControlm13: ChartFilterControl1,
  ChartFilterControlm21: ChartFilterControl1,
  ChartFilterControlm22: ChartFilterControl1,
  ChartFilterControlm23: ChartFilterControl1,
  ChartFilterControlm31: ChartFilterControl1,
  ChartFilterControlm32: ChartFilterControl1,
  ChartFilterControlm33: ChartFilterControl1,

  MixedNotificationList2: MixedNotificationList1,
  MixedNotificationList3: MixedNotificationList1,

  MapWrapperControl1,
  MapControl1,
  MapFilterControl1,

  ParentLeftArea: ContentArea,
  ParentMainArea: ContentArea,
  ParentRightArea: ContentArea,
  Parent3MiddleArea: ContentArea,
  ParentMainApprovalArea: ContentArea,
  ParentMainModuleArea: ContentArea,
  ParentMainMemoArea: ContentArea,
  ParentMainOpenArea: ContentArea,

  ParentMainDateArea: ContentArea,

  // ParentMainArea: ParentMainArea2,

  ParentMerge3MiddleArea,
  MergeMiddleArea1_3: MiddleArea1_3,
  MergeMiddleArea2_3: MiddleArea1_3,
  MergeMiddleArea3_3: MiddleArea1_3,
  MergeMiddleArea3: MiddleArea1_3,

  Parent2MiddleArea: ParentMerge2MiddleArea,
  Parent3MiddleArea: ParentMerge3MiddleArea,

  MiddleArea1_2: MiddleArea1_2,
  MiddleArea2_2: MiddleArea1_2,

  MiddleArea1_3,
  MiddleArea2_3: MiddleArea1_3,
  MiddleArea3_3: MiddleArea1_3,

  DateNotificationListm3: DateNotificationList1,

  QuickNotificationListm1,
  QuickNotificationListm2,
  QuickNotificationListm3,
};
