import React, { Component } from 'react';
import api from "../endpoints"
import renderComponent from "../renderComponent";
import classNames from 'classnames';
import uuid from 'react-uuid';


class ApprovalLayout1 extends Component {

    constructor(props) {
        super(props);
      }

    componentDidMount(){

    }

    layoutRule = [
        "max-w-xs overflow-auto", 
        "px-12 pt-12 overflow-auto",
        "max-w-sm"
    ]

    render() {
       return (
            <div className="home-layout-1 flex w-full h-full absolute">
                {this.props.subcomponents.map((config,idx) => (
                    <section key={idx} className={classNames(this.layoutRule[idx],"right-sidebar flex-1")}>
                        {renderComponent(config)}
                    </section>
                    ))
                }
            </div>
       )
    }
}

export default ApprovalLayout1;