// export default (amount,currency = 'NGN') => new Intl.NumberFormat('en-US', {
//     style: 'currency',
//     currency,
//   }).format(amount || 0)  

export const formatNumber = (amount) => {
  var newAmount = parseFloat(amount)
  return new Intl.NumberFormat('en-US').format(newAmount || 0) 
} 

  // export default formatter;