import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../endpoints";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import FilterIcon from "../icons/FilterIcon";
import AppContext from "../contexts/AppContext";
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import DrillModalDialog from "./DrillModalDialog";
import appFetch from "../appFetch";
import { appendQueryToUri } from "../util/generalUtil";
import apiEndpoints from "../apiEndpoints";
import WithLoader from "./WithLoader";
// import '../index.css';

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-96 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No chart available</h2>
    </div>
  );
};

function ChartControl3({ setLoading, name, options, selectedChartControl }) {
  const { context } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const gridRef = useRef(null);
  // const [loading, setLoading] = useState(false);
  options = selectedChartControl?.options ?? options;

  const graphData = (options ?? [])[0] ?? {};

  console.log("the graphData is", graphData);
  console.log("the modalData is", modalData);

  const showDetail = () => {
    setLoading(true);
    let postData = {
      layoutId: graphData.layoutid,
      targetId: graphData.targetid,
      target: graphData.target,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        console.log("res drill chart", res);
        const { layoutid, targetid, drillid } = graphData;
        setModalData({ ...res, layoutid, targetid, drillid });
        setIsOpen(true);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoading(false));
  };

  // Assuming graphData is defined
  const convertedGraphData =
    (graphData?.series &&
      graphData?.series[0]?.data.map((item) => ({
        ...item,
        y: Number(item.y),
      }))) ||
    {};

  const graphOptions = {
    chart: {
      plotShadow: false,
      style: {
        float: "left",
      },
      // marginRight: 280,
      type: "pie",
    },
    title: {
      // text: 'Project Earnings in April',
      // align: 'left',
      // marginRight: 60,
      x: -130,
      style: {
        // fontFamily: 'sans-serif',
        fontSize: "22px",
      },
    },
    legend: {
      align: "right",
      margin: 0,
      x: -180,
      y: -40,
      floating: false,
      layout: "vertical",
      verticalAlign: "middle",
      itemStyle: {
        fontSize: "16px",
      },
      labelFormatter: function () {
        let name =
          this.name.length > 20 ? this.name.substr(0, 20) + "..." : this.name;
        console.log(this);
        return `${name}   ${this.y}`;
      },
    },
    plotOptions: {
      pie: {
        borderColor: null,
        size: "75%",
        // borderWidth: 12,
        slicedOffset: 20,
        innerSize: "40%",
        dataLabels: {
          enabled: true,
          formatter: function () {
            return (
              "<b>" +
              this.point.name +
              "</b><br/> " +
              Math.round(this.percentage) +
              " %"
            );
          },
        },
        showInLegend: false,
      },
      series: {
        label: {
          enabled: false,
        },
        point: {
          events: {
            click: function () {
              // Call the custom function with the category as an argument
              showDetail();
            },
          },
        },
      },
    },
    series: [
      {
        name: "Percentage",
        colorByPoint: true,
        data: convertedGraphData,
      },
    ],
  };

  console.log("graphOptions", graphOptions, options);

  return (
    <>
      {isOpen && (
        <DrillModalDialog
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          item={modalData}
          setLoading={setLoading}
        />
      )}

      <div>
        <div id="graph" className="mb-10">
          {Object.keys(graphData?.series ?? {}).length > 0 ? (
            <HighchartsReact
              highcharts={Highcharts}
              options={{ ...graphOptions }}
              containerProps={{ style: { width: "100%" } }}
            />
          ) : (
            <EmptyRecord />
          )}
        </div>
      </div>
    </>
  );
}

export default WithLoader(ChartControl3);
